import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router';

import Button from 'src/app/components/button';

const CreateOfferCard: React.FC = () => {
  const { t } = useTranslation();
  const [redirect, setRedirect] = useState(null);

  function handleCreateOffer() {
    setRedirect('offers/create');
  }

  if (redirect) return <Redirect to={redirect} />;

  return (
    <Button
      className="offer-card offer-create-button"
      data-testid="create-button"
      role="button"
      onClick={handleCreateOffer}
    >
      <div className="overlay-button">
        <img
          alt=""
          className="create-button"
          src={require('src/assets/svg/plus_icon.svg')}
        />
      </div>
      <span>{t('offers.createOffer.label')}</span>
    </Button>
  );
};

export default CreateOfferCard;
