export default {
  bodyFont: 'Poppins',
  baseBoxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.1)',
  baseBorder: '1px solid #ebecee',
  baseBorder2: '1px solid #dbdbdb',
  basePadding: [8, 14, 20, 40],
  borderRadius: '5px',
  breakpoints: {
    mobileMid: '425px',
    mobileLarge: '674px',
    tablet: '864px',
    desktop: '1000px',
    desktopXl: '1720px',
  },
  buttonSize: [38, 40, 50],
  colors: {
    white: '#fff',
    white2: '#a1adb4',
    placeholder: '#c3c3c3',
    blue: '#0048ff',
    darkBlue: '#003650',
    lightBlue: '#e9f2fc',
    lightBlue2: '#e0eaf6',
    lightGray4: '#f8f8f8',
    gray: '#A2ADB5',
    purple: '#9013FE',
    red: '#e92424',
  },
  fontSizes: {
    input: 13,
    symbol: 16,
  },
  inputSize: [38, 40, 50],
};
