import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Button from '../button';
import { colors, defaults } from '../../../theme/variables';

function getBg(type = '') {
  switch (type.toLowerCase()) {
    case 'error':
      return colors.red;
    default:
      return colors.green;
  }
}

interface StatusBarContainerProps {
  statusType?: string;
  hide?: boolean;
  width?: string;
}

const StatusBarContainer = styled.div<StatusBarContainerProps>`
  align-items: baseline;
  animation: fade-in 0.6s ease;
  background: ${({ statusType }) => getBg(statusType)};
  border: none;
  border-radius: ${defaults.borderRadius};
  box-sizing: border-box;
  color: ${colors.white};
  line-height: 22px;
  display: flex;
  max-width: calc(100% - 40px);
  min-height: 38px;
  justify-content: space-between;
  padding: 7px 12px;
  position: fixed;
  right: 20px;
  top: 20px;
  z-index: 10000;

  ${props => props.width && `width: ${props.width};`}

  ${props =>
    props.hide &&
    `
    animation: fade-out .6s ease;
    opacity: 0;
  `}

  button {
    color: ${colors.white} !important;
    margin-left: 40px;
  }

  p {
    margin: 0;
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes fade-out {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
    }
  }
`;

interface StatusBarProps {
  hideBtnLabel: string;
  statusMessage: string;
  onClose?: () => unknown;
  autoHide?: boolean;
  statusType?: string;
  width?: string;
}

const StatusBar: React.FC<StatusBarProps> = ({
  autoHide = false,
  hideBtnLabel,
  statusMessage,
  width,
  onClose,
  statusType = '',
}) => {
  const statusBarRef = useRef<HTMLDivElement>();
  const [hide, setHide] = useState(false);

  useEffect(() => {
    setHide(false);
    let timeout: number;
    if (autoHide)
      timeout = setTimeout(() => {
        setHide(true);
      }, 3000);

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusMessage]);

  useEffect(() => {
    if (hide) {
      setTimeout(() => {
        statusBarRef.current.style.visibility = 'hidden';
        statusBarRef.current.style.zIndex = '-1';
      }, 600);
    } else {
      statusBarRef.current.style.visibility = 'visible';
      statusBarRef.current.style.zIndex = '10000';
    }
  }, [hide]);

  return (
    <StatusBarContainer
      hide={hide}
      statusType={statusType}
      width={width}
      data-testid={hide ? 'status-bar-hidden' : null}
      ref={statusBarRef}
    >
      <p>{statusMessage}</p>
      {!autoHide && (
        <Button
          appearance="transparent"
          onClick={() => {
            setHide(true);
            if (onClose) onClose();
          }}
          data-testid="hide-btn"
        >
          {hideBtnLabel}
        </Button>
      )}
    </StatusBarContainer>
  );
};

export default StatusBar;
