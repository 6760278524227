import React from 'react';

export interface ConfirmBoxProps {
  handleCancel: () => any;
  handleConfirm: () => any;
  message: string;
  title: string;
  confirmLabel: string;
  cancelLabel: string;
}

export class ConfirmBox extends React.PureComponent<ConfirmBoxProps> {
  render() {
    const closeIcon = require('src/assets/svg/close_icon.svg');
    const {
      title,
      message,
      cancelLabel,
      confirmLabel,
      handleConfirm,
      handleCancel,
    } = this.props;
    return (
      <div className="confirm-box-container">
        <button
          type="button"
          className="btn-round"
          onClick={handleCancel}
          data-testid="close-confirm-box"
        >
          <img alt={cancelLabel} className="large-only" src={closeIcon} />
        </button>
        <section className="confirm-box">
          <h3 data-test="confirm-title">{title}</h3>
          <p data-test="confirm-message">{message}</p>
          <div className="confirm-box-buttons">
            <button
              type="button"
              data-test="confirm-button"
              data-testid="confirm-button"
              className="btn btn-confirm"
              onClick={handleConfirm}
            >
              {confirmLabel}
            </button>
            <button
              type="button"
              data-test="cancel-button"
              data-testid="cancel-button"
              className="btn btn-white"
              onClick={handleCancel}
            >
              {cancelLabel}
            </button>
          </div>
        </section>
      </div>
    );
  }
}

export default ConfirmBox;
