import jsPDF from 'jspdf';
import moment from 'moment';
import { t } from 'i18next';
import {
  BusinessInformation,
  Invoice,
} from '@fidel.uk/store/lib/brand-user/brand-user-model';
import { toCurrency } from 'src/utils/transform';
import images from 'src/assets/data/images.json';

export default class BillingService {
  static generateInvoice = (
    invoice: Invoice,
    businessInformation: BusinessInformation,
  ) => {
    const pt = 0.75;
    const document = new jsPDF('p', 'pt', 'a4');

    document.setFont('Helvetica');
    document.setFontSize(60 * pt);
    document.setFontType('bold');
    document.setTextColor(0, 54, 80);
    document.text(t('billing.invoice.title'), 70 * pt, 90 * pt);

    document.setFontSize(20 * pt);
    document.setFontType('normal');
    document.text(moment.unix(invoice.date).format('ll'), 70 * pt, 140 * pt);

    document.setFontSize(13 * pt);
    document.setFontType('bold');
    document.text(
      t('billing.invoice.billedTo').toUpperCase(),
      250 * pt,
      210 * pt,
    );
    document.text(
      t('billing.invoice.titleNumber').toUpperCase(),
      720 * pt,
      210 * pt,
      { align: 'right' },
    );

    document.setFontSize(15 * pt);
    document.setFontType('normal');
    document.text(businessInformation.companyName, 250 * pt, 240 * pt);
    document.text(invoice.identifier, 720 * pt, 240 * pt, { align: 'right' });
    document.text(businessInformation.address, 250 * pt, 264 * pt);
    document.text(
      `${businessInformation.postcode}, ${businessInformation.city}`,
      250 * pt,
      288 * pt,
    );
    document.text(
      t(`countries.${businessInformation.countryCode}`),
      250 * pt,
      312 * pt,
    );
    document.text(businessInformation.companyNumber, 250 * pt, 336 * pt);

    document.setFontType('bold');
    document.text(
      t('billing.invoice.durationMessage', {
        start: moment.unix(invoice.startDate).format('ll'),
        end: moment
          .unix(invoice.date)
          .subtract('1', 'd')
          .format('ll'),
      }),
      250 * pt,
      408 * pt,
    );

    document.setFontSize(13 * pt);
    document.text(
      t('billing.invoice.dueDate').toUpperCase(),
      70 * pt,
      448 * pt,
    );
    document.text(
      t('billing.invoice.description').toUpperCase(),
      250 * pt,
      448 * pt,
    );
    document.text(t('billing.invoice.qty').toUpperCase(), 520 * pt, 448 * pt, {
      align: 'right',
    });
    document.text(t('billing.invoice.vat').toUpperCase(), 600 * pt, 448 * pt, {
      align: 'right',
    });
    document.text(
      t('billing.invoice.amount').toUpperCase(),
      720 * pt,
      448 * pt,
      { align: 'right' },
    );

    document.setDrawColor(0, 54, 80);
    document.setLineWidth(2);
    document.line(250 * pt, 464 * pt, 720 * pt, 464 * pt);
    document.line(250 * pt, 564 * pt, 720 * pt, 564 * pt);

    document.setFontSize(20 * pt);
    document.setFontType('normal');
    document.text(invoice.dueDate.format('ll'), 70 * pt, 488 * pt);

    document.setFontSize(15 * pt);

    document.text(t('billing.invoice.discount'), 250 * pt, 500 * pt);
    document.text('1', 520 * pt, 500 * pt, { align: 'right' });
    document.text(
      `${(invoice.cashback.vat > 0 && `${invoice.cashback.vat * 100}%`) ||
        '–'}`,
      600 * pt,
      500 * pt,
      { align: 'right' },
    );
    document.text(
      toCurrency(invoice.cashback.amount, invoice.currency),
      720 * pt,
      500 * pt,
      { align: 'right' },
    );

    document.text(t('billing.invoice.performanceFee'), 250 * pt, 535 * pt);
    document.text('1', 520 * pt, 535 * pt, { align: 'right' });
    document.text(
      `${(invoice.performanceFee.vat > 0 &&
        `${invoice.performanceFee.vat * 100}%`) ||
        '–'}`,
      600 * pt,
      535 * pt,
      { align: 'right' },
    );
    document.text(
      toCurrency(
        invoice.performanceFee && invoice.performanceFee.amount,
        invoice.currency,
      ),
      720 * pt,
      535 * pt,
      { align: 'right' },
    );

    document.text(t('billing.invoice.subtotal'), 600 * pt, 595 * pt, {
      align: 'right',
    });
    document.text(t('billing.invoice.vat'), 600 * pt, 625 * pt, {
      align: 'right',
    });
    document.text(
      toCurrency(
        invoice.performanceFee.amount + invoice.cashback.amount,
        invoice.currency,
      ),
      720 * pt,
      595 * pt,
      { align: 'right' },
    );
    document.text(
      toCurrency(
        invoice.performanceFee.amount * invoice.performanceFee.vat +
          invoice.cashback.amount * invoice.cashback.vat,
        invoice.currency,
      ),
      720 * pt,
      625 * pt,
      { align: 'right' },
    );

    document.setFontType('bold');
    document.text(t('billing.invoice.total'), 600 * pt, 660 * pt, {
      align: 'right',
    });

    document.setFontSize(20 * pt);
    document.text(
      toCurrency(invoice.amount, invoice.currency),
      720 * pt,
      660 * pt,
      { align: 'right' },
    );

    if (invoice.status === 'paid')
      document.addImage(
        images.stamp,
        'PNG',
        430 * pt,
        620 * pt,
        152 * pt,
        152 * pt,
      );

    document.addImage(
      images.fidel,
      'PNG',
      70 * pt,
      870 * pt,
      88 * pt,
      22.8 * pt,
    );

    document.setFontSize(15 * pt);
    document.setFontType('normal');

    document.text('4th Floor National House', 250 * pt, 880 * pt);
    document.text('60–66 Wardour St', 250 * pt, 904 * pt);
    document.text('W1F 0TA, London', 250 * pt, 928 * pt);
    document.text('United Kingdom', 250 * pt, 952 * pt);
    document.text('GB 155393006', 250 * pt, 976 * pt);

    document.text('Bank: HSBC UK', 450 * pt, 880 * pt);
    document.text('Account Nr: 5181 1290', 450 * pt, 904 * pt);
    document.text('Sort code: 40-05-20', 450 * pt, 928 * pt);
    document.text('IBAN: GB15 HBUK 4005 2051 8112 90', 450 * pt, 952 * pt);

    document.text(
      t('billing.invoice.mail', {
        email: 'accounts@fidel.uk',
        phone: '+44 20 3815 5770',
      }),
      250 * pt,
      1048 * pt,
    );

    document.setFontType('bold');
    document.text(t('billing.invoice.questions'), 250 * pt, 1024 * pt);

    document.save(t('billing.invoice.titleNumber') + invoice.identifier);

    return document;
  };
}
