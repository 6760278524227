import { State } from '@fidel.uk/store';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import StatusBar from 'src/app/components/status-bar';

const ConnectedStatusBar: React.FC = () => {
  const { t } = useTranslation();
  const [currentStatus, setCurrentStatus] = useState<any>();
  const { counter, status } = useSelector((state: State) => ({
    counter: state.status.counter,
    status: state.status.status,
  }));

  useEffect(() => {
    if (status) {
      const { code, level, message: statusMessage } = status;
      const message =
        statusMessage ||
        t(`status.${code}`, { defaultValue: null }) ||
        t(`status.${code}_${level.toUpperCase()}`, { defaultValue: null });
      if (message) setCurrentStatus({ message, type: level, key: counter });
    }
  }, [status, counter, t]);

  if (!currentStatus) return null;

  return (
    <StatusBar
      key={currentStatus.key}
      hideBtnLabel={t('statusBar.hide')}
      statusMessage={currentStatus.message || ''}
      statusType={currentStatus.type || ''}
    />
  );
};

export default ConnectedStatusBar;
