import React from 'react';
import { t } from 'i18next';

const BusinessTypeDropdown = React.forwardRef<any, {}>((props, ref) => {
  const options = [
    {
      value: 'Food & Drinks',
      label: t('offers.businessStep.form.businessType.foodDrinks'),
    },
    {
      value: 'Retail',
      label: t('offers.businessStep.form.businessType.retail'),
    },
    {
      value: 'Travel',
      label: t('offers.businessStep.form.businessType.travel'),
    },
  ];

  return (
    <div className="ui-dropdown">
      <select
        data-testid="business-type-selector"
        name="businessType"
        id="businessType"
        ref={ref}
      >
        <option disabled>
          {t('offers.businessStep.form.businessType.placeholder')}
        </option>
        {options.map(({ value, label }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </select>
    </div>
  );
});

export default BusinessTypeDropdown;
