import React from 'react';
import moment from 'moment';
import onClickOutside from 'react-onclickoutside';
import { t } from 'i18next';

import DatePicker from 'src/app/components/DatePicker';
import UICheckbox from 'src/style/ui-components/checkbox/checkbox';
import UIField from 'src/style/ui-components/field/field';
import {
  AmountOperator,
  DateRange,
  TransactionFilter,
} from 'src/utils/interfaces';
import { Form, FormLabel } from 'src/app/styled';
import { FilterBoxView } from '../styled/transaction-filter-box';

export interface TransactionFilterBoxProps {
  handleFiltersChange: (transactionFilter: TransactionFilterBoxState) => void;
  filters: TransactionFilter;
}

export interface TransactionFilterBoxState {
  focusedDateInput: string | null;
  dateRange: DateRange | any;
  cardScheme: string;
  amountFilter: { operator: AmountOperator; amount: number };
  dateChecked: boolean;
  cardSchemeChecked: boolean;
  amountChecked: boolean;
  filterBoxOpened: boolean;
  countActiveFilter: number;
}

export class TransactionFilterBox extends React.Component<
  TransactionFilterBoxProps,
  TransactionFilterBoxState
> {
  constructor(props: TransactionFilterBoxProps) {
    super(props);

    this.state = {
      focusedDateInput: null,
      dateRange: props.filters.dateRange || {},
      cardScheme: 'visa',
      amountFilter: {
        operator: AmountOperator.EQUAL,
        amount: 0,
      },
      dateChecked: true,
      cardSchemeChecked: false,
      amountChecked: false,
      filterBoxOpened: false,
      countActiveFilter: 1,
    };

    this.handleClear = this.handleClear.bind(this);
    this.handleDateRangeFocus = this.handleDateRangeFocus.bind(this);
    this.handleSelectDateRange = this.handleSelectDateRange.bind(this);
    this.selectPresetRange = this.selectPresetRange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleFilterCheckedChange = this.handleFilterCheckedChange.bind(this);
    this.handleAmountChange = this.handleAmountChange.bind(this);
    this.handleAmountOperatorChange = this.handleAmountOperatorChange.bind(
      this,
    );
    this.handleCardSchemeChange = this.handleCardSchemeChange.bind(this);
    this.toggleFilterBoxOpened = this.toggleFilterBoxOpened.bind(this);
  }

  handleClickOutside(e: any) {
    const { filterBoxOpened } = this.state;
    if (filterBoxOpened) this.toggleFilterBoxOpened(e);
  }

  private handleDateRangeFocus(field: string) {
    this.setState({
      focusedDateInput: field,
    });
  }

  private handleSelectDateRange(dateRange: DateRange) {
    const range: any = {};
    if (dateRange.startDate)
      range.startDate = moment(dateRange.startDate).startOf('day');

    if (dateRange.endDate)
      range.endDate = moment(dateRange.endDate).endOf('day');

    this.setState({ dateRange: range }, () => {
      if (!dateRange.startDate && !dateRange.endDate)
        this.setState({
          dateChecked: false,
        });
    });
  }

  private selectPresetRange(range?: { len: any; period: string }) {
    const dateRange: DateRange = {};
    if (range) {
      dateRange.startDate = moment().subtract(range.len, range.period);
      dateRange.endDate = moment();
    }
    this.setState({
      focusedDateInput: null,
    });
    this.handleSelectDateRange(dateRange);
  }

  private handleSubmit() {
    const {
      cardSchemeChecked,
      dateRange,
      dateChecked,
      amountChecked,
    } = this.state;
    const filters = [cardSchemeChecked, amountChecked];

    if (!dateRange.startDate && !dateRange.endDate)
      this.setState({
        dateChecked: false,
      });
    else filters.push(dateChecked);

    this.setState(
      {
        filterBoxOpened: false,
        countActiveFilter: filters.filter(f => f).length,
      },
      () => {
        const { handleFiltersChange } = this.props;
        handleFiltersChange(this.state);
      },
    );
  }

  private handleFilterCheckedChange(filterChecked: string) {
    const updatedState = { ...this.state };
    const { state } = this;
    updatedState[filterChecked] = !state[filterChecked];
    this.setState(updatedState);
  }

  private handleAmountChange(e: any) {
    const { amountFilter } = this.state;
    this.setState({
      amountFilter: {
        ...amountFilter,
        amount: parseFloat(e.target.value),
      },
    });
    e.preventDefault();
  }

  private handleAmountOperatorChange(e: any) {
    const { amountFilter } = this.state;
    this.setState({
      amountFilter: {
        ...amountFilter,
        operator: e.target.value,
      },
    });
    e.preventDefault();
  }

  private handleCardSchemeChange(e: any) {
    this.setState({
      cardScheme: e.target.value,
    });
    e.preventDefault();
  }

  private toggleFilterBoxOpened(e: any) {
    const { filterBoxOpened } = this.state;
    this.setState({
      filterBoxOpened: !filterBoxOpened,
    });
    e.preventDefault();
  }

  private handleClear(e: any) {
    this.setState(
      {
        dateChecked: false,
        cardSchemeChecked: false,
        amountChecked: false,
        filterBoxOpened: false,
        countActiveFilter: 0,
      },
      () => {
        const { handleFiltersChange } = this.props;
        handleFiltersChange(this.state);
      },
    );
    e.preventDefault();
  }

  render() {
    const filterSVG = require('src/assets/svg/filter_icon.svg');
    const cornerSVG = require('src/assets/svg/corner_down_icon.svg');
    const {
      countActiveFilter,
      dateChecked,
      amountChecked,
      filterBoxOpened,
      cardSchemeChecked,
      cardScheme,
      focusedDateInput,
      amountFilter,
      dateRange,
    } = this.state;

    return (
      <div className="transaction-filter-box-container">
        <button
          type="button"
          className="btn btn-white"
          data-testid="filter-box-btn"
          onClick={this.toggleFilterBoxOpened}
        >
          <img src={filterSVG} alt={t('transactions.filter.label')} />
          <span>{t('transactions.filter.label')}</span>
          {countActiveFilter > 0 ? (
            <span className="count-filter square">{countActiveFilter}</span>
          ) : null}
        </button>
        {filterBoxOpened ? (
          <FilterBoxView className="transaction-filter-box">
            <Form onSubmit={this.handleSubmit} inline>
              <div className="transaction-filter-box-header">
                <button
                  type="button"
                  className="btn btn-white"
                  data-testid="filter-box-clear-btn"
                  onClick={this.handleClear}
                >
                  {t('transactions.filter.clear')}
                </button>
                <span className="title">{t('transactions.filter.title')}</span>
                <button
                  className="btn"
                  type="submit"
                  data-testid="filter-submit-btn"
                >
                  {t('transactions.filter.apply')}
                </button>
              </div>
              <UIField>
                <UICheckbox
                  label={t('transactions.filter.date')}
                  name="date"
                  checked={!!dateChecked}
                  tabIndex={0}
                  handleChange={this.handleFilterCheckedChange.bind(
                    this,
                    'dateChecked',
                  )}
                />
                {dateChecked ? (
                  <div className="filter-container">
                    <DatePicker
                      handleChange={this.handleSelectDateRange}
                      handleFocus={this.handleDateRangeFocus}
                      dateRange={dateRange}
                      focusedInput={focusedDateInput}
                      selectPresetRange={this.selectPresetRange}
                    />
                  </div>
                ) : null}
              </UIField>
              <UIField>
                <UICheckbox
                  label={t('transactions.filter.amount.title')}
                  name="amount"
                  checked={!!amountChecked}
                  tabIndex={0}
                  handleChange={this.handleFilterCheckedChange.bind(
                    this,
                    'amountChecked',
                  )}
                />
                {amountChecked ? (
                  <div className="filter-container">
                    <div className="ui-dropdown">
                      <select
                        data-testid="amount-operator-selector"
                        defaultValue={amountFilter.operator}
                        onChange={this.handleAmountOperatorChange}
                        tabIndex={0}
                      >
                        <option value={AmountOperator.EQUAL}>
                          {t('transactions.filter.amount.equal')}
                        </option>
                        <option value={AmountOperator.GREATER}>
                          {t('transactions.filter.amount.greater')}
                        </option>
                        <option value={AmountOperator.LESS}>
                          {t('transactions.filter.amount.less')}
                        </option>
                      </select>
                    </div>
                    <div className="filter-amount-container">
                      <FormLabel htmlFor="amountInput">
                        <img
                          src={cornerSVG}
                          alt={t('transactions.filter.amount.title')}
                        />
                      </FormLabel>
                      <input
                        id="amountInput"
                        name="amount"
                        min={0}
                        type="number"
                        defaultValue={amountFilter.amount}
                        onChange={this.handleAmountChange}
                      />
                    </div>
                  </div>
                ) : null}
              </UIField>
              <UIField>
                <UICheckbox
                  label={t('transactions.filter.cardScheme')}
                  name="cardScheme"
                  checked={!!cardSchemeChecked}
                  tabIndex={0}
                  handleChange={this.handleFilterCheckedChange.bind(
                    this,
                    'cardSchemeChecked',
                  )}
                />
                {cardSchemeChecked ? (
                  <div className="filter-container">
                    <div className="ui-dropdown">
                      <select
                        data-testid="schemes-dropdown"
                        defaultValue={cardScheme}
                        onChange={this.handleCardSchemeChange}
                        tabIndex={0}
                      >
                        <option value="amex">American Express</option>
                        <option value="mastercard">Mastercard</option>
                        <option value="visa">Visa</option>
                      </select>
                    </div>
                  </div>
                ) : null}
              </UIField>
            </Form>
          </FilterBoxView>
        ) : null}
      </div>
    );
  }
}

export default onClickOutside(TransactionFilterBox);
