import React from 'react';
import { State } from '@fidel.uk/store';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { MINUS } from 'src/utils/special-characters';

interface OffersDropdownProps {
  handleSelect: (e: any) => void; // Not needed right now because only one offer
  selectValue: string;
}

const OffersDropdown = ({ handleSelect, selectValue }: OffersDropdownProps) => {
  const { t } = useTranslation();
  const { offers, programs, publisherName }: any = useSelector(
    (state: State) => ({
      offers: state.offers.offers.live.items,
      programs: state.brandUser.programs,
      publisherName: state.brandUser.details?.publisher?.name,
    }),
  );

  return (
    <div className="offers-select ui-dropdown">
      <select
        className="selector"
        onChange={(e: any) => handleSelect(e.target && e.target.value)}
        data-testid="offers-dropdown"
        defaultValue={selectValue || ''}
        style={{
          backgroundImage: `url(${require('src/assets/svg/offer_icon.svg')})`,
        }}
      >
        {offers?.length > 0 ? (
          offers.map(({ name, id }) => (
            <option key={id} value={`offer_${id}`}>
              {publisherName} {MINUS} {name}
            </option>
          ))
        ) : programs?.length > 0 ? (
          programs.map(({ name, programId }) => (
            <option key={programId} value={programId}>
              {name}
            </option>
          ))
        ) : (
          <option disabled value="">
            {t('notAvailable')}
          </option>
        )}
      </select>
    </div>
  );
};

export default OffersDropdown;
