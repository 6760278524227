import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { State } from '@fidel.uk/store';
import { toBusinessInfo } from 'src/utils/transform';

export default function useBusinessInfo() {
  const brandUserState = useSelector((state: State) => state.brandUser);
  const [businessInfo, setBusinessInfo]: any = useState({});

  useEffect(() => {
    const { businessInformation } = brandUserState;
    if (businessInformation)
      setBusinessInfo(toBusinessInfo(businessInformation));
  }, [brandUserState]);

  return businessInfo;
}
