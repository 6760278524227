import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { State } from '@fidel.uk/store';
import { Offer, PaymentType } from '@fidel.uk/store/lib/offers/offer-model';
import {
  CardCVCElement,
  CardExpiryElement,
  CardNumberElement,
  injectStripe,
} from 'react-stripe-elements';
import * as OfferActions from '@fidel.uk/store/lib/offers';

import LoadingScreen from 'src/app/components/loading-screen';
import LoadingSpinner from 'src/app/components/loading-spinner';
import UIField from 'src/style/ui-components/field/field';
import UIRadioButton from 'src/style/ui-components/radio-button/radio-button';
import { fidelCountries } from 'src/utils/countries';
import { Form, FormField, FormLabel } from 'src/app/styled';
import PaymentReminder from './payment-reminder';
import PaymentDescription from './payment-description';

export interface PaymentFormProps {
  handleCompletePaymentForm: () => any;
  handlePreviousStep: (...args: any) => any;
  currentOffer: Offer;
  stripe?: any;
}

const PaymentForm: React.FC<PaymentFormProps> = ({
  handleCompletePaymentForm,
  handlePreviousStep,
  currentOffer,
  stripe = {},
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { error, paymentCompleted } = useSelector((state: State) => ({
    error: state.offer.error,
    paymentCompleted: state.offer.paymentCompleted,
  }));
  const brandUserState = useSelector((state: State) => state.brandUser);
  const [email, setEmail] = useState();
  const [loading, setLoading] = useState(false);
  const [paymentType, setPaymentType] = useState(PaymentType.AUTOMATIC);
  const cardElementProps: any = {
    style: {
      base: {
        color: '#003650',
        lineHeight: '2.15',
        fontFamily: '"Poppins", "Helvetica Neue", sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '13px',
        '::placeholder': {
          color: '#c3c3c3',
        },
      },
      invalid: {
        color: '#e92424',
      },
    },
    classes: {
      base: 'field',
      complete: 'complete',
      empty: 'is-empty',
      focus: 'is-focused',
      invalid: 'is-invalid',
      webkitAutofill: 'webkit-autofill',
    },
  };

  const schemes = [
    { alt: 'Visa', icon: require('src/assets/svg/visa_icon.svg') },
    { alt: 'Mastercard', icon: require('src/assets/svg/mastercard_icon.svg') },
    { alt: 'American Express', icon: require('src/assets/svg/amex_icon.svg') },
  ];

  const creditCardSelectedLabel = (
    <div className="payment-stripe-container-label">
      <span className="text-label">
        {t('offers.paymentStep.form.paymentType.automatic.description')}
      </span>
      <div className="card-allowed">
        {schemes.map(scheme => (
          <img key={scheme.alt} src={scheme.icon} alt={scheme.alt} />
        ))}
      </div>
    </div>
  );

  useEffect(() => {
    if (loading) {
      if (paymentCompleted) handleCompletePaymentForm();
      if (error || paymentCompleted) setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, paymentCompleted, loading]);

  async function handleSubmit(e: any) {
    e.preventDefault();
    if (!loading) {
      const { businessInformation } = brandUserState;
      const countryCode = businessInformation.countryCode
        ? businessInformation.countryCode
        : 'GBR';
      const defaultCountry = fidelCountries[countryCode];
      const address: string[] = businessInformation.address
        ? businessInformation.address.split(', ')
        : null;

      setLoading(true);

      if (paymentType === PaymentType.AUTOMATIC) {
        const response = await stripe.createToken({
          type: 'card',
          name: currentOffer.name,
          address_line1: address?.length > 0 ? address[0] : '',
          address_line2: address?.length > 1 ? address[1] : '',
          address_city: businessInformation.city,
          address_zip: businessInformation.postcode,
          address_country: defaultCountry.code,
          currency: 'GBP',
        });

        if (response?.token)
          dispatch(
            OfferActions.processPayment(currentOffer, {
              stripeToken: response.token.id,
              type: PaymentType.AUTOMATIC,
            }),
          );
        else setLoading(false);
      } else
        dispatch(
          OfferActions.processPayment(currentOffer, {
            email,
            type: PaymentType.MANUAL,
          }),
        );
    }
  }

  return (
    <div className="business-form payment" data-testid="payment-form">
      {currentOffer && (
        <PaymentReminder
          currentOffer={currentOffer}
          handlePreviousStep={handlePreviousStep}
        />
      )}
      {currentOffer ? (
        <section>
          <div className="title-carousel">
            <h2>{t('offers.paymentStep.form.title')}</h2>
          </div>
          <Form onSubmit={handleSubmit} className="fidel-form payment" inline>
            <PaymentDescription currentOffer={currentOffer} />
            <div className="payment-selector">
              <div className="title">
                {t('offers.paymentStep.form.paymentType.title')}
              </div>
              <div className="payment-selector-container">
                <UIRadioButton
                  handleChange={() => setPaymentType(PaymentType.AUTOMATIC)}
                  checked={paymentType === PaymentType.AUTOMATIC}
                  value={PaymentType.AUTOMATIC}
                  id="stripe"
                  name="paymentType"
                  label={t(
                    'offers.paymentStep.form.paymentType.automatic.label',
                  )}
                />
                {paymentType === PaymentType.AUTOMATIC && (
                  <div className="payment-stripe-container">
                    {creditCardSelectedLabel}
                    <UIField
                      inline
                      label={t(
                        'offers.paymentStep.form.paymentType.automatic.cardNumber',
                      )}
                    >
                      <div className="ui-input-stripe">
                        <CardNumberElement {...cardElementProps} />
                      </div>
                    </UIField>
                    <UIField
                      inline
                      label={t(
                        'offers.paymentStep.form.paymentType.automatic.expiryDate',
                      )}
                    >
                      <div className="ui-input-stripe">
                        <CardExpiryElement {...cardElementProps} />
                      </div>
                    </UIField>
                    <UIField
                      inline
                      label={t(
                        'offers.paymentStep.form.paymentType.automatic.cvc',
                      )}
                    >
                      <div className="ui-input-stripe">
                        <CardCVCElement {...cardElementProps} />
                      </div>
                    </UIField>
                  </div>
                )}
                <UIRadioButton
                  handleChange={() => setPaymentType(PaymentType.MANUAL)}
                  checked={paymentType === PaymentType.MANUAL}
                  value={PaymentType.MANUAL}
                  id="manualPayment"
                  name="paymentType"
                  label={t('offers.paymentStep.form.paymentType.manual.label')}
                />
                {paymentType === PaymentType.MANUAL && (
                  <div className="payment-stripe-container manual-payment xl">
                    <p>
                      {t('offers.paymentStep.form.paymentType.manual.info')}
                    </p>
                    <FormField inline>
                      <FormLabel htmlFor="email">
                        {t(
                          'offers.paymentStep.form.paymentType.manual.email.label',
                        )}
                      </FormLabel>
                      <input
                        id="email"
                        name="email"
                        type="email"
                        onChange={(e: any) => setEmail(e.target.value)}
                        placeholder={t(
                          'offers.paymentStep.form.paymentType.manual.email.placeholder',
                        )}
                      />
                    </FormField>
                  </div>
                )}
              </div>
            </div>
            <div
              className={
                paymentType === PaymentType.AUTOMATIC
                  ? 'form-footer'
                  : 'form-footer right'
              }
            >
              {paymentType === PaymentType.AUTOMATIC && (
                <img
                  alt=""
                  src="/assets/img/poweredbystripe.png"
                  srcSet="/assets/img/poweredbystripe@2x.png 2x"
                />
              )}
              <button
                type="submit"
                className="btn with-padding right"
                data-testid="submit-offer"
              >
                {loading ? (
                  <LoadingSpinner color="white" />
                ) : (
                  <span>
                    {t('offers.paymentStep.form.paymentType.submit.label')}
                  </span>
                )}
              </button>
            </div>
          </Form>
        </section>
      ) : (
        <LoadingScreen />
      )}
    </div>
  );
};

export default injectStripe(PaymentForm);
