import React from 'react';
import onClickOutside from 'react-onclickoutside';
import { t } from 'i18next';
import { ExportProps } from 'src/utils/interfaces';

export class ExportButton extends React.Component<ExportProps, {}> {
  constructor(props: ExportProps) {
    super(props);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  handleClickOutside() {
    const { showSelectFormat } = this.props;
    showSelectFormat(false);
  }

  render() {
    const {
      selectFileFormatOpen,
      disabled,
      showSelectFormat,
      handleClickExport,
    } = this.props;
    return (
      <div className="export-button-container">
        <button
          type="button"
          data-testid="export-btn"
          className={`btn btn-white btn-with-icon btn-export${
            selectFileFormatOpen ? ' clicked' : ''
          }`}
          disabled={disabled}
          onClick={() => showSelectFormat(!selectFileFormatOpen)}
        >
          <div className="export-icon" />
          <span className="hide-on-xxs">{t('components.export.label')}</span>
        </button>

        {selectFileFormatOpen ? (
          <div
            className="dropdown-menu-container"
            onMouseLeave={() => showSelectFormat(false)}
          >
            <div>
              {/* TN: TODO when refactor done */}
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <span
                onClick={() => handleClickExport('pdf')}
                data-testid="export-pdf-btn"
              >
                {t('components.export.pdf')}
              </span>
            </div>
            <div>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <span
                onClick={() => handleClickExport('csv')}
                data-testid="export-csv-btn"
              >
                {t('components.export.csv')}
              </span>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default onClickOutside(ExportButton);
