import React from 'react';
import { State } from '@fidel.uk/store';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import StepsBox from 'src/app/components/steps-box';

interface OfferStepsProps {
  handleClose: () => any;
  handleClick: (...args: any) => any;
  stepCompleted: string;
}

const OfferSteps: React.FC<OfferStepsProps> = ({
  handleClose,
  handleClick,
  stepCompleted,
}) => {
  const { t } = useTranslation();
  const brandUser = useSelector((state: State) => state.brandUser.details);
  let steps = [
    {
      label: t('offers.steps.business'),
      active: !stepCompleted,
      succeed: ['business', 'offer', 'payment'].includes(stepCompleted),
      stepName: 'business',
      handleClick,
    },
    {
      label: t('offers.steps.offer'),
      active: stepCompleted === 'business',
      succeed: ['offer', 'payment'].includes(stepCompleted),
      stepName: 'offer',
      handleClick,
    },
  ];

  if (!brandUser?.stripe)
    steps = [
      ...steps,
      {
        label: t('offers.steps.payment'),
        active: stepCompleted === 'offer',
        succeed: stepCompleted === 'payment',
        stepName: 'payment',
        handleClick,
      },
    ];

  return <StepsBox steps={steps} handleClose={handleClose} />;
};

export default OfferSteps;
