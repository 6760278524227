import download from 'downloadjs';
import jsPDF from 'jspdf';
import * as transform from 'src/utils/transform';

require('jspdf-autotable');

interface ExportOptions {
  format: string;
  options: any[];
  programName: string;
  rawTransactions: any[];
  filters?: any;
}

class ExportService {
  options: any[] = [];

  export = ({
    format,
    options,
    programName,
    rawTransactions,
    filters,
  }: ExportOptions) => {
    this.options = options;
    const transactions = transform.toExportData(rawTransactions);
    const total = transform.toCurrency(
      rawTransactions.reduce((p, c) => p + parseFloat(c.amount || 0), 0),
      rawTransactions[0].currency,
    );

    let reportDate;
    let fileDate: string;

    const { dateRange }: { dateRange: any } = filters || {};

    if (!!dateRange && !!dateRange.startDate && !!dateRange.endDate) {
      const { startDate, endDate } = dateRange;
      reportDate = `${transform.toDate(
        startDate.toString(),
      )} – ${transform.toDate(endDate.toString())}`;
      fileDate = `${transform.toShortDate(
        startDate.toString(),
      )} – ${transform.toShortDate(endDate.toString())}`;
    } else {
      reportDate = fileDate = 'All transactions';
    }

    const fileName = `${programName} - Transactions Report: ${fileDate}`;

    if (format === 'pdf')
      this.createPDF(
        'Transactions',
        transactions,
        programName,
        fileName,
        reportDate,
        total,
      );
    else this.createCSV(transactions, programName, fileName);
  };

  createPDF = (
    type: string,
    rows: any[],
    programName: string,
    fileName: string,
    fileDate: string,
    total: string,
  ) => {
    const doc = new jsPDF('l', 'pt');
    const pageWidth = doc.internal.pageSize.getWidth();
    doc.setFontType('bold');
    doc.setFontSize(26);
    doc.text(`${programName}`, 40, 50);
    doc.setFontSize(15);
    doc.text(`Export: ${fileDate}`, 40, 90);
    doc.setDrawColor(0, 0, 0);
    doc.setLineWidth(2);
    doc.line(40, 110, 800, 110);
    doc.text(`Total ${type}`, 40, 140);
    doc.text(`${rows.length}`, pageWidth - 40, 140, null, null, 'right');

    let margin = 180;
    doc.text('Total Volume', 40, margin);
    doc.text(total, pageWidth - 40, margin, null, null, 'right');
    doc.line(40, margin + 20, 800, margin + 20);
    margin += 40;

    doc.autoTable(this.options, rows, {
      startY: margin,
      pageBreak: 'auto',
      margin: { top: 50 },
      showHead: 'firstPage',
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [127, 129, 131],
      },
    });
    doc.save(`${fileName}.pdf`);
  };

  createCSV = (transactions: any[], programName: string, fileName: string) => {
    const replacer = (key: string, value: string) =>
      value === null ? '' : value;
    const keys: string[] = [];
    const header: string[] = [];

    for (let i = 0; i < this.options.length; i++) {
      keys.push(this.options[i].dataKey);
      header.push(this.options[i].title);
    }

    const metadataKeys = Object.keys(
      transactions[0].metadata ? transactions[0].metadata : [],
    );

    for (let i = 0; i < metadataKeys.length; i++)
      header.push(`${programName} ${metadataKeys[i]}`);

    const csv = transactions.map((row: any) => {
      let csvRow = keys.map(fieldName =>
        JSON.stringify(row[fieldName], replacer),
      );

      if (row.metadata)
        csvRow = csvRow.concat(
          metadataKeys.map(fieldName =>
            JSON.stringify(row.metadata[fieldName], replacer),
          ),
        );

      return csvRow.join(',');
    });

    csv.unshift(header.join(','));
    const csvStr = csv.join('\r\n');
    download(new Blob([csvStr]), `${fileName}.csv`, 'text/csv');
  };
}

export default ExportService;
