import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { State } from '@fidel.uk/store';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as TransactionActions from '@fidel.uk/store/lib/transactions/transaction-actions';

import useOnScroll from 'src/hooks/use-on-scroll';
import ExportService from 'src/services/export-service';
import { ExportButton } from 'src/app/components/ExportButton';
import { TransactionFilter } from 'src/utils/interfaces';

import OffersDropdown from './components/offers-dropdown';
import TransactionFilterBox, {
  TransactionFilterBoxState,
} from './components/TransactionFilterBox';

const TransactionsHeader: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    bootstrapped,
    exportableTransactions,
    lastTransaction,
    loading,
    offers,
    programs,
    transactions,
    publisher,
  } = useSelector((state: State) => ({
    bootstrapped: state.offers.bootstrapped,
    exportableTransactions: state.transactions.exportableTransactions,
    lastTransaction: state.transactions.lastTransaction,
    loading: state.transactions.loading,
    offers: state.offers.offers.live.items,
    programs: state.brandUser.programs,
    transactions: state.transactions.transactions,
    publisher: state.brandUser.details?.publisher,
  }));
  const [filters, setFilters] = useState<TransactionFilter>({
    dateRange: {
      startDate: moment()
        .subtract(1, 'month')
        .set({
          minute: 0,
          hour: 0,
          second: 0,
          millisecond: 0,
        }),
      endDate: moment().set({
        minute: 59,
        hour: 23,
        second: 59,
        millisecond: 999,
      }),
    },
  });
  const [selectFormatVisible, setSelectFormatVisible] = useState(false);
  const [exportOptions, setExportOptions] = useState(null);
  const [exportEnabled, setExportEnabled] = useState(false);
  const [selectedOfferOrProgramId, setSelectedOfferOrProgramId] = useState(
    null,
  );
  const columnsOptions: any[] = [
    { title: t('transactions.label.card'), dataKey: 'lastNumbers' },
    { title: t('transactions.label.scheme'), dataKey: 'scheme' },
    { title: t('transactions.label.amount'), dataKey: 'amount' },
    { title: t('transactions.label.cashback'), dataKey: 'cashback' },
    {
      title: t('transactions.label.performanceFee'),
      dataKey: 'performanceFee',
    },
    { title: t('transactions.label.address'), dataKey: 'address' },
    { title: t('transactions.label.date'), dataKey: 'time' },
  ];
  const selectedOffer =
    (selectedOfferOrProgramId as string)?.includes('offer') &&
    selectedOfferOrProgramId.slice(6);

  function buildDefaultOptions() {
    const selectedProgram = programs.find(
      program => program.programId === selectedOfferOrProgramId,
    );

    let options: any;

    if (selectedOffer) options = { offerId: selectedOffer };
    else
      options = {
        brandId: selectedProgram?.brandId,
        programId: selectedOfferOrProgramId,
      };

    if (filters?.dateRange) {
      const { startDate, endDate } = filters.dateRange;
      if (startDate) options = { ...options, startDate: startDate.format() };

      if (endDate) options = { ...options, endDate: endDate.format() };
    }

    return options;
  }

  function handleFiltersChange(filterBox: TransactionFilterBoxState) {
    const updatedFilters: any = {};

    if (filterBox.countActiveFilter > 0) {
      if (filterBox.amountChecked)
        updatedFilters.amountFilter = filterBox.amountFilter;

      if (filterBox.cardSchemeChecked)
        updatedFilters.cardScheme = filterBox.cardScheme;

      if (filterBox.dateChecked) updatedFilters.dateRange = filterBox.dateRange;
    }

    setFilters(updatedFilters);
    dispatch(
      TransactionActions.filterTransactions(
        buildDefaultOptions(),
        updatedFilters,
      ),
    );
  }

  function handleClickExport(format: string) {
    let updatedExportOptions: any = { format };

    if (!selectedOffer) {
      const { name } = programs?.find(
        program => program.programId === selectedOfferOrProgramId,
      );
      updatedExportOptions = { ...updatedExportOptions, programName: name };
    }

    setSelectFormatVisible(false);
    setExportOptions(updatedExportOptions);

    if (selectedOfferOrProgramId) {
      setExportEnabled(true);
      dispatch(
        TransactionActions.getExportableTransactions(buildDefaultOptions()),
      );
    }
  }

  function createOfferName(programName) {
    const offer = selectedOffer && offers.find(o => o.id === selectedOffer);
    return (
      (offer && publisher && `${publisher.name} – ${offer.name}`) || programName
    );
  }

  function loadMoreTransactions() {
    if (!loading && lastTransaction && selectedOfferOrProgramId) {
      dispatch(
        TransactionActions.getTransactions({
          ...buildDefaultOptions(),
          last: lastTransaction,
        }),
      );
    }
  }

  useOnScroll({
    handleScroll: loadMoreTransactions,
    scrollableElement: document.querySelector('.transactions-wrapper'),
  });

  useEffect(() => {
    if (bootstrapped && programs) {
      if (offers.length > 0)
        setSelectedOfferOrProgramId(`offer_${offers[0].id}`);
      else setSelectedOfferOrProgramId(programs[0].programId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bootstrapped, programs]);

  useEffect(() => {
    if (selectedOfferOrProgramId && !lastTransaction) {
      const options = buildDefaultOptions();
      if ((options.brandId && options.programId) || options.offerId)
        dispatch(TransactionActions.getTransactions(options));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, selectedOfferOrProgramId, programs]);

  useEffect(() => {
    if (
      transactions.length < 100 &&
      selectedOfferOrProgramId &&
      lastTransaction
    )
      loadMoreTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastTransaction, selectedOfferOrProgramId]);

  useEffect(() => {
    if (exportEnabled && exportableTransactions.length > 0) {
      const exportService: ExportService = new ExportService();
      const { programName, format } = exportOptions;

      exportService.export({
        format,
        programName: createOfferName(programName),
        options: columnsOptions,
        rawTransactions: exportableTransactions,
        filters,
      });

      setExportEnabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportEnabled, exportableTransactions]);

  return (
    <div className="header-wrapper">
      <div className="transactions-header">
        <div className="transactions-header-left">
          <OffersDropdown
            selectValue={selectedOfferOrProgramId}
            handleSelect={programId => setSelectedOfferOrProgramId(programId)}
          />
          <TransactionFilterBox
            handleFiltersChange={handleFiltersChange}
            filters={filters}
          />
        </div>
        <ExportButton
          handleClickExport={handleClickExport}
          showSelectFormat={setSelectFormatVisible}
          disabled={!transactions || transactions.length === 0}
          selectFileFormatOpen={selectFormatVisible}
        />
      </div>
    </div>
  );
};
export default TransactionsHeader;
