import React from 'react';
import moment from 'moment';
import { FormattedNumber } from 'react-intl';
import { t } from 'i18next';

import {
  Transaction as TransactionModel,
  TransactionStatus,
} from '@fidel.uk/store/lib/transactions/transaction-model';

import * as show from 'src/utils/show';
import * as transform from 'src/utils/transform';

interface TransactionProps {
  data: TransactionModel;
  handleClick: (transaction: TransactionModel) => void;
}

const TransactionRow = ({ data, handleClick }: TransactionProps) => {
  const {
    scheme,
    lastNumbers,
    currency,
    amount,
    address,
    city,
    postcode,
    countryCode,
    status,
    date,
  } = data;
  const statusClasses = `pill${
    status === TransactionStatus.QUALIFIED ? ' qualified' : ''
  }${status === TransactionStatus.REJECTED ? ' rejected' : ''}`;

  const onClick = () => {
    handleClick(data);
  };

  return (
    <tr onClick={onClick} className="clickable">
      <td className="col-scheme">
        <img
          className="transaction-type-icon"
          src={show.iconFor(scheme)}
          alt={scheme}
        />
      </td>
      <td className={`col-amount${amount < 0 ? ' amount-negative' : ''}`}>
        <strong>
          <FormattedNumber value={amount} currency={currency} />
        </strong>
      </td>
      <td className="col-location">
        <span>
          {address}, {city}, {postcode}, {transform.toTwoDigit(countryCode)}
        </span>
      </td>
      <td className="col-card">{lastNumbers}</td>
      <td className="col-status">
        {status ? (
          <span className={statusClasses}>
            {t(`transactions.status.${status.toLowerCase()}`)}
          </span>
        ) : null}
      </td>
      <td className="col-date">
        <span className="date-time-group">
          {moment(date).format('DD/MM/YYYY HH:mm')}
        </span>
      </td>
    </tr>
  );
};

export default TransactionRow;
