import React from 'react';

interface EmptyProps {
  message: string;
}

const Empty: React.FC<EmptyProps> = ({ message }) => (
  <section className="empty-container">
    <img src={require('src/assets/svg/search_icon.svg')} alt="" />
    <strong>{message}</strong>
  </section>
);

export default Empty;
