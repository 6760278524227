import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { State } from '@fidel.uk/store/lib';

import BusinessForm from 'src/app/containers/offers/components/business-form';
import { Form, FormField, FormLabel } from 'src/app/styled';

const Account: React.FC = () => {
  const { t } = useTranslation();
  const { role, user } = useSelector((state: State) => ({
    role: state.authReducer.role,
    user: state.user.details,
  }));
  const isModerator = ['moderator', 'super'].includes(role);

  return (
    <AccountView className="main-wrapper account-view">
      <section className="account-container">
        <h2>
          <span>{t('account.profile')}</span>
          {isModerator && (
            <span className="moderator">{t('moderator.title')}</span>
          )}
        </h2>
        <Form className="fidel-form account">
          <div className="row">
            <FormField inline>
              <FormLabel htmlFor="nameFirst">
                {t('account.firstName.label')}
              </FormLabel>
              <input
                id="nameFirst"
                name="nameFirst"
                disabled
                defaultValue={user?.nameFirst || ''}
              />
            </FormField>
            <FormField inline>
              <FormLabel htmlFor="nameLast">
                {t('account.surname.label')}
              </FormLabel>
              <input
                id="nameLast"
                name="nameLast"
                disabled
                defaultValue={user?.nameLast || ''}
              />
            </FormField>
          </div>
          <FormField inline>
            <FormLabel htmlFor="email">{t('account.email.label')}</FormLabel>
            <input
              id="email"
              name="email"
              disabled
              defaultValue={user?.nameLast || ''}
            />
          </FormField>
        </Form>
      </section>
      <section className="account-container business-container">
        <BusinessForm inline />
      </section>
    </AccountView>
  );
};

const AccountView = styled.main`
  h2 {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  .moderator {
    background-color: ${p => p.theme.colors.gray};
    border-radius: 3px;
    color: ${p => p.theme.colors.white};
    display: inline-block;
    font-weight: 600;
    font-size: 9px;
    line-height: 13px;
    text-align: center;
    letter-spacing: 0.34px;
    padding: 1px 4px;
    text-transform: uppercase;
  }
  .account {
    padding: 0;

    .row {
      div:first-of-type {
        margin-right: 20px;
      }
    }

    input {
      height: inherit;
    }
    div {
      width: 100%;
    }
  }
`;

export default Account;
