import React from 'react';

interface UIInfoProps {
  info: string;
}

const UIInfo = ({ info }: UIInfoProps) => {
  const classNames = info.length > 20 ? 'info-tooltip large' : 'info-tooltip';
  return (
    <div className="ui-info">
      <div className="tooltip">
        <i className="icon-info-icon" />
        <div className={classNames} data-test="info">
          {info}
        </div>
      </div>
    </div>
  );
};

export default UIInfo;
