import moment from 'moment';
import { t } from 'i18next';
import React from 'react';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import AbstractFidelDatePicker from 'src/app/abstracts/AbstractFidelDatePicker';
import { DateRange } from 'src/utils/interfaces';
import * as show from 'src/utils/show';

import PresetRangePicker from './PresetRangePicker';

interface DatePickerProps {
  handleChange: (dateRange: DateRange) => void;
  handleFocus: (event: any) => void;
  selectPresetRange: (range?: { len: number; period: string }) => void;
  dateRange?: DateRange;
  focusedInput?: any;
}

export class DatePicker extends AbstractFidelDatePicker<DatePickerProps, {}> {
  render() {
    return (
      <div className="drp-wrapper">
        <DateRangePicker
          startDatePlaceholderText={t('transactions.label.startDate')}
          endDatePlaceholderText={t('transactions.label.endDate')}
          startDate={this.props.dateRange && this.props.dateRange.startDate}
          startDateId="startDate"
          endDate={this.props.dateRange && this.props.dateRange.endDate}
          endDateId="endDate"
          anchorDirection="right"
          calendarInfoPosition="top"
          renderCalendarInfo={() => (
            <PresetRangePicker
              selectPresetRange={this.props.selectPresetRange}
            />
          )}
          customArrowIcon="—"
          daySize={34}
          displayFormat="ll"
          firstDayOfWeek={1}
          focusedInput={this.props.focusedInput}
          hideKeyboardShortcutsPanel
          horizontalMargin={8}
          renderMonthText={this.renderMonth}
          isOutsideRange={day => day.isAfter(moment(), 'day')}
          navPrev={<img alt="" src={show.iconFor('arrowLeft')} />}
          navNext={<img alt="" src={show.iconFor('arrowRight')} />}
          numberOfMonths={1}
          onDatesChange={this.props.handleChange}
          onFocusChange={this.props.handleFocus}
        />
        <span className="select-dropdown" />
      </div>
    );
  }
}

export default DatePicker;
