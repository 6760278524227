const DEV_ENV = {
  STRIPE_KEY: 'pk_test_mADjGB6PjZCqyvAiRjT3qJBN',
};

const MOCK_ENV = {
  STRIPE_KEY: 'pk_test_mADjGB6PjZCqyvAiRjT3qJBN',
};

const PROD_ENV = {
  STRIPE_KEY: 'pk_live_8sBfjJ8o5skRkXptXx0LH8K2',
};

const environment =
  process.env.REACT_APP_STAGE === 'prod'
    ? PROD_ENV
    : process.env.REACT_APP_STAGE === 'mock'
    ? MOCK_ENV
    : DEV_ENV;

const config = {
  PASSWORD_MAX_LENGTH: 30,
  PASSWORD_MIN_LENGTH: 6,
  STRIPE_KEY: `${environment.STRIPE_KEY}`,
};

export default config;
