import React from 'react';
import jwtDecode from 'jwt-decode';
import useForm from 'react-hook-form';
import { State } from '@fidel.uk/store';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import * as AuthActions from '@fidel.uk/store/lib/auth/auth-actions';

import FormFields from 'src/app/components/form-fields';
import LoadingSpinner from 'src/app/components/loading-spinner';
import config from 'src/utils/config';
import Button from 'src/app/components/button';
import { Form } from 'src/app/styled';
import { Field, FieldType } from 'src/utils/interfaces';

const FIDEL_LOGO = require('src/assets/svg/FIDEL_logo_blue.svg');

enum TokenStatus {
  VALID,
  INVALID,
  EXPIRED,
}

function getTokenStatus(token: string) {
  let decodedToken: { exp: number };

  try {
    decodedToken = jwtDecode<typeof decodedToken>(token);
  } catch {
    return TokenStatus.INVALID;
  }

  const currentTime = new Date().getTime();
  const expirationTimestamp = 60 * 1000 * 10; // 10 minutes

  return decodedToken.exp * 1000 + expirationTimestamp < currentTime
    ? TokenStatus.EXPIRED
    : TokenStatus.VALID;
}

const ResetPassword: React.FC = () => {
  const dispatch = useDispatch();
  const { loading, passwordReset } = useSelector(
    (state: State) => state.authReducer,
  );
  const { t } = useTranslation();
  const { token } = useParams();
  const { errors, formState, handleSubmit, register, watch } = useForm({
    mode: 'onBlur',
  });
  const tokenStatus = getTokenStatus(token);
  const fields: Field[] = [
    {
      key: 'password',
      id: 'password',
      label: t('sign.label.password'),
      inline: true,
      noBorder: true,
      variant: 'sign',
      group: [
        {
          type: FieldType.input,
          props: {
            className: 'margin',
            key: 'password_input',
            error: !!errors.password,
            id: 'password',
            name: 'password',
            type: 'password',
            ref: register({
              required: true,
              minLength: config.PASSWORD_MIN_LENGTH,
              maxLength: config.PASSWORD_MAX_LENGTH,
            }),
          },
        },
        {
          type: FieldType.error,
          error: {
            key: 'error_password',
            hasError: !!errors.password,
            errorMessage: t('sign.label.passwordError', {
              min: 6,
              max: 30,
            }),
          },
        },
      ],
    },
    {
      key: 'password2',
      id: 'password2',
      label: t('sign.label.confirmPassword'),
      inline: true,
      noBorder: true,
      variant: 'sign',
      group: [
        {
          type: FieldType.input,
          props: {
            className: 'margin',
            key: 'password2_input',
            error: !!errors.password2,
            id: 'password2',
            name: 'password2',
            type: 'password',
            ref: register({
              required: true,
              validate: value => value === watch('password'),
            }),
          },
        },
        {
          type: FieldType.error,
          error: {
            key: 'error_password2',
            hasError: !!errors.password2,
            errorMessage: t('sign.label.passwordMatchError'),
          },
        },
      ],
    },
  ];

  function onSubmit({ password }: any) {
    dispatch(AuthActions.resetPassword(token, password));
  }

  if (
    tokenStatus === TokenStatus.INVALID ||
    (formState.isSubmitted && passwordReset)
  )
    return <Redirect to="/" />;

  return (
    <div className="sign-container">
      <div className="fidel-logo">
        <NavLink to="/">
          <img src={FIDEL_LOGO} alt="" />
        </NavLink>
      </div>

      <div className="sign-wrapper">
        <h1>{t('sign.in.welcome')}</h1>
        <p>{t('sign.in.resetPassword.title')}</p>

        {tokenStatus === TokenStatus.EXPIRED ? (
          <p>
            <Trans
              i18nKey="sign.in.resetPassword.message"
              components={[
                <NavLink to="/sign-in/forgot-password">here</NavLink>,
              ]}
            />
          </p>
        ) : (
          <Form
            className="full-width xl fidel-form"
            inline
            onSubmit={handleSubmit(onSubmit)}
          >
            <FormFields fields={fields} />
            <Button
              data-testid="reset-password-btn"
              type="submit"
              size="medium"
            >
              {loading ? (
                <LoadingSpinner color="white" />
              ) : (
                t('sign.label.submit')
              )}
            </Button>
          </Form>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;
