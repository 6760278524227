import {
  BusinessInformation,
  Invoice,
} from '@fidel.uk/store/lib/brand-user/brand-user-model';
import { t } from 'i18next';
import React from 'react';
import onClickOutside from 'react-onclickoutside';
import { NavLink } from 'react-router-dom';

import BillingComponent from 'src/app/abstracts/BillingComponent';

interface InvoiceActionBoxProps {
  businessInformation: BusinessInformation;
  invoice: Invoice;
}

interface InvoiceActionBoxState {
  boxVisible: boolean;
}

export class InvoiceActionBox extends BillingComponent<
  InvoiceActionBoxProps,
  InvoiceActionBoxState
> {
  constructor(props: InvoiceActionBoxProps) {
    super(props);

    this.state = {
      boxVisible: false,
    };

    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.showBox = this.showBox.bind(this);
  }

  handleClickOutside() {
    this.setState({
      boxVisible: false,
    });
  }

  render() {
    const { invoice } = this.props;
    const { boxVisible } = this.state;
    return (
      <div className="invoice-action-box-container">
        <button type="button" onClick={this.showBox}>
          <img
            src="/assets/img/more_actions_icon.svg"
            alt={t('billing.label.download')}
          />
        </button>
        {boxVisible ? (
          <div className="invoice-action-box">
            <button type="button" onClick={this.generateInvoice}>
              {t('billing.label.download')}
            </button>
            <NavLink to={`/billing/${invoice.identifier}`}>
              {t('billing.label.view')}
            </NavLink>
          </div>
        ) : null}
      </div>
    );
  }

  private showBox() {
    this.setState({
      boxVisible: true,
    });
  }
}

export default onClickOutside(InvoiceActionBox);
