import React from 'react';
import moment from 'moment';
import { State } from '@fidel.uk/store';
import { Offer } from '@fidel.uk/store/lib/offers/offer-model';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { toCurrency, toDays } from 'src/utils/transform';

interface PaymentReminder {
  currentOffer: Offer;
  handlePreviousStep: (...args: any) => any;
}

const PaymentReminder: React.FC<PaymentReminder> = ({
  currentOffer,
  handlePreviousStep,
}) => {
  const { t } = useTranslation();
  const publisher = useSelector(
    (state: State) => state.brandUser.details?.publisher,
  );
  const dateLabel =
    currentOffer && currentOffer.endDate
      ? t('offers.offer.date.duration')
      : t('offers.offer.date.start');

  return (
    <section className="reminder-container" data-test="payment-summary">
      <div className="reminder-content">
        <div className="title-container">
          <div className="title">{currentOffer.name}</div>
          <button
            type="button"
            data-testid="edit-offer"
            className="btn btn-white"
            onClick={handlePreviousStep}
          >
            {t('offers.paymentStep.summary.edit')}
          </button>
        </div>
        <ul>
          <li>
            <span className="label">
              {t('offers.offerStep.form.publisher.label')}
            </span>
            <span>{publisher.name}</span>
          </li>
          <li>
            <span className="label">
              {t('offers.offerStep.form.discount.label')}
            </span>
            <span>
              {currentOffer.type.name === 'amount'
                ? toCurrency(currentOffer.type.value, currentOffer.currency)
                : `${currentOffer.type.value}%`}
            </span>
          </li>
          <li>
            <span className="label">
              {t('offers.offerStep.form.minSpend.label')}
            </span>
            <span>{currentOffer.minTransactionAmount || t('na')}</span>
          </li>
          <li>
            <span className="label">
              {t('offers.offerStep.form.maxSpend.label')}
            </span>
            <span>{currentOffer.maxTransactionAmount || t('na')}</span>
          </li>
          <li>
            <span className="label">
              {t('offers.offerStep.form.daysOfWeek.label')}
            </span>
            <span>{toDays(currentOffer.daysOfWeek)}</span>
          </li>
          <li>
            <span className="label">
              {t('offers.offerStep.form.returnPeriod.label')}
            </span>
            <span>{currentOffer.returnPeriod || t('na')}</span>
          </li>
          <li>
            <span className="label">{dateLabel}</span>
            <span>
              {moment(currentOffer.startDate).format('ll')}
              {currentOffer.endDate &&
                ` - ${moment(currentOffer.endDate).format('ll')}`}
            </span>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default PaymentReminder;
