import React, { useEffect } from 'react';
import clearAll from '@fidel.uk/store/lib/clear-all';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '@fidel.uk/store';
import { getAllUsers } from '@fidel.uk/store/lib/moderator/moderator-actions';

import { MINUS } from 'src/utils/special-characters';

interface AccountDropdownProps {
  account: any;
  setAccount: (account: any) => any;
}

const AccountsDropdown: React.FC<AccountDropdownProps> = ({
  account,
  setAccount,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const moderator = useSelector((state: State) => state.moderator);
  const { items: accounts, loading } = moderator.accounts;

  useEffect(() => {
    if (accounts.length > 0 && !account) {
      setAccount(accounts[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts]);

  useEffect(() => {
    if (account) {
      dispatch(clearAll());
      dispatch(getAllUsers({ account }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  return (
    <div className="ui-dropdown">
      <select
        onChange={(e: any) => setAccount(e.target.value)}
        value={account || ''}
        data-testid="accounts-dropdown"
        className="selector"
        disabled={loading}
      >
        <option disabled value="">
          {t(loading ? 'moderator.loadingAccounts' : 'moderator.selectAccount')}
        </option>
        {accounts.map(({ id, name, nameFirst, nameLast }) => (
          <option key={id} value={id}>
            {name} {MINUS} {nameFirst} {nameLast}
          </option>
        ))}
      </select>
    </div>
  );
};

export default AccountsDropdown;
