import React from 'react';
import { t } from 'i18next';

const celebrationIcon = require('src/assets/svg/celebration_icon.svg');

const WelcomeBox = ({ user, hideMessage }: any) => (
  <section className="welcomebox">
    <img src={celebrationIcon} alt="" />
    <div>
      <h3>
        {t('components.welcome.title', {
          name: !!user && user.nameFirst,
        })}
      </h3>
      <p>{t('components.welcome.message')}</p>
      <button
        data-testid="welcome-hide-btn"
        type="button"
        className="btn with-padding"
        onClick={hideMessage}
      >
        {t('components.welcome.gotIt')}
      </button>
    </div>
  </section>
);

export default WelcomeBox;
