import React from 'react';

import UIInfo from 'src/style/ui-components/info/info';
import { Field, FieldGroup, FieldType } from 'src/utils/interfaces';

import {
  FormError,
  FormField,
  FormGroup,
  FormInputBox,
  FormLabel,
} from '../styled';

interface FormFieldsProps {
  fields: Field[];
}

const FormFields: React.FC<FormFieldsProps> = ({ fields }) => {
  function displayField({
    key,
    error,
    element,
    group,
    props = {},
    type,
  }: FieldGroup) {
    const { className } = props;
    switch (type) {
      case FieldType.element:
        return element;
      case FieldType.error:
        return (
          error.hasError && (
            <FormError key={error.key}>{error.errorMessage}</FormError>
          )
        );
      case FieldType.input:
        return (
          <input
            {...props}
            className={`${className || ''} ${props.error ? 'error' : ''}`}
          />
        );
      case FieldType.inputBox:
        return (
          <FormInputBox key={key} error={props.error}>
            {group.map(inputBoxField => displayField(inputBoxField))}
          </FormInputBox>
        );
      case FieldType.textarea:
        return <textarea {...props} />;
      default:
        return null;
    }
  }

  return (
    <>
      {fields.map(
        ({
          label,
          noBorder,
          group,
          id,
          inline,
          hidden,
          key,
          info,
          variant,
        }) => {
          return (
            !hidden && (
              <FormField key={key} inline={inline} variant={variant}>
                {label && (
                  <FormLabel htmlFor={id} variant={variant}>
                    {label}
                    {info && <UIInfo info={info} />}
                  </FormLabel>
                )}
                <FormGroup noBorder={noBorder} inline={inline}>
                  {group.map(field => displayField(field))}
                </FormGroup>
              </FormField>
            )
          );
        },
      )}
    </>
  );
};

export default FormFields;
