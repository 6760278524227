import React from 'react';

import BillingService from 'src/services/billing-service';

export interface BillingComponentProps {
  businessInformation: any;
  invoice: any;
}

export default abstract class BillingComponent<
  P extends BillingComponentProps,
  S
> extends React.Component<P, S> {
  constructor(props: P) {
    super(props);

    this.generateInvoice = this.generateInvoice.bind(this);
  }

  protected generateInvoice() {
    const { invoice, businessInformation } = this.props;
    BillingService.generateInvoice(invoice, businessInformation);
  }
}
