import styled from 'styled-components';

import {
  breakpoints,
  colors,
  input,
  Appearance,
  Size,
  ScreenSize,
  Variant,
} from '../../../theme/variables';

function getBg(variant?: Variant) {
  switch (variant) {
    case 'secondary':
      return colors.grey;
    case 'dark':
      return colors.primary;
    case 'white':
      return colors.white;
    case 'red':
      return colors.red;
    default:
      return colors.accent;
  }
}

function getFontColor(variant?: Variant) {
  return ['secondary', 'white'].includes(variant || '')
    ? colors.primary
    : colors.white;
}

function getHeight(size?: Size, screenSize: ScreenSize = 'smallScreen') {
  return size ? input.size[screenSize][size] : input.size[screenSize].normal;
}

function getFontSize(size?: string) {
  switch (size) {
    case 'small':
      return '14px';
    case 'large':
      return '18px';
    default:
      return input.fontSize;
  }
}

interface Button {
  appearance?: Appearance;
  hidden?: boolean;
  variant?: Variant;
  width?: string;
  size?: Size;
  screenSize?: ScreenSize;
}

const Button = styled.button.attrs(props => ({
  type: props.type || 'button',
}))<Button>`
  ${input.defaults}
  background: ${props => getBg(props.variant)};
  color: ${props => getFontColor(props.variant)};
  ${props => props.width && `width: ${props.width};`}
  max-width: 100%;
  height: ${props => getHeight(props.size, props.screenSize)};
  line-height: ${props => getHeight(props.size, props.screenSize)};
  white-space: nowrap;
  display: ${props => (props.hidden ? 'none' : 'inline-flex')};
  align-items: center;
  justify-content: space-between;
  font-size: ${props => getFontSize(props.size)};
  font-weight: 600;
  border: none;
  cursor: pointer;
  padding: ${props => (props.size === 'small' ? '0 10px' : '0 22px')};
  transition: opacity .3s ease;
  -webkit-appearance: none;
  
  ${props =>
    props.appearance === 'transparent' &&
    `
      background: ${colors.transparent};
      color: ${colors.primary};
      height: auto;
      padding: 0;
    `}
  
  ${props =>
    props.appearance === 'round' &&
    `
      border-radius: 50%;
      height: 38px;
      justify-content: center;
      padding: 0;
      width: ${props.width || '38px'};
    `}

  @media (min-width: ${breakpoints.above13inch}) {
    height: ${props => getHeight(props.size, 'largeScreen')};
  }

  &:hover,
  &:focus {
    opacity: .9;
    transition: opacity .3s ease;
    outline: none;
  }

  &:-moz-focus-outer {
    border: none;
  }

  &[disabled] {
    opacity: .5;
  }

  >:nth-child(2) {
    margin-left: 10px;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    align-items: baseline;

    svg {
      position: relative;
      top: 4px;
    }
  }
`;

export default Button;
