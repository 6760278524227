import React from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import moment from 'moment';
import enLocale from 'moment/locale/en-gb';
import frLocale from 'moment/locale/fr';
import ReactDOM from 'react-dom';
import { createStore } from '@fidel.uk/store';
import { I18nextProvider } from 'react-i18next';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import * as Sentry from '@sentry/browser';
import 'core-js/features/array/find';
import 'core-js/features/array/from';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import i18n from 'src/i18n';
import theme from 'src/theme';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
import './assets/fonts/fontastic.css';
import './style/compiled/style/index.css';

if (process.env.NODE_ENV !== 'production') {
  // NOTE: Enable only locally
  // const {whyDidYouUpdate} = require('why-did-you-update');
  // whyDidYouUpdate(React);
  // Activate only if checking Accessibility
  // const a11y = require('react-a11y').default;
  // a11y(React, ReactDOM, {
  //   rules: {
  //     'img-uses-alt': 'warn',
  //     'aria-role': 'warn',
  //     'tabindex-no-positive': 'warn',
  //     'mouse-events-have-key-events': 'warn',
  //     'aria-unsupported-elements': 'warn',
  //   }
  // });
}

Sentry.init({
  environment: process.env.REACT_APP_STAGE || 'dev',
  release: `${process.env.REACT_APP_NAME}-${process.env.REACT_APP_VERSION}`,
  dsn: 'https://57f8e282e112440e92fd46f56b852a42@sentry.io/1392569',
  debug: true,
});

if (i18n.language === 'fr') moment.updateLocale('fr', frLocale);
else moment.updateLocale('en', enLocale);

ReactDOM.render(
  <Provider store={createStore()}>
    <IntlProvider locale={i18n.language} defaultLocale="en">
      <MuiThemeProvider>
        <I18nextProvider i18n={i18n}>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </I18nextProvider>
      </MuiThemeProvider>
    </IntlProvider>
  </Provider>,
  document.getElementById('root') as HTMLElement,
);

serviceWorker.unregister();
