import styled from 'styled-components';

export const ModeratorContainer = styled.div`
  margin-bottom: 34px;

  .ui-dropdown {
    width: 100%;

    &:after {
      color: transparent !important;
      top: 5px;
      left: -25px;
      position: relative;
      background-repeat: no-repeat;
      background-position: left center;
      background-image: url('/assets/svg/dropdown_icon.svg');
      width: 25px !important;
      pointer-events: none;
    }

    &:after {
      color: ${p => p.theme.colors.darkBlue};
    }
  }

  label span {
    color: ${p => p.theme.colors.gray};
    font-weight: 600;
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 0.340909px;
    margin-bottom: 5px;
  }

  p {
    color: orange;
    margin-top: 10px;
  }

  select.selector {
    height: 40px;
    padding: 0 30px 0 20px;
    font-weight: 600;
    font-size: 13px;
    line-height: 28px;
    letter-spacing: 0.325px;

    &:last-of-type {
      margin-top: 10px;
    }
  }
`;
