import styled from 'styled-components';

export const FilterBoxView = styled.div`
  form {
    padding: 0;
  }

  .filter-amount-container {
    display: flex;

    label {
      width: auto;
    }

    input {
      width: 100%;
    }

    img {
      margin-right: 10px;
    }
  }
`;
