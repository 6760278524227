import React from 'react';

interface UICheckboxProps {
  name: string;
  checked?: boolean;
  round?: boolean;
  label?: any | undefined;
  handleChange?: any;
  tabIndex?: number;
}

const UICheckbox: React.FC<UICheckboxProps> = ({
  label,
  tabIndex,
  name,
  round,
  checked,
  handleChange,
}) => {
  const classes = `checkbox${round ? ' round' : ''}${
    checked ? ' checked icon-check' : ''
  }`;

  return (
    <div className="ui-checkbox">
      <label htmlFor={name}>
        <span className={classes} />
        {label ? <span className="label">{label}</span> : null}
      </label>
      <input
        type="checkbox"
        name={name}
        id={name}
        tabIndex={tabIndex}
        onClick={handleChange}
      />
    </div>
  );
};

export default UICheckbox;
