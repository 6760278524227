import React from 'react';
import { useTranslation } from 'react-i18next';

import Step from './step';

const closeIcon = require('src/assets/svg/close_icon.svg');

const StepsBox = ({ steps, handleClose }) => {
  const { t } = useTranslation();
  return (
    <div className="steps-box">
      <div className="steps">
        {steps.map((step: any, id) => (
          <Step key={id} step={step} id={id} />
        ))}
      </div>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className="close"
        onClick={handleClose}
        data-testid="steps-close-btn"
      >
        <img className="large-only" src={closeIcon} alt={t('close')} />
      </div>
    </div>
  );
};

export default StepsBox;
