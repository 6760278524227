import React from 'react';
import moment from 'moment';
import { Publisher } from '@fidel.uk/store/lib/brand-user/brand-user-model';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import * as transform from 'src/utils/transform';

export interface OfferCardProps {
  offer: any;
  publisher: Publisher;
  handleResume: () => any;
  handleDelete?: () => any;
  viewActive?: boolean;
}
const OfferCard: React.FC<OfferCardProps> = ({
  offer,
  publisher,
  viewActive = true,
  handleDelete,
  handleResume,
}) => {
  const { t } = useTranslation();
  const offerInfos = [
    {
      title: t('offers.offerStep.form.publisher.label'),
      definition: publisher.name,
    },
    {
      title: t('offers.offerStep.form.discount.label'),
      definition:
        offer.type.name === 'amount'
          ? transform.toCurrency(offer.type.value, offer.currency)
          : `${offer.type.value}%`,
    },
    {
      title: t('offers.offer.date.start'),
      definition: moment(offer.startDate).format('ll'),
    },
  ];

  return (
    <div className={`offer-card ${viewActive ? '' : 'draft'}`}>
      <div className="offer-title">
        <h4 data-test="offer-title">{offer.name}</h4>
        {offer.brandLogoURL && (
          <img
            className={viewActive || !handleDelete ? '' : 'no-hover'}
            src={offer.brandLogoURL}
            alt={offer.brandName}
          />
        )}
        <button
          type="button"
          data-testid={`offer-${offer.id}-btn-delete`}
          className="remove hover"
          onClick={handleDelete}
        >
          <img
            src={require('src/assets/svg/trash_icon.svg')}
            alt={t('offers.delete.draft.title')}
          />
        </button>
      </div>
      <dl data-test="offer-info" className="offer-info">
        {offerInfos.map(info => (
          <div key={info.title}>
            <dt className="title">{info.title}</dt>
            <dd>{info.definition}</dd>
          </div>
        ))}
      </dl>
      {viewActive ? (
        <NavLink
          data-testid={`offer-${offer.id}-btn-view`}
          to={`offers/view/${offer.id}`}
          className="btn btn-view"
        >
          {t('offers.offer.view')}
        </NavLink>
      ) : (
        <button
          type="button"
          data-testid={`offer-${offer.id}-btn-resume`}
          className="btn btn-resume"
          onClick={handleResume}
        >
          {t('offers.offer.resume')}
        </button>
      )}
    </div>
  );
};

export default OfferCard;
