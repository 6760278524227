import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

import FormFields from 'src/app/components/form-fields';
import BusinessTypeDropdown from 'src/app/containers/offers/components/business-type-dropdown';
import CountryDropdown from 'src/app/containers/offers/components/country-dropdown';
import PhoneCountrySelector from 'src/app/containers/offers/components/phone-country-selector';
import { fidelCountries } from 'src/utils/countries';
import { Field, FieldType } from 'src/utils/interfaces';

interface BusinessFormFieldsProps {
  businessInfo: any;
  errors: any;
  register: any;
  inline?: boolean;
  setValue?: (name: string, value: unknown) => any;
  watch?: (name: string) => any;
}

const BusinessFormFields: React.FC<BusinessFormFieldsProps> = ({
  businessInfo,
  errors,
  inline,
  register,
  setValue,
  watch,
}) => {
  const { t } = useTranslation();
  const phoneNumberRegex = '^[0-9]+$';
  const fields: Field[] = [
    {
      key: 'businessType',
      id: 'businessType',
      info: t('offers.businessStep.form.businessType.info'),
      inline,
      label: t('offers.businessStep.form.businessType.label'),
      group: [
        {
          type: FieldType.element,
          element: (
            <BusinessTypeDropdown
              key="business-type-dropdown"
              ref={register({ required: true })}
            />
          ),
        },
      ],
    },
    {
      key: 'description',
      id: 'description',
      info: t('offers.businessStep.form.description.info'),
      inline,
      label: t('offers.businessStep.form.description.label'),
      group: [
        {
          type: FieldType.textarea,
          props: {
            key: 'textarea_description',
            'data-testid': 'business-description',
            defaultValue: businessInfo.description,
            id: 'description',
            name: 'description',
            placeholder: t('offers.businessStep.form.description.placeholder'),
            required: true,
            ref: register({ minLength: 5, maxLength: 1000, required: true }),
            rows: 5,
          },
        },
        {
          type: FieldType.error,
          error: {
            key: 'error_description',
            hasError: !!errors.description,
            errorMessage: t(
              'offers.businessStep.form.description.errorMessage',
            ),
          },
        },
      ],
    },
    {
      key: 'name',
      id: 'name',
      info: t('offers.businessStep.form.businessNameAddress.info'),
      inline,
      label: t('offers.businessStep.form.businessNameAddress.label'),
      group: [
        {
          type: FieldType.input,
          props: {
            key: 'name_input',
            className: 'margin',
            error: !!errors.name,
            defaultValue: businessInfo.name,
            placeholder: t('offers.businessStep.form.businessName.placeholder'),
            id: 'name',
            name: 'name',
            required: true,
            ref: register({ minLength: 2, maxLength: 50, required: true }),
          },
        },
        {
          type: FieldType.input,
          props: {
            key: 'addressLine',
            className: 'margin',
            error: !!errors.addressLine,
            defaultValue: businessInfo.addressLine,
            placeholder: t(
              'offers.businessStep.form.address.addressLine1.placeholder',
            ),
            name: 'addressLine',
            required: true,
            ref: register({ minLength: 2, maxLength: 100, required: true }),
          },
        },
        {
          type: FieldType.input,
          props: {
            key: 'addressLine2',
            className: 'margin',
            defaultValue: businessInfo.addressLine2,
            placeholder: t(
              'offers.businessStep.form.address.addressLine2.placeholder',
            ),
            name: 'addressLine2',
            ref: register({
              minLength: 0,
              maxLength: 100,
            }),
          },
        },
        {
          type: FieldType.input,
          props: {
            key: 'city',
            className: 'margin',
            error: !!errors.city,
            defaultValue: businessInfo.city,
            placeholder: t('offers.businessStep.form.address.city.placeholder'),
            name: 'city',
            required: true,
            ref: register({
              minLength: 2,
              maxLength: 100,
              pattern: /^([A-Za-z'’ \u00C0-\u00D6\u00D8-\u00F6\u00F8-\u017F]*)$/i,
              required: true,
            }),
          },
        },
        {
          type: FieldType.error,
          error: {
            key: 'city_error',
            hasError: !!errors.city,
            errorMessage: t(
              'offers.businessStep.form.address.city.errorMessage',
            ),
          },
        },
        {
          type: FieldType.input,
          props: {
            key: 'postcode',
            className: 'margin',
            defaultValue: businessInfo.postcode,
            error: !!errors.postcode,
            placeholder: t(
              'offers.businessStep.form.address.postcode.placeholder',
            ),
            name: 'postcode',
            ref: register({ minLength: 4, maxLength: 20 }),
          },
        },
        {
          type: FieldType.error,
          error: {
            key: 'postcode_error',
            hasError: !!errors.postcode,
            errorMessage: t(
              'offers.businessStep.form.address.postcode.errorMessage',
            ),
          },
        },
        {
          type: FieldType.element,
          element: (
            <CountryDropdown
              key="country-dropdown"
              ref={register({ required: true })}
            />
          ),
        },
      ],
    },
    {
      key: 'vatNumber',
      id: 'vatNumber',
      info: t('offers.businessStep.form.vatNumber.info'),
      inline,
      label: t('offers.businessStep.form.vatNumber.label'),
      group: [
        {
          type: FieldType.input,
          props: {
            error: !!errors.vatNumber,
            key: 'vatNumber_input',
            defaultValue: businessInfo.vatNumber,
            id: 'vatNumber',
            name: 'vatNumber',
            required: watch('country') !== fidelCountries.USA.code,
            ref: register({
              minLength: 4,
              required: watch('country') !== fidelCountries.USA.code,
            }),
          },
        },
        {
          type: FieldType.error,
          error: {
            key: 'vatNumber_error',
            hasError: !!errors.vatNumber,
            errorMessage: t('offers.businessStep.form.vatNumber.errorMessage'),
          },
        },
      ],
    },
    {
      key: 'companyNumber',
      id: 'companyNumber',
      info: t('offers.businessStep.form.phone.info'),
      inline,
      label: t('offers.businessStep.form.phone.label'),
      noBorder: true,
      group: [
        {
          type: FieldType.inputBox,
          key: 'companyNumber_inputBox',
          group: [
            {
              type: FieldType.element,
              element: (
                <PhoneCountrySelector
                  key="phone-country-selector"
                  className="first"
                  phoneCountry={watch('phoneCountry')}
                  ref={register({ required: true })}
                />
              ),
            },
            {
              type: FieldType.element,
              element: watch('phoneCountry') && (
                <span className="padding" key="phone-country-text">
                  {fidelCountries[watch('phoneCountry')].phone}
                </span>
              ),
            },
            {
              type: FieldType.input,
              props: {
                key: 'phone_input',
                className: 'last',
                error: !!errors.companyNumber,
                defaultValue: businessInfo.companyNumber,
                id: 'companyNumber',
                name: 'companyNumber',
                type: 'tel',
                pattern: phoneNumberRegex,
                required: true,
                ref: register({
                  minLength: 7,
                  pattern: phoneNumberRegex,
                  required: true,
                }),
              },
            },
          ],
        },
        {
          type: FieldType.error,
          error: {
            key: 'companyNumber_error',
            hasError: !!errors.companyNumber,
            errorMessage: t('offers.businessStep.form.phone.errorMessage'),
          },
        },
      ],
    },
  ];

  useEffect(() => {
    if (!isEmpty(businessInfo)) {
      setValue('businessType', businessInfo.businessType);
      setValue('country', businessInfo.country || 'GBR');
      setValue('phoneCountry', businessInfo.phoneCountry || 'GBR');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessInfo]);

  return <FormFields fields={fields} />;
};

export default BusinessFormFields;
