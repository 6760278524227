export const fidelCountries = {
  IRL: {
    phone: '+353',
    flag: require('src/assets/svg/countries/ireland.svg'),
    code: 'IRL',
    currency: 'EUR',
  },
  GBR: {
    phone: '+44',
    flag: require('src/assets/svg/countries/united-kingdom.svg'),
    code: 'GBR',
    currency: 'GBP',
  },
  USA: {
    phone: '+1',
    flag: require('src/assets/svg/countries/united-states-of-america.svg'),
    code: 'USA',
    currency: 'USD',
  },
  SWE: {
    phone: '+46',
    flag: require('src/assets/svg/countries/sweden.svg'),
    code: 'SWE',
    currency: 'SEK',
  },
  // AUS: {
  //   name: 'Australia',
  //   phone: '+61',
  //   flag: require(  'src/assets/svg/countries/australia.svg'),
  //   code: 'AUS'
  // },
  CAN: {
    phone: '+1',
    flag: require('src/assets/svg/countries/canada.svg'),
    code: 'CAN',
    currency: 'CAD',
  },
  JPN: {
    phone: '+81',
    flag: require('src/assets/svg/countries/japan.svg'),
    code: 'JPN',
    currency: 'JPY',
  },
};

export const languages = {
  fr: {
    flag: require('src/assets/svg/countries/canada.svg'),
  },
  en: {
    flag: require('src/assets/svg/countries/united-kingdom.svg'),
  },
};
