import React from 'react';
import { t } from 'i18next';

export default class BillingBankDetails extends React.Component {
  render() {
    return (
      <div className="bank-details">
        <p>{t('billing.bank.message')}</p>
        <div className="bank">
          <img
            src="/assets/img/HSBC_UK.png"
            srcSet="/assets/img/HSBC_UK.png, /assets/img/HSBC_UK@2x.png 2x"
            alt="HSBC UK Logo"
          />
          <dl>
            <div className="inline">
              <dt>{t('billing.bank.name')}</dt>
              <dd>HSBC UK</dd>
            </div>
            <div className="inline">
              <dt>{t('billing.bank.account')}</dt>
              <dd>5181 1290</dd>
            </div>
            <div className="inline">
              <dt>{t('billing.bank.sortCode')}</dt>
              <dd>40-05-20</dd>
            </div>
            <div className="inline">
              <dt>{t('billing.bank.currency')}</dt>
              <dd>GBP</dd>
            </div>
          </dl>
        </div>
      </div>
    );
  }
}
