import React from 'react';
import moment, { Moment } from 'moment';
import i18next from 'src/i18n';

import { firstLetterUp } from 'src/utils/transform';

export default abstract class AbstractFidelDatePicker<
  P,
  S
> extends React.Component<P, S> {
  protected renderMonth(m: Moment) {
    return firstLetterUp(
      moment(m)
        .locale(i18next.language)
        .format('MMMM'),
    );
  }
}
