import { Moment } from 'moment';

export enum AmountOperator {
  EQUAL = '==',
  GREATER = '>',
  LESS = '<',
}

export interface DateRange {
  startDate?: Moment | null;
  endDate?: Moment | null;
}

export interface TransactionFilter {
  status?: string;
  amountFilter?: { operator: AmountOperator; amount: number };
  cardScheme?: string;
  dateRange?: DateRange;
}

export interface ExportProps {
  handleClickExport: (format: string) => void;
  showSelectFormat: (show: boolean) => void;
  disabled: boolean;
  selectFileFormatOpen: boolean;
}

export interface FidelRoute {
  path: string;
  label: any;
}

export interface Form {
  handleForm: (e: any) => void;
  handleFormError: () => void;
  isFormValid: () => boolean;
  checkForm?: (error: boolean) => void;
}

export enum FieldType {
  element,
  error,
  input,
  inputBox,
  textarea,
}

export interface FieldGroup {
  type: FieldType;
  element?: any;
  error?: { hasError: boolean; errorMessage: string; key?: string };
  props?: any;
  group?: FieldGroup[];
  key?: string;
}

type VariantType = 'sign';

export interface Field {
  id: string;
  group: FieldGroup[];
  hidden?: boolean;
  info?: string;
  inline?: boolean;
  key?: string;
  label?: string;
  noBorder?: boolean;
  variant?: VariantType;
}
