import styled, { css } from 'styled-components';
import { breakpoints } from 'src/theme/variables';

export const Form = styled.form<any>`
  padding: ${p => (!p.inline ? `20px 30px` : `0`)};

  input,
  select,
  textarea {
    flex: 1 1 auto;
    display: flex;
    border-radius: ${p => p.theme.borderRadius};
    background-color: ${p => p.theme.colors.white};
    border: ${p => p.theme.baseBorder};
    font-size: ${p => p.theme.fontSizes.input}px;
    font-family: ${p => p.theme.bodyFont};
    line-height: 1.87;
    letter-spacing: 0.4px;
    padding: 10px 20px;
    color: ${p => p.theme.colors.darkBlue};
    width: 100%;

    &::placeholder {
      color: ${p => p.theme.colors.placeholder};
    }

    &[disabled] {
      background-color: ${p => p.theme.colors.lightGray4} !important;
    }

    &.error {
      border-color: ${p => p.theme.colors.red};
    }
  }

  input,
  select {
    height: ${p => (p.inline ? p.theme.inputSize[2] : p.theme.inputSize[0])}px;
  }

  .ui-checkbox input,
  .ui-radio-button input {
    display: none;
  }

  .ui-input input {
    border: none;
  }

  select {
    padding: 0 20px;
    width: 100%;
  }

  .discount {
    width: revert;
  }

  button {
    justify-content: center;
  }
`;

const labelInlineCSS = css`
  label {
    padding: 0;
    justify-content: flex-start;
  }
`;

const formFieldInlineCSS = css`
  padding: 20px 0;
  flex-flow: column !important;
  flex-direction: column;

  ${labelInlineCSS}
`;

export const FormField = styled.div<any>`
  display: flex;
  flex-flow: column wrap;
  ${props => props.alignItems && `align-items: ${props.alignItems};`}

  @media (min-width: ${props => props.breakpoint || breakpoints.tablet}) {
    flex-flow: ${props => props.flow || 'row nowrap'};
    justify-content: ${props => props.justifyContent || 'space-between'};
    >* {
      ${props =>
        props.columns && `max-width: calc(${100 / props.columns}% - 20px);`}
    }
  }
  
  width: ${p => (p.inline ? '50%' : '100%')};
  ${p => p.inline && formFieldInlineCSS}

  label {
    align-self: baseline;
  }

  .ui-dropdown {
    flex: 1 1 auto;
  }

  .ui-checkbox label {
    justify-content: flex-start;
  }

  @media (max-width: ${p => p.theme.breakpoints.tablet}) {
    width: 100%;
    border-bottom: ${p => p.theme.baseBorder};
    ${formFieldInlineCSS}
  }

  ${p =>
    p.variant === 'sign' &&
    `
      border-bottom: none !important;
      padding: 0 !important;
      width: 100%;

      p {
        font-size: ${p.theme.fontSizes.input}px;
      }
    `}
`;

export const FormGroup = styled.div<any>`
  flex: 1 1 auto;

  @media (min-width: ${p => p.theme.breakpoints.tablet}) {
    border-bottom: ${p => (!p.noBorder && p.theme.baseBorder) || '0'};
    padding: 30px 30px 30px 0;
  }
  
  ${p =>
    p.inline &&
    css`
      padding: 0 !important;
      border-bottom: none !important;
    `}

  .margin {
      margin-bottom: 8px;
    }
  }
`;

export const FormFooter = styled.div<any>`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 20px 0;
  width: 100%;

  button {
    justify-content: center;
    img {
      margin-left: 10px;
    }
  }

  .offer-footer {
    display: flex;
    button:not(:last-of-type) {
      margin-right: 20px;
    }
  }

  @media (min-width: ${p => p.theme.breakpoints.tablet}) {
    border-top: ${p => p.theme.baseBorder};
  }

  @media (max-width: ${p => p.theme.breakpoints.tablet}) {
    flex-direction: column-reverse;

    .offer-footer {
      flex-direction: column;
      margin-top: 20px;
      button:not(:last-of-type) {
        margin-bottom: 20px;
        margin-right: 0;
      }
    }

    button {
      width: 100% !important;

      &:not(:first-of-type) {
        margin-bottom: 20px !important;
      }
    }
  }

  ${p =>
    p.inline &&
    css`
      border-top: none;
    `}
`;

export const FormInputBox = styled.div<any>`
  align-items: center;
  display: flex;
  background: white;
  border: ${p => p.theme.baseBorder};
  border-radius: ${p => p.theme.borderRadius};
  font-size: ${p => p.theme.fontSizes.input}px;
  justify-content: flex-start;

  ${p =>
    p.error &&
    css`
      border-color: ${p.theme.colors.red};
    `}

  * {
    background-color: transparent !important;
    border: none !important;

    .first {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .last {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:not(.last):not(.first) {
      border-radius: 0;
    }
  }

  input {
    flex: 4 1 auto;
  }

  .padding {
    padding-left: 10px;
  }

  .ui-dropdown {
    border-right: ${p => p.theme.baseBorder};
    min-width: 65px;

    .phone-country-selector {
      background-color: transparent;
      color: transparent;
    }
  }
`;

export const FormLabel = styled.label<{ variant?: string }>`
  justify-content: flex-end;
  display: flex;
  max-width: ${p => (p.variant === 'sign' ? 'initial' : '160px')};
  width: 100%;
  padding: 30px 20px 30px 0;
`;

export const FormError = styled.p<any>`
  text-align: right;
  color: ${p => p.theme.colors.red};
`;

export const Currency = styled.span`
  align-items: center;
  border-right: ${p => p.theme.baseBorder} !important;
  display: flex;
  font-size: ${p => p.theme.fontSizes.symbol}px;
  height: ${p => p.theme.inputSize[0]}px;
  padding: 0 20px;
`;
