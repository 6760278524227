import React from 'react';

import SignUpForm from './components/sign-up-form';

const FIDEL_LOGO = require('src/assets/svg/FIDEL_logo_blue.svg');

const SignUp: React.FC = () => {
  return (
    <div className="sign-container">
      <img src={FIDEL_LOGO} alt="" />
      <div className="sign-wrapper">
        <SignUpForm />
      </div>
    </div>
  );
};

export default SignUp;
