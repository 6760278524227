import React from 'react';
import CircularProgress from 'material-ui/CircularProgress';
import styled from 'styled-components';

interface LoadingSpinnerProps {
  color: string;
  size?: number;
  thickness?: number;
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
  color,
  size = 24,
  thickness = 3,
}) => {
  const spinnerProps = { color, size, thickness };
  return (
    <LoadingSpinnerContainer data-testid="loading-spinner" size={size}>
      <CircularProgress {...spinnerProps} />
    </LoadingSpinnerContainer>
  );
};

const LoadingSpinnerContainer = styled.div<any>`
  display: flex;
  align-items: baseline;
  height: ${p => p.size}px;
  width: ${p => p.size}px;

  div {
    display: flex !important;
  }
`;

export default LoadingSpinner;
