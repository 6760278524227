import React, { useEffect } from 'react';
import clearAll from '@fidel.uk/store/lib/clear-all';
import { sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '@fidel.uk/store';
import { getUserFromData } from '@fidel.uk/store/lib/user/user-actions';

import { MINUS } from 'src/utils/special-characters';

interface UsersDropdownProps {
  currentUser: any;
  setCurrentUser: (user: any) => any;
}

const UsersDropdown: React.FC<UsersDropdownProps> = ({
  currentUser,
  setCurrentUser,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const moderator = useSelector((state: State) => state.moderator);
  const { items: users, loading } = moderator.users;
  const sortedUsersWithoutSkipInvite = sortBy(users, ['brandName']).filter(
    ({ skipInvite }) => !skipInvite,
  );

  useEffect(() => {
    if (sortedUsersWithoutSkipInvite.length > 0 && !currentUser) {
      setCurrentUser(sortedUsersWithoutSkipInvite[0].brandUserId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  useEffect(() => {
    const selectedUser = sortedUsersWithoutSkipInvite.find(
      ({ brandUserId }) => brandUserId === currentUser,
    );
    if (selectedUser) {
      const { userId: user } = selectedUser;
      dispatch(clearAll());
      dispatch(getUserFromData(user, true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  return loading || users.length > 0 ? (
    <div className="ui-dropdown">
      <select
        onChange={(e: any) => setCurrentUser(e.target.value)}
        value={currentUser || ''}
        data-testid="users-dropdown"
        className="selector"
        disabled={loading}
      >
        <option disabled value="">
          {t(loading ? 'moderator.loadingUsers' : 'moderator.selectUser')}
        </option>
        {sortedUsersWithoutSkipInvite.map(
          ({ nameFirst, brandUserId, brandName, nameLast }) => (
            <option key={brandUserId} value={brandUserId}>
              {brandName} {MINUS} {nameFirst} {nameLast}
            </option>
          ),
        )}
      </select>
    </div>
  ) : (
    <p>{t('moderator.usersNotFound')}</p>
  );
};

export default UsersDropdown;
