import { Source } from '@fidel.uk/store/lib/brand-user/brand-user-model';
import { t } from 'i18next';
import React from 'react';

interface BillingCardDetailsProps {
  source: Source;
}

const BillingCardDetails: React.FC<BillingCardDetailsProps> = ({ source }) => {
  const { brand, last4, expMonth, expYear } = source;
  return (
    <div className="card-details">
      <img
        className="card-image"
        src={`/assets/img/${brand}_card.png`}
        srcSet={`/assets/img/${brand}_card@2x.png 2x`}
        alt={brand}
      />
      <div className="card">
        <div className={`last4 ${brand}`}>{last4}</div>
        <div className="expiry-container">
          <span className="exp-label">{t('billing.expiryLabel')}</span>
          <span className="exp">
            {expMonth} / {expYear}
          </span>
        </div>
      </div>
    </div>
  );
};

export default BillingCardDetails;
