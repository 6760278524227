import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { isEqual, isEmpty } from 'lodash';
import { Offer } from '@fidel.uk/store/lib/offers/offer-model';
import { t } from 'i18next';

import { fidelCountries } from './countries';

export const firstLetterUp = (str: string) => {
  const s = str.toString();
  return `${s.slice(0, 1).toUpperCase()}${s.slice(1)}`;
};

export const toDecimal = (num: number) =>
  num.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');

export const symbols = {
  EUR: '€',
  GBP: '£',
  CAD: '$',
  USD: '$',
  JPY: '¥',
  SEK: 'kr',
};

export const toCurrency = (amount = 0, currency: string) =>
  `${symbols[currency] || currency} ${toDecimal(amount)}`;

export const toDate = (date: string) =>
  moment(date).format('DD/MM/YYYY HH:mm:ss');

export const toShortDate = (date: string) => moment(date).format('DD-MM-YYYY');

export const toInitials = (user: any) =>
  `${user.nameFirst[0]}${user.nameLast[0]}`;

export const toAccountData = (user: any) => {
  const brand =
    !!user.programs && user.programs.length >= 1 && user.programs[0].brands[0];
  return brand
    ? { name: brand.name, initials: brand.name[0], id: user.accountId }
    : {
        name: `${user.nameFirst} ${user.nameLast}`,
        initials: toInitials(user),
        id: user.accountId,
      };
};

export const toMessage = (error: any) =>
  typeof error === 'string' ? error : error.description || 'Unexpected error';

export const actionToErrorMsg = (action: any) =>
  action.error &&
  action.error.response &&
  ((action.error.response.error && action.error.response.error.message) ||
    (action.error.response.data &&
      action.error.response.data.error &&
      action.error.response.data.error.message));

export const toParams = (options: any) =>
  options.params
    ? Object.keys(options.params)
        .map(k => `${k}=${options.params[k]}`)
        .join('&')
    : null;

export const toPrograms = (originalPrograms: any[]) =>
  originalPrograms
    .map(program =>
      program.brands.map(brand => ({
        name: `${brand.name} – ${program.name}`,
        brandId: brand.id,
        programId: program.id,
        pairId: `${brand.id}_${program.id}`,
      })),
    )
    .reduce((prev, curr) => [...prev, ...curr], []);

export const toSignUpData = (token: string) => {
  try {
    const decoded = jwtDecode(token);
    return {
      accountName: decoded.account.name,
      brandName: decoded.brand.name,
      programName: decoded.program.name,
      nameFirst: decoded.user.nameFirst,
      email: decoded.user.email,
    };
  } catch {
    return null;
  }
};

export const toTwoDigit = (countryCode: string) => {
  const countryCodes = {
    CAN: 'CA',
    GBR: 'UK',
    IRL: 'IE',
    JPN: 'JP',
    SWE: 'SE',
    USA: 'US',
  };
  return countryCodes[countryCode] || countryCode;
};

export const toExportData = (transactions: any[]) =>
  transactions.map(tr => ({
    lastNumbers: `****${tr.lastNumbers ||
      (tr.card && tr.card.lastNumbers) ||
      ''}`,
    scheme: firstLetterUp(tr.scheme || (tr.card && tr.card.scheme) || ''),
    amount: toDecimal(tr.amount || 0),
    address:
      tr.location && tr.location.address
        ? `${tr.location.address}, ${tr.location.city}, ${
            tr.location.postcode
          }, ${toTwoDigit(tr.location.countryCode)}`
        : `${tr.address}, ${tr.city}, ${tr.postcode}, ${toTwoDigit(
            tr.countryCode,
          )}`,
    time: toDate(tr.datetime || tr.date),
    cashback: (tr.offer && toDecimal(tr.offer.cashback)) || '',
    performanceFee: (tr.offer && toDecimal(tr.offer.performanceFee)) || '',
  }));

export const toDays = (daysOfWeek: number[] = []) => {
  const daysCode = {
    0: 'sunday',
    1: 'monday',
    2: 'tuesday',
    3: 'wednesday',
    4: 'thursday',
    5: 'friday',
    6: 'saturday',
  };

  let days = '';

  daysOfWeek.forEach(day => {
    days += `${t(`days.${daysCode[day]}`).slice(0, 3)} `;
  });

  return days.trim().replace(RegExp(' ', 'g'), ', ');
};

export const toBusinessInfo = (businessInfo: any) => {
  const {
    address,
    postcode,
    businessType,
    countryCode,
    vatNumber,
    companyNumber,
    companyName,
    description,
    city,
  } = businessInfo;
  const addresses: string[] = address && address.split(', ');
  const newCountry = countryCode || 'GBR';
  let newPhoneCountry;

  Object.keys(fidelCountries).forEach(key => {
    if (
      businessInfo.companyNumber.slice(0, 4).includes(fidelCountries[key].phone)
    )
      if (!newPhoneCountry) {
        newPhoneCountry = key;
        if (
          fidelCountries[newCountry].phone ===
          fidelCountries[newPhoneCountry].phone
        )
          newPhoneCountry = newCountry;
      }
  });
  const phoneNumber = companyNumber
    ? companyNumber.replace(fidelCountries[newPhoneCountry]?.phone, '')
    : '';

  return {
    businessType,
    description: description || '',
    name: companyName || '',
    addressLine: addresses && addresses.length > 0 ? addresses[0] : '',
    addressLine2: addresses && addresses.length > 1 ? addresses[1] : '',
    city: city || '',
    postcode: postcode || '',
    country: newCountry,
    vatNumber: vatNumber || '',
    companyNumber: phoneNumber,
    phoneCountry: newPhoneCountry,
  };
};

export const toOffer = (raw: any, currentOffer?: Offer): any => {
  if (isEmpty(raw)) return {};

  const offer: any = {};
  const {
    brandId,
    country,
    daysOfWeek,
    discount,
    discountType,
    endDate,
    endTime,
    maxSpend,
    minSpend,
    offerName,
    publisher,
    returnPeriod,
    startDate,
    startTime,
    terms,
  } = raw;

  const start = startTime
    ? (offer.startDate = startDate
        .set({
          minute: moment(startTime, 'HH:mm').get('minute'),
          hour: moment(startTime, 'HH:mm').get('hour'),
          second: 0,
          millisecond: 0,
        })
        .format('YYYY-MM-DD[T]HH:mm:ss'))
    : (offer.startDate = startDate
        .set({
          minute: 0,
          hour: 0,
          second: 0,
          millisecond: 0,
        })
        .format('YYYY-MM-DD[T]HH:mm:ss'));

  const end = endDate
    ? endTime
      ? (offer.endDate = endDate
          .set({
            minute: moment(endTime, 'HH:mm').get('minute'),
            hour: moment(endTime, 'HH:mm').get('hour'),
            second: 59,
            millisecond: 999,
          })
          .format('YYYY-MM-DD[T]HH:mm:ss'))
      : (offer.endDate = endDate
          .set({
            minute: '59',
            hour: '23',
            second: 59,
            millisecond: 999,
          })
          .format('YYYY-MM-DD[T]HH:mm:ss'))
    : null;

  const type = {
    name: discountType,
    value: parseFloat(discount),
  };

  if (currentOffer) {
    if (!isEqual(currentOffer.daysOfWeek, daysOfWeek))
      offer.daysOfWeek = daysOfWeek;

    if (!isEqual(currentOffer.name, offerName)) offer.name = offerName;

    if (!isEqual(currentOffer.maxTransactionAmount, maxSpend))
      offer.maxTransactionAmount = parseFloat(maxSpend) || 0;

    if (!isEqual(currentOffer.minTransactionAmount, minSpend))
      offer.minTransactionAmount = parseFloat(minSpend) || 0;

    if (returnPeriod && !isEqual(currentOffer.returnPeriod, returnPeriod))
      offer.returnPeriod = parseFloat(returnPeriod);

    if (!isEqual(offer.startDate, start)) offer.startDate = start;

    if (!isEqual(offer.endDate, end)) offer.endDate = end;

    if (terms && !isEqual(currentOffer.additionalTerms, terms))
      offer.additionalTerms = terms;
  } else {
    offer.daysOfWeek = daysOfWeek;
    offer.brandId = brandId;
    offer.type = type;
    offer.name = offerName;
    offer.countryCode = country;

    if (maxSpend) offer.maxTransactionAmount = parseFloat(maxSpend);

    if (minSpend) offer.minTransactionAmount = parseFloat(minSpend);

    if (returnPeriod) offer.returnPeriod = parseFloat(returnPeriod);

    offer.startDate = start;
    offer.endDate = end;

    if (terms) offer.additionalTerms = terms;

    offer.publisherId = publisher;
  }

  return offer;
};
