import React from 'react';
import useForm from 'react-hook-form';
import { AuthActions, State } from '@fidel.uk/store';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import FormFields from 'src/app/components/form-fields';
import LoadingSpinner from 'src/app/components/loading-spinner';
import Button from 'src/app/components/button';
import { Form } from 'src/app/styled';
import { Field, FieldType } from 'src/utils/interfaces';

const FIDEL_LOGO = require('src/assets/svg/FIDEL_logo_blue.svg');

const SignIn: React.FC = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state: State) => state.authReducer);
  const { t } = useTranslation();
  const { errors, handleSubmit, register, watch } = useForm({ mode: 'onBlur' });
  const fields: Field[] = [
    {
      key: 'email',
      id: 'email',
      label: t('sign.label.email'),
      inline: true,
      noBorder: true,
      variant: 'sign',
      group: [
        {
          type: FieldType.input,
          props: {
            className: 'margin',
            key: 'email_input',
            error: !!errors.email,
            id: 'email',
            name: 'email',
            type: 'email',
            ref: register({ required: true }),
          },
        },
      ],
    },
    {
      key: 'password',
      id: 'password',
      label: t('sign.label.password'),
      inline: true,
      noBorder: true,
      variant: 'sign',
      group: [
        {
          type: FieldType.input,
          props: {
            className: 'margin',
            key: 'password_input',
            error: !!errors.password,
            id: 'password',
            name: 'password',
            type: 'password',
            ref: register({ required: true }),
          },
        },
      ],
    },
  ];

  function onSubmit({ email, password }: any) {
    dispatch(AuthActions.signIn(email, password));
  }

  return (
    <div className="sign-container">
      <NavLink to="/">
        <img src={FIDEL_LOGO} alt="" />
      </NavLink>

      <div className="sign-wrapper">
        <h1>{t('sign.in.welcome')}</h1>
        <p>{t('sign.in.title')}</p>
        <Form
          className="fidel-form full-width xl"
          inline
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormFields fields={fields} />
          <Button type="submit" data-testid="sign-in-btn" size="medium">
            {loading ? (
              <LoadingSpinner color="white" />
            ) : (
              t('sign.label.signIn')
            )}
          </Button>
        </Form>
        <p>
          <NavLink
            to={`/sign-in/forgot-password/${watch('email')}`}
            className="link-primary"
          >
            {t('sign.in.forgotPassword.title')}
          </NavLink>
        </p>
      </div>
    </div>
  );
};

export default SignIn;
