import React from 'react';

const Step = ({ step, id }) => {
  const activeClass = step.succeed
    ? 'step succeed'
    : (step.active && 'step active') || 'step';

  const handleClick = (e: any) => {
    step.handleClick(e, step.stepName);
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      key={`step_${step.stepName}`}
      data-testid={`step_${step.stepName}`}
      className={activeClass}
      onClick={handleClick}
    >
      <span className="step-number">
        {step.succeed ? <i className="icon-check" /> : id + 1}
      </span>
      <span>{step.label}</span>
      <div className="space" />
    </div>
  );
};

export default Step;
