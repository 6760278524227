import { useEffect } from 'react';

export default function useOnClickOutside(
  reference: any,
  handleClickOutside: (...args: any) => any,
) {
  useEffect(() => {
    const handler = (event: { target: any }) => {
      if (!reference.current || reference.current.contains(event.target))
        return;
      handleClickOutside(event);
    };

    document.addEventListener('mousedown', handler);
    document.addEventListener('touchstart', handler);

    return () => {
      document.removeEventListener('mousedown', handler);
      document.removeEventListener('touchstart', handler);
    };
  }, [reference, handleClickOutside]);
}
