import React from 'react';
import { t } from 'i18next';

const PresetRangePicker = (props: {
  selectPresetRange: (range?: { len: number; period: string }) => void;
}) => {
  const ranges = [
    { len: 1, period: 'week', label: t('components.ranges.week') },
    { len: 1, period: 'month', label: t('components.ranges.month') },
    {
      len: 3,
      period: 'months',
      label: t('components.ranges.month', { count: 3 }),
    },
    { len: 1, period: 'year', label: t('components.ranges.year') },
  ];
  return (
    <div className="preset-range-buttons">
      {ranges.map(range => (
        <button
          type="button"
          className="btn-white"
          key={`${range.len.toString()}${range.period}`}
          onClick={() => props.selectPresetRange(range)}
        >
          {`${range.len} ${range.label[0]}`}
        </button>
      ))}
      <button
        type="button"
        className="btn-white"
        onClick={() => {
          props.selectPresetRange();
        }}
      >
        {t('components.ranges.all')}
      </button>
    </div>
  );
};

export default PresetRangePicker;
