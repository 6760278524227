import React from 'react';
import useForm from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { State } from '@fidel.uk/store';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import * as AuthActions from '@fidel.uk/store/lib/auth/auth-actions';

import LoadingSpinner from 'src/app/components/loading-spinner';
import FormFields from 'src/app/components/form-fields';
import Button from 'src/app/components/button';
import { Form } from 'src/app/styled';
import { Field, FieldType } from 'src/utils/interfaces';

const FIDEL_LOGO = require('src/assets/svg/FIDEL_logo_blue.svg');

const ForgotPassword: React.FC = () => {
  const dispatch = useDispatch();
  const { loading, passwordResetEmailSent } = useSelector(
    (state: State) => state.authReducer,
  );
  const { t } = useTranslation();
  const { email: defaultValue } = useParams();
  const { errors, formState, handleSubmit, register } = useForm({
    mode: 'onBlur',
  });
  const fields: Field[] = [
    {
      key: 'email',
      id: 'email',
      label: t('sign.label.email'),
      inline: true,
      noBorder: true,
      variant: 'sign',
      group: [
        {
          type: FieldType.input,
          props: {
            className: 'margin',
            key: 'email_input',
            defaultValue,
            error: !!errors.email,
            id: 'email',
            name: 'email',
            type: 'email',
            ref: register({ required: true }),
          },
        },
      ],
    },
  ];

  function onSubmit({ email }: any) {
    dispatch(AuthActions.sendPasswordResetEmail(email));
  }

  if (formState.isSubmitted && passwordResetEmailSent)
    return <Redirect to="/" />;

  return (
    <div className="sign-container">
      <div className="fidel-logo">
        <NavLink to="/">
          <img src={FIDEL_LOGO} alt="" />
        </NavLink>
      </div>

      <div className="sign-wrapper">
        <h1>{t('sign.in.welcome')}</h1>
        <p>{t('sign.in.forgotPassword.title')}</p>
        <Form
          inline
          className="full-width xl fidel-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <p>{t('sign.in.forgotPassword.message')}</p>
          <FormFields fields={fields} />
          <Button type="submit" data-testid="forgot-password-btn" size="medium">
            {loading ? (
              <LoadingSpinner color="white" />
            ) : (
              t('sign.label.submit')
            )}
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default ForgotPassword;
