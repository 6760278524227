import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const dropdownIcon = require('src/assets/svg/dropdown_icon_white.svg');

interface BrandUserDetailProps {
  moderatingUserDetail: any;
  openProfileMenu: () => void;
  profileMenuOpened: boolean;
  userDetails: any;
}

const BrandUserDetail: React.FC<BrandUserDetailProps> = ({
  moderatingUserDetail,
  openProfileMenu,
  profileMenuOpened,
  userDetails,
}) => {
  const { t } = useTranslation();
  const details = moderatingUserDetail || userDetails;

  return (
    <BrandUserDetailContainer
      type="button"
      data-testid="toggle-menu"
      data-toggle="profile-menu"
      onClick={() => openProfileMenu()}
    >
      <div
        className="user-initial medium-only"
        style={{
          backgroundImage: `url(${(details && details.brandLogoUrl) || ''})`,
          backgroundSize: 'cover',
          color: details?.brandLogoUrl ? 'transparent' : 'inherit',
        }}
      >
        {details?.initials}
      </div>
      <div className="profile-name-container">
        <span
          className="brand-name large-only"
          title={details?.brandName || ''}
        >
          {details?.brandName}
        </span>
        <div className="user-info">
          <span className="account-name large-only">{details?.name}</span>
          {moderatingUserDetail && (
            <span className="moderator-icon medium-only">M</span>
          )}
          <img
            data-toggle="profile-menu"
            src={dropdownIcon}
            className={`dropdown-icon${profileMenuOpened ? ' rotated' : ''}`}
            alt={t('components.sidebar.dropdown')}
          />
        </div>
      </div>
    </BrandUserDetailContainer>
  );
};

const BrandUserDetailContainer = styled.button`
  align-items: center;
  background-color: transparent;
  color: ${p => p.theme.colors.white};
  display: flex;
  margin-bottom: 40px;
  padding: 0;

  * {
    pointer-events: none;
  }

  img {
    pointer-events: initial;
  }

  @media (max-width: ${p => p.theme.breakpoints.tablet}) {
    margin-bottom: 0;

    .medium-only {
      display: none !important;
    }
  }

  .user-initial {
    background-color: ${p => p.theme.colors.purple};
    border-radius: 32px;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 15px;
    justify-content: center;
    line-height: 22px;
    margin-right: 13px;
    height: 32px;
    width: 32px;
  }

  .profile-name-container {
    text-align: left;

    .brand-name {
      color: ${p => p.theme.colors.gray};
      font-weight: 600;
      font-size: 10px;
      line-height: 15px;
      letter-spacing: 0.34px;
      text-transform: uppercase;
    }

    .user-info {
      align-items: center;
      display: flex;

      .account-name {
        max-width: 100px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .moderator-icon {
        background-color: ${p => p.theme.colors.gray};
        border-radius: 3px;
        display: inline-block;
        font-weight: 600;
        font-size: 9px;
        line-height: 13px;
        text-align: center;
        letter-spacing: 0.34px;
        height: 15px;
        width: 15px;
      }

      .dropdown-icon {
        margin-left: 11px;
      }
    }
  }
`;

export default BrandUserDetail;
