import React from 'react';
import { t } from 'i18next';
import { isEqual } from 'lodash';

import UICheckbox from '../checkbox/checkbox';

interface UIDaysPickerProps {
  value: number[];
  handleChange: any;
}

interface UIDaysPickerState {
  selectedDaysOfWeek: number[];
}

export default class UIDaysPicker extends React.Component<
  UIDaysPickerProps,
  UIDaysPickerState
> {
  constructor(props: UIDaysPickerProps) {
    super(props);

    this.state = {
      selectedDaysOfWeek: props.value,
    };

    this.handleDayChange = this.handleDayChange.bind(this);
  }

  componentDidUpdate(nextProps: UIDaysPickerProps) {
    const { value } = this.props;
    if (!isEqual(nextProps.value, value)) {
      this.setState({
        selectedDaysOfWeek: value,
      });
      this.forceUpdate();
    }
  }

  private handleDayChange(day: number) {
    const { selectedDaysOfWeek } = this.state;
    const { handleChange } = this.props;

    if (selectedDaysOfWeek.includes(day)) {
      if (selectedDaysOfWeek.length > 1) {
        const daysOfWeek = selectedDaysOfWeek.filter(d => d !== day);
        this.setState({ selectedDaysOfWeek: daysOfWeek });
        handleChange(daysOfWeek);
      }
    } else {
      const daysOfWeek = [...selectedDaysOfWeek, day];
      this.setState({ selectedDaysOfWeek: daysOfWeek });
      handleChange(daysOfWeek);
    }
  }

  render() {
    const selectedDays = {};
    const { selectedDaysOfWeek } = this.state;
    selectedDaysOfWeek.forEach(d => {
      selectedDays[d] = true;
    });

    const daysOfWeek = [
      {
        name: `day-${t('days.monday')}`,
        label: t('days.monday').substring(0, 1),
        value: 1,
      },
      {
        name: `day-${t('days.tuesday')}`,
        label: t('days.tuesday').substring(0, 1),
        value: 2,
      },
      {
        name: `day-${t('days.wednesday')}`,
        label: t('days.wednesday').substring(0, 1),
        value: 3,
      },
      {
        name: `day-${t('days.thursday')}`,
        label: t('days.thursday').substring(0, 1),
        value: 4,
      },
      {
        name: `day-${t('days.friday')}`,
        label: t('days.friday').substring(0, 1),
        value: 5,
      },
      {
        name: `day-${t('days.saturday')}`,
        label: t('days.saturday').substring(0, 1),
        value: 6,
      },
      {
        name: `day-${t('days.sunday')}`,
        label: t('days.sunday').substring(0, 1),
        value: 0,
      },
    ];

    return (
      <div className="days-picker-container">
        <div className="days-picker">
          {daysOfWeek.map(day => (
            <div key={`ui-day-${day.value}`} className="day">
              <UICheckbox
                name={day.name}
                round
                checked={selectedDays[day.value]}
                label={day.label}
                tabIndex={0}
                handleChange={this.handleDayChange.bind(this, day.value)}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }
}
