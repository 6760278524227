import React, { useEffect, useState } from 'react';
import useForm from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AuthActions, State } from '@fidel.uk/store';
import { useParams } from 'react-router';
import { NavLink } from 'react-router-dom';

import Button from 'src/app/components/button';
import FormFields from 'src/app/components/form-fields';
import InvalidInvitation from 'src/app/components/InvalidInvitation';
import LoadingScreen from 'src/app/components/loading-screen';
import LoadingSpinner from 'src/app/components/loading-spinner';
import UICheckbox from 'src/style/ui-components/checkbox/checkbox';
import UIField from 'src/style/ui-components/field/field';
import config from 'src/utils/config';
import { Form } from 'src/app/styled';
import { Field, FieldType } from 'src/utils/interfaces';
import * as transform from 'src/utils/transform';

const SignUpForm: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { tokenId } = useParams();
  const { loading, tokenState, error } = useSelector(
    (state: State) => state.authReducer,
  );
  const [termsAccepted, setTermsAccepted] = useState(false);
  const { errors, handleSubmit, register, watch } = useForm({
    mode: 'onBlur',
  });
  const invitation: any =
    (tokenState && transform.toSignUpData(tokenState.token)) || {};
  const { nameFirst, accountName, brandName, email } = invitation;
  const fields: Field[] = [
    {
      key: 'email',
      id: 'email',
      label: t('sign.label.email'),
      inline: true,
      noBorder: true,
      variant: 'sign',
      group: [
        {
          type: FieldType.input,
          props: {
            disabled: true,
            defaultValue: email,
            className: 'margin',
            key: 'email_input',
            error: !!errors.email,
            id: 'email',
            name: 'email',
            type: 'email',
          },
        },
      ],
    },
    {
      key: 'password',
      id: 'password',
      label: t('sign.label.password'),
      inline: true,
      noBorder: true,
      variant: 'sign',
      group: [
        {
          type: FieldType.input,
          props: {
            className: 'margin',
            key: 'password_input',
            error: !!errors.password,
            id: 'password',
            name: 'password',
            type: 'password',
            ref: register({
              required: true,
              minLength: config.PASSWORD_MIN_LENGTH,
              maxLength: config.PASSWORD_MAX_LENGTH,
            }),
          },
        },
        {
          type: FieldType.error,
          error: {
            key: 'error_password',
            hasError: !!errors.password,
            errorMessage: t('sign.label.passwordError', {
              min: 6,
              max: 30,
            }),
          },
        },
      ],
    },
    {
      key: 'password2',
      id: 'password2',
      label: t('sign.label.confirmPassword'),
      inline: true,
      noBorder: true,
      variant: 'sign',
      group: [
        {
          type: FieldType.input,
          props: {
            className: 'margin',
            key: 'password2_input',
            error: !!errors.password2,
            id: 'password2',
            name: 'password2',
            type: 'password',
            ref: register({
              required: true,
              validate: value => value === watch('password'),
            }),
          },
        },
        {
          type: FieldType.error,
          error: {
            key: 'error_password2',
            hasError: !!errors.password2,
            errorMessage: t('sign.label.passwordMatchError'),
          },
        },
      ],
    },
  ];

  useEffect(() => {
    if (tokenId) dispatch(AuthActions.getToken(tokenId));
  }, [dispatch, tokenId]);

  function onSubmit({ password }: any) {
    dispatch(
      AuthActions.completeSignUp(tokenState.token, tokenId, email, password),
    );
  }

  if (!tokenState && !error) return <LoadingScreen />;

  if (!invitation) return <InvalidInvitation />;

  if (tokenState?.token === null)
    return (
      <p>
        <Trans
          i18nKey="sign.up.tokenAlreadyUsed"
          components={[
            <NavLink to="/sign-in">{t('sign.label.signIn')}</NavLink>,
            <NavLink to="/sign-in/forgot-password">
              {t('sign.label.resetPassword')}
            </NavLink>,
          ]}
        />
      </p>
    );

  return (
    <div>
      <h1>{t('sign.up.hi', { nameFirst })}</h1>
      <p>
        {t('sign.up.message', {
          accountName,
          brandName,
        })}
      </p>

      <Form
        className="full-width xl fidel-form"
        onSubmit={handleSubmit(onSubmit)}
        inline
      >
        <FormFields fields={fields} />
        <UIField>
          <UICheckbox
            label={
              <span>
                <Trans
                  i18nKey="sign.up.terms"
                  components={[
                    <a
                      href="https://fidel.uk/legal/merchant-terms"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms of Use
                    </a>,
                  ]}
                />
              </span>
            }
            handleChange={e => setTermsAccepted(e.target.checked)}
            checked={termsAccepted}
            name="termsAccepted"
          />
        </UIField>
        <Button
          type="submit"
          size="medium"
          disabled={!termsAccepted}
          data-testid="sign-up-btn"
        >
          {loading ? (
            <LoadingSpinner color="white" />
          ) : (
            t('sign.label.authorise')
          )}
        </Button>
      </Form>

      <p>
        <small>
          <Trans
            i18nKey="sign.up.alreadyApproved"
            components={[
              <NavLink to="/sign-in">{t('sign.label.signIn')}</NavLink>,
            ]}
          />
        </small>
      </p>
    </div>
  );
};

export default SignUpForm;
