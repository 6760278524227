import React from 'react';

import UIInfo from '../info/info';

interface UIFieldProps {
  children: any;
  inline?: boolean;
  info?: string | undefined;
  label?: any | undefined;
  name?: string | undefined;
}

const UIField = ({ inline, label, children, name, info }: UIFieldProps) => {
  return (
    <div className={inline ? 'ui-field inline' : 'ui-field'}>
      {label ? (
        <label htmlFor={name || ''}>
          {label} {info && <UIInfo info={info} />}
        </label>
      ) : null}
      <div className="ui-field-element">{children}</div>
    </div>
  );
};

export default UIField;
