import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { sortBy } from 'lodash';
import { Redirect } from 'react-router';
import { State } from '@fidel.uk/store';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as OfferActions from '@fidel.uk/store/lib/offers';

import LoadingScreen from 'src/app/components/loading-screen';
import CreateOfferCard from 'src/app/containers/offers/components/create-offer-card';
import { ConfirmBox } from 'src/app/components/ConfirmBox';

import OfferCard from './components/offer-card';

const Offers: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loading, offers, publisher } = useSelector((state: State) => ({
    loading: state.offers.loading,
    offers: state.offers.offers,
    publisher: state.brandUser.details?.publisher,
  }));
  const [activeFilter, setActiveFilter] = useState<string>('status');
  const [offerToDelete, setOfferToDelete] = useState<string>(null);
  const [offerToEdit, setOfferToEdit] = useState<string>(null);
  const [createFormVisible, setCreateFormVisible] = useState(false);
  const [offersVisible, setOffersVisible] = useState(new Map());
  const updateOffersVisible = (key, value) => {
    setOffersVisible(new Map(offersVisible.set(key, value)));
  };

  function handleDeleteOffer() {
    dispatch(OfferActions.deleteOffer(offerToDelete));
    setOfferToDelete(null);
  }

  function displayOfferCard({ offer, viewActive = true }) {
    return (
      <OfferCard
        key={offer.id}
        offer={offer}
        publisher={publisher}
        viewActive={viewActive}
        handleDelete={() => setOfferToDelete(offer.id)}
        handleResume={() => setOfferToEdit(offer.id)}
      />
    );
  }

  useEffect(() => {
    updateOffersVisible('pending', true);

    return () => {
      dispatch(OfferActions.clear());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (publisher) {
      dispatch(OfferActions.getOffers());
      dispatch(OfferActions.getAllOffers());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publisher]);

  if (createFormVisible) return <Redirect push to="/offers/create/" />;

  if (offerToEdit)
    return <Redirect push to={`/offers/create/${offerToEdit}`} />;

  if (loading) return <LoadingScreen />;

  return (
    <div className="offers-container">
      {offerToDelete && (
        <ConfirmBox
          handleCancel={() => setOfferToDelete(null)}
          handleConfirm={handleDeleteOffer}
          message={t('offers.delete.draft.message')}
          title={t('offers.delete.draft.title')}
          confirmLabel={t('offers.delete.draft.confirm')}
          cancelLabel={t('offers.delete.draft.cancel')}
        />
      )}
      {offers && offers.all && offers.all.length > 0 ? (
        <div className="offers-list">
          <section data-test="offers-active">
            <div className="offers-list-header">
              <h3>{t('offers.title')}</h3>
              <div className="ui-dropdown">
                <select
                  className="selector"
                  data-testid="offer-filter-dropdown"
                  value={activeFilter}
                  onChange={(e: any) => setActiveFilter(e.target.value)}
                >
                  <option value="status">
                    {t('offers.active.filter.status')}
                  </option>
                  <option value="publisher">
                    {t('offers.active.filter.publisher')}
                  </option>
                  <option value="startDate">
                    {t('offers.active.filter.startDate')}
                  </option>
                </select>
              </div>
            </div>
            <div className="offers-active">
              <CreateOfferCard />
              {sortBy(offers.live.items, [activeFilter]).map(liveOffer =>
                displayOfferCard({ offer: liveOffer }),
              )}
            </div>
          </section>
          {['pending', 'allSet', 'expired'].map(
            type =>
              offers[type].items &&
              offers[type].items.length > 0 && (
                <section key={type}>
                  <div className="offers-list-header">
                    <h3>{t(`offers.${type}`)}</h3>
                    <button
                      type="button"
                      className="toggle-offer"
                      data-testid={`toggle-offer-${type}`}
                      onClick={() =>
                        updateOffersVisible(type, !offersVisible.get(type))
                      }
                    >
                      {offersVisible.get(type) ? t('hide') : t('show')}
                    </button>
                  </div>
                  {offersVisible.get(type) && (
                    <div
                      className="offers-active"
                      data-testid={`offers-${type}-list`}
                    >
                      {sortBy(offers[type].items, [activeFilter]).map(
                        offerByType =>
                          displayOfferCard({
                            offer: offerByType,
                            viewActive: false,
                          }),
                      )}
                    </div>
                  )}
                </section>
              ),
          )}
        </div>
      ) : (
        <NoOfferContainer className="full">
          <div className="no-offer">
            <img
              alt=""
              src="/assets/img/no_offers_yet_illu.png"
              srcSet="/assets/img/no_offers_yet_illu@2x.png 2x"
            />
            <div className="message">
              <strong>{t('offers.no-offer.label')}</strong>
            </div>
            <div className="message">{t('offers.no-offer.message')}</div>
            <button
              type="button"
              data-testid="create-empty-offer-btn"
              className="btn with-padding"
              onClick={() => setCreateFormVisible(true)}
            >
              {t('offers.createOffer.label')}
            </button>
          </div>
        </NoOfferContainer>
      )}
    </div>
  );
};

const NoOfferContainer = styled.section`
  min-height: 100%;
  justify-content: center;
`;

export default Offers;
