import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import FidelSingleDatePicker from 'src/app/components/FidelSingleDatePicker';
import { DatetimeInputContainer } from '../styled';

export interface DatetimeInputProps {
  date: any;
  handleChange: (date: any) => any;
  id: string;
  isOutsideRange: (day: any) => any;
  label: string;
  inputTimeProps: any;
  showClearDate: boolean;
  datePickerPlaceholder?: string;
  initialVisibleMonth?: () => any;
}

const DatetimeInput: React.FC<DatetimeInputProps> = ({
  date,
  datePickerPlaceholder,
  handleChange,
  id,
  isOutsideRange,
  initialVisibleMonth,
  inputTimeProps,
  label,
  showClearDate,
}) => {
  const { i18n } = useTranslation();
  return (
    <DatetimeInputContainer>
      <label htmlFor={id}>{label}</label>
      <FidelSingleDatePicker
        id={id}
        placeholder={datePickerPlaceholder}
        date={(date && moment(date).locale(i18n.language)) || null}
        handleChange={handleChange}
        isOutsideRange={isOutsideRange}
        initialVisibleMonth={initialVisibleMonth}
        showClearDate={showClearDate}
      />
      <input type="time" placeholder="00:00" {...inputTimeProps} />
    </DatetimeInputContainer>
  );
};

export default DatetimeInput;
