import React from 'react';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import AbstractFidelDatePicker from 'src/app/abstracts/AbstractFidelDatePicker';
import * as show from 'src/utils/show';

interface SingleDatePickerProps {
  id: string;
  date: any;
  handleChange: (date: any) => void;
  isOutsideRange?: (date: any) => boolean;
  placeholder?: string;
  initialVisibleMonth?: any;
  showClearDate?: boolean;
}

export class FidelSingleDatePicker extends AbstractFidelDatePicker<
  SingleDatePickerProps,
  any
> {
  constructor(props: SingleDatePickerProps) {
    super(props);

    this.state = {
      focused: false,
      date: props.date,
    };

    this.onFocusChange = this.onFocusChange.bind(this);
  }

  onFocusChange({ focused }: any) {
    this.setState({ focused });
  }

  render() {
    return (
      <div className="drp-wrapper">
        <SingleDatePicker
          id={this.props.id}
          date={this.props.date}
          focused={this.state.focused}
          onFocusChange={this.onFocusChange}
          anchorDirection="right"
          calendarInfoPosition="top"
          daySize={34}
          displayFormat="ll"
          firstDayOfWeek={1}
          hideKeyboardShortcutsPanel
          horizontalMargin={8}
          isOutsideRange={this.props.isOutsideRange}
          navPrev={<img alt="" src={show.iconFor('arrowLeft')} />}
          navNext={<img alt="" src={show.iconFor('arrowRight')} />}
          numberOfMonths={1}
          renderMonthText={this.renderMonth}
          onDateChange={this.props.handleChange}
          weekDayFormat="dd"
          placeholder={this.props.placeholder}
          initialVisibleMonth={this.props.initialVisibleMonth}
          showClearDate={this.props.showClearDate}
        />
      </div>
    );
  }
}

export default FidelSingleDatePicker;
