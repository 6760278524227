import React from 'react';
import moment from 'moment';
import { t } from 'i18next';
import { Offer } from '@fidel.uk/store/lib/offers/offer-model';
import {
  Transaction,
  TransactionStatus,
} from '@fidel.uk/store/lib/transactions/transaction-model';
import { FormattedNumber } from 'react-intl';

import * as show from 'src/utils/show';
import * as transform from 'src/utils/transform';

export interface TransactionDetailProps {
  handleClose: (e: any) => void;
  transaction: Transaction;
  publisherName: string;
  offer?: Offer;
}

export function TransactionDetail({
  offer,
  publisherName,
  transaction,
  handleClose,
}: TransactionDetailProps) {
  const {
    address,
    amount,
    city,
    countryCode,
    currency,
    date,
    scheme,
    status,
    offer: offerTransaction,
    lastNumbers,
    postcode,
  }: any = transaction;

  const messageContainer = () => {
    let className = 'message-container';
    let message;

    switch (status) {
      case TransactionStatus.QUALIFIED:
        className += ' qualified';
        message = (
          <p>
            <img
              src={require('src/assets/svg/qualified_check.svg')}
              alt={t('transactions.detail.status.qualified')}
            />
            {t('transactions.detail.status.qualified')}
          </p>
        );
        break;
      case TransactionStatus.REFUND:
        className += ' refund';
        message = <p>{t('transactions.detail.status.refund')}</p>;
        break;
      case TransactionStatus.PENDING:
        className += ' pending';
        message = offerTransaction?.qualificationDate ? (
          <p>
            {t('transactions.detail.status.pending', {
              date: moment(offerTransaction.qualificationDate).format('l'),
            })}
          </p>
        ) : (
          <p>{t('transactions.detail.status.pendingWithoutDate')}</p>
        );
        break;
      case TransactionStatus.REJECTED:
        className += ' rejected';
        message =
          offerTransaction?.message?.length > 0 ? (
            <div>
              <p>{t('transactions.detail.status.rejected')}</p>
              <ul>
                {offerTransaction.message.map((m, idx) => (
                  <li key={`message-${idx}`}>{m}</li>
                ))}
              </ul>
            </div>
          ) : (
            <span>{t('transactions.detail.status.rejected')}</span>
          );
        break;
      default:
        return null;
    }

    return <div className={className}>{message}</div>;
  };

  const amountContainer = (
    <span className={amount < 0 ? 'amount negative' : 'amount'}>
      {/* eslint-disable */}
      {amount ? (
        <FormattedNumber value={amount} style="currency" currency={currency} />
      ) : (
        t('na')
      )}
    </span>
  );

  const handleEscape = (e: any) => {
    if (e.keyCode === 27) {
      document.removeEventListener('keydown', handleEscape);
      handleClose(e);
    }
  };

  document.addEventListener('keydown', handleEscape);

  return (
    <div id="transactionDetail" className="transaction-detail-container">
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div className="transaction-detail-overlay" onClick={handleClose} />
      <section className="transactions-detail">
        <div className="transaction-detail-header">
          <h2>
            {amountContainer}
            <span className="currency">&nbsp;{currency}</span>
          </h2>
          <img
            className={`transaction-type-icon ${scheme}`}
            alt={scheme}
            src={show.iconFor(scheme)}
          />
        </div>
        {messageContainer()}
        <div className="transaction">
          <h3>{t('transactions.detail.title.transaction')}</h3>
          <dl>
            <div className="list-inline">
              <dt>{t('transactions.detail.label.card')}</dt>
              <dd>• • • •  • • • •  • • • •  {lastNumbers}</dd>
            </div>
            <div className="list-inline">
              <dt>{t('transactions.detail.label.amount')}</dt>
              <dd>{amountContainer}</dd>
            </div>
            <div className="list-inline">
              <dt>{t('transactions.detail.label.date')}</dt>
              <dd>{moment(date).format('DD/MM/YY HH:mm')}</dd>
            </div>
            <div className="list-inline">
              <dt>{t('transactions.detail.label.scheme')}</dt>
              <dd className={`scheme ${scheme}`}>{scheme}</dd>
            </div>
            {offerTransaction?.id && (
              <>
                <div className="list-inline">
                  <dt>{t('transactions.detail.label.cashback')}</dt>
                  <dd>
                    <FormattedNumber
                      value={offerTransaction.cashback}
                      style="currency"
                      currency={currency}
                    />
                  </dd>
                </div>
                <div className="list-inline">
                  <dt>{t('transactions.detail.label.performanceFee')}</dt>
                  <dd>
                    <FormattedNumber
                      value={offerTransaction.performanceFee}
                      style="currency"
                      currency={currency}
                    />
                  </dd>
                </div>
              </>
            )}
          </dl>
        </div>
        {offer && offerTransaction?.id && (
          <div className="offer">
            <h3>{t('transactions.detail.title.offer')}</h3>
            <dl>
              <div className="list-inline">
                <dt>{t('transactions.detail.label.publisher')}</dt>
                <dd>{publisherName}</dd>
              </div>
              <div className="list-inline">
                <dt>{t('transactions.detail.label.offerName')}</dt>
                <dd>{offer.name}</dd>
              </div>
              <div className="list-inline">
                <dt>{t('transactions.detail.label.returnPeriod')}</dt>
                <dd>
                  {offer.returnPeriod ? (
                    <span>
                      {offer.returnPeriod}{' '}
                      {t('offers.offerStep.form.returnPeriod.value.days')}
                    </span>
                  ) : (
                    <span>{t('na')}</span>
                  )}
                </dd>
              </div>
              <div className="list-inline">
                <dt>{t('transactions.detail.label.discount')}</dt>
                <dd>
                  {offer.type.name === 'amount' ? (
                    <FormattedNumber
                      value={offer.type.value}
                      style="currency"
                      currency={currency}
                    />
                  ) : (
                    <span>{offer.type.value} %</span>
                  )}
                </dd>
              </div>
            </dl>
          </div>
        )}
        <div className="location">
          <h3>{t('transactions.detail.title.location')}</h3>
          <p>
            <span>
              {address}, {postcode}, {city}, {transform.toTwoDigit(countryCode)}
            </span>
          </p>
        </div>
      </section>
    </div>
  );
}

export default TransactionDetail;
