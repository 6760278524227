import React from 'react';
import { lighten } from 'polished';
import styled from 'styled-components';

const LoadingScreen = () => <ProgressBar data-testid="loading" />;

const ProgressBar = styled.div<any>`
  overflow: visible;

  &::after {
    content: ' ';
    height: 2px;
    background: ${p => lighten(0.05, p.theme.colors.blue)};
    position: fixed;
    animation: loader 2s linear infinite;
    top: 0;
    left: 0;
    z-index: 2000;
  }
`;

export default LoadingScreen;
