import React from 'react';
import { useTranslation } from 'react-i18next';

import { languages } from 'src/utils/countries';

interface LanguageSelectorProps {
  menu?: boolean;
  handleClickOutside?: (e: any) => void;
}

const LanguageSelector: React.FC<LanguageSelectorProps> = ({
  menu,
  handleClickOutside,
}) => {
  const { t, i18n } = useTranslation();

  function handleLanguage(e: any) {
    e.preventDefault();
    localStorage.setItem('fidel-lang', e.target.value);
    i18n.changeLanguage(e.target.value, () => {
      if (menu && handleClickOutside) handleClickOutside(null);
      i18n.reloadResources();
    });
  }

  return (
    <div
      className={`ui-dropdown-icon select-language ${
        menu ? 'small-only' : 'medium-only'
      }`}
    >
      <select
        data-testid="language-selector"
        className="selector"
        onChange={handleLanguage}
        value={i18n.language}
        style={{
          backgroundImage: `url(${languages[i18n.language].flag})`,
        }}
      >
        <option disabled>{t('lang.placeholder')}</option>
        {Object.keys(languages).map(key => (
          <option key={`lang-${key}`} value={key}>
            {t(`lang.${key}`)}
          </option>
        ))}
      </select>
    </div>
  );
};

export default LanguageSelector;
