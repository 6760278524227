import React from 'react';
import { NavLink } from 'react-router-dom';
import { t } from 'i18next';

const InvalidInvitation = () => (
  <div>
    <p>{t('components.invalidInvitation.message1')}</p>
    <p>{t('components.invalidInvitation.message2')}</p>
    <p>{t('components.invalidInvitation.message3')}</p>
    <p>{t('components.invalidInvitation.message4')}</p>
    <p>
      <NavLink to="/sign-in" className="link-primary">
        {t('components.invalidInvitation.signIn')}
      </NavLink>
    </p>
  </div>
);

export default InvalidInvitation;
