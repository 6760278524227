import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from 'src/i18n/lang/en.json';
import fr from 'src/i18n/lang/fr.json';

const savedLanguage = localStorage.getItem('fidel-lang');

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    interpolation: {
      escapeValue: false,
    },
    fallbackLng: 'en',
    lng: savedLanguage || 'en',
    resources: {
      en: {
        translation: en,
      },
      fr: {
        translation: fr,
      },
    },
  });

export default i18n;
