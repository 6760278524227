import React from 'react';
import { useTranslation } from 'react-i18next';

import UICheckbox from '../../../../style/ui-components/checkbox/checkbox';

interface BusinessReminderProps {
  businessInfo: any;
}

const BusinessReminder: React.FC<BusinessReminderProps> = ({
  businessInfo,
}) => {
  const { t } = useTranslation();
  const reminders = [
    {
      label: t('offers.businessStep.goodToKnow.label.businessTypeValid'),
      name: 'businessTypeValid',
      checked: !!businessInfo.businessType && !!businessInfo.description,
    },
    {
      label: t('offers.businessStep.goodToKnow.label.businessNameValid'),
      name: 'businessNameValid',
      checked: !!businessInfo.name,
    },
    {
      label: t('offers.businessStep.goodToKnow.label.addressValid'),
      name: 'addressValid',
      checked:
        !!businessInfo.addressLine &&
        !!businessInfo.city &&
        !!businessInfo.postcode,
    },
    {
      label: t('offers.businessStep.goodToKnow.label.vatNumberValid'),
      name: 'vatNumberValid',
      checked:
        businessInfo.country === 'USA' ||
        (!!businessInfo.vatNumber && businessInfo.vatNumber.length > 4),
    },
    {
      label: t('offers.businessStep.goodToKnow.label.phoneNumberValid'),
      name: 'phoneNumberValid',
      checked:
        !!businessInfo.companyNumber && businessInfo.companyNumber.length > 7,
    },
  ];

  return (
    <section className="reminder-container">
      <div className="reminder-icon">
        <img
          alt={t('offers.businessStep.goodToKnow.title')}
          src="/assets/img/goodtoknow_illu_alt.png"
          srcSet="/assets/img/goodtoknow_illu_alt@2x.png 2x"
        />
      </div>
      <div className="reminder-content">
        <div className="title">{t('offers.businessStep.goodToKnow.title')}</div>
        <p>{t('offers.businessStep.goodToKnow.description')}</p>
        {reminders.map(reminder => (
          <UICheckbox
            key={reminder.name}
            label={reminder.label}
            name={reminder.name}
            checked={reminder.checked}
            tabIndex={-1}
          />
        ))}
      </div>
    </section>
  );
};

export default BusinessReminder;
