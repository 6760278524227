import React from 'react';
import { t } from 'i18next';

import { fidelCountries } from '../../../../utils/countries';

interface PhoneCountrySelectorProps {
  className?: string;
  phoneCountry?: string;
}

const PhoneCountrySelector = React.forwardRef<any, PhoneCountrySelectorProps>(
  ({ className, phoneCountry }, ref) => {
    const options: any[] = [];
    const countryImages: any = {};
    Object.keys(fidelCountries).forEach(key => {
      countryImages[key] = fidelCountries[key].flag;
      options.push(
        <option key={`${fidelCountries[key].phone}_${key}`} value={key}>
          {fidelCountries[key].phone} ({fidelCountries[key].code})
        </option>,
      );
    });

    return (
      <div className={`ui-dropdown ${className}`}>
        <select
          className="phone-country-selector"
          data-testid="phone-country-selector"
          name="phoneCountry"
          ref={ref}
          style={{
            backgroundImage: `url(${countryImages[phoneCountry]})`,
          }}
        >
          <option disabled>
            {t('offers.businessStep.form.phone.placeholder')}
          </option>
          {options}
        </select>
      </div>
    );
  },
);

export default PhoneCountrySelector;
