import React, { useEffect, useRef, useState } from 'react';
import useForm from 'react-hook-form';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import * as OfferActions from '@fidel.uk/store/lib/offers';
import { State } from '@fidel.uk/store/lib';
import { Offer } from '@fidel.uk/store/lib/offers/offer-model';

import LoadingSpinner from 'src/app/components/loading-spinner';
import useOfferFormFields from 'src/app/containers/offers/hooks/use-offer-form-fields';
import { Form, FormFooter } from 'src/app/styled';
import { toOffer } from 'src/utils/transform';

export interface OfferFormProps {
  handleCompleteOfferForm: () => void;
  handlePreviousStep: (e: any) => void;
  currentOffer?: Offer | any;
}

const OfferForm: React.FC<OfferFormProps> = ({
  currentOffer = {},
  handleCompleteOfferForm,
  handlePreviousStep,
}) => {
  const formRef: any = useRef();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { createdOrUpdated, error } = useSelector((state: State) => ({
    createdOrUpdated: state.offer.createdOrUpdated,
    error: state.offer.error,
  }));
  const brandUser = useSelector((state: State) => state.brandUser.details);
  const {
    errors,
    formState,
    handleSubmit,
    register,
    watch,
    triggerValidation,
    getValues,
    setValue,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      country: 'GBR',
      discountType: 'amount',
      publisher: brandUser?.publisher?.id,
    },
  });
  const [loading, setLoading] = useState<boolean>();
  const [saveInProgress, setSaveInProgress] = useState<boolean>();

  const offerFormFields = useOfferFormFields({
    errors,
    register,
    setValue,
    watch,
    offer: currentOffer,
    publisher: brandUser?.publisher,
  });

  useEffect(() => {
    if (createdOrUpdated && loading && !saveInProgress) {
      setLoading(false);
      handleCompleteOfferForm();
    } else if (createdOrUpdated && saveInProgress) {
      setSaveInProgress(false);
    } else if (error) {
      setLoading(false);
      setSaveInProgress(false);
    }
  }, [
    createdOrUpdated,
    error,
    handleCompleteOfferForm,
    saveInProgress,
    loading,
  ]);

  function toRaw(values: any) {
    const {
      returnPeriod,
      returnPeriodValue,
      daysOfWeek,
      endDate,
      startDate,
    } = offerFormFields;
    return {
      ...values,
      returnPeriod: returnPeriod && returnPeriodValue,
      daysOfWeek,
      endDate,
      startDate,
      brandId: brandUser?.brandId,
    };
  }

  function onSubmit(values: any) {
    if (!loading) {
      setLoading(true);
      const offer = toOffer(
        toRaw(values),
        currentOffer && Object.keys(currentOffer).length > 0
          ? currentOffer
          : undefined,
      );
      if (isEmpty(offer)) handleCompleteOfferForm();

      if (currentOffer?.id)
        dispatch(OfferActions.updateOffer(currentOffer.id, offer));
      else dispatch(OfferActions.createOffer(offer));
    }
  }

  async function handleSaveDraft() {
    if (!saveInProgress) {
      const values = getValues();
      const offer = toOffer(
        toRaw(values),
        currentOffer && Object.keys(currentOffer).length > 0
          ? currentOffer
          : undefined,
      );
      const validation = await triggerValidation();
      if (validation && !isEmpty(offer)) {
        setSaveInProgress(true);
        if (currentOffer?.id)
          dispatch(OfferActions.updateOffer(currentOffer.id, offer));
        else dispatch(OfferActions.createOffer(offer));
      } else if (formState.touched.length === 0) {
        formRef.current.querySelector('input').focus();
      } else if (errors) {
        const firstKey = Object.keys(errors).pop();
        if (firstKey) errors[firstKey].ref.focus();
      }
    }
  }

  return (
    <div className="business-form offer-form">
      <section>
        <div className="title-carousel">
          <h2>{t('offers.offerStep.form.title')}</h2>
        </div>
        <Form
          onSubmit={handleSubmit(onSubmit)}
          className="fidel-form"
          data-testid="offer-form"
          ref={formRef}
        >
          {offerFormFields.fields}
          <FormFooter>
            <div className="offer-footer">
              <button
                type="button"
                className="with-padding btn save-draft btn-white"
                data-testid="save-draft"
                onClick={handleSaveDraft}
              >
                {saveInProgress ? (
                  <LoadingSpinner color="#003650" />
                ) : currentOffer ? (
                  t('offers.offerStep.form.saveDraft')
                ) : (
                  t('offers.offerStep.form.create')
                )}
              </button>
              {!brandUser?.stripe && (
                <button
                  type="submit"
                  className="btn with-padding right"
                  data-testid="continue-payment"
                >
                  {loading ? (
                    <LoadingSpinner color="white" />
                  ) : (
                    <span className="cta-container">
                      <span>
                        {formState.touched &&
                          `${t('offers.offerStep.form.submit.save_prefix')} `}
                        {t('offers.offerStep.form.submit.next')}
                      </span>
                      <img
                        src={require('src/assets/svg/cta_arrow.svg')}
                        alt=""
                      />
                    </span>
                  )}
                </button>
              )}
            </div>
            <button
              type="button"
              tabIndex={0}
              className="with-padding btn btn-white"
              data-testid="previous-step"
              onClick={handlePreviousStep}
            >
              {t('offers.offerStep.form.back')}
            </button>
          </FormFooter>
        </Form>
      </section>
    </div>
  );
};

export default OfferForm;
