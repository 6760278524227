import React from 'react';
import { t } from 'i18next';

import { fidelCountries } from '../../../../utils/countries';

interface CountryDropdownProps {
  disabled?: boolean;
}

const CountryDropdown = React.forwardRef<any, CountryDropdownProps>(
  ({ disabled }, ref) => {
    return (
      <div className="ui-dropdown">
        <select
          data-testid="country-dropdown"
          name="country"
          disabled={disabled}
          ref={ref}
        >
          <option disabled value="">
            {t('countries.default')}
          </option>
          {Object.keys(fidelCountries).map(key => (
            <option key={`${fidelCountries[key].code}_country`} value={key}>
              {t(`countries.${fidelCountries[key].code}`)}
            </option>
          ))}
        </select>
      </div>
    );
  },
);

export default CountryDropdown;
