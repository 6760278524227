import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { signOut } from '@fidel.uk/store/lib/auth/auth-actions';
import useOnClickOutside from 'src/hooks/use-on-click-outside';

import { FidelRoute } from 'src/utils/interfaces';

import LanguageSelector from './language-selector';

export interface ProfileMenuProps {
  moderatingUserDetail: any;
  toggleMenu: () => void;
  routes?: FidelRoute[];
}

const mapStateToProps = state => ({
  authState: state.authReducer,
  brandUserState: state.brandUser,
});

const ProfileMenu: React.FC<ProfileMenuProps> = ({
  moderatingUserDetail,
  routes,
  toggleMenu,
}) => {
  const profileMenuRef = useRef();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { authState, brandUserState } = useSelector(mapStateToProps);
  const { merchantAccount } = brandUserState;
  const email = moderatingUserDetail?.email || merchantAccount?.email;
  const brandName =
    moderatingUserDetail?.brandName || merchantAccount?.brandName;

  function handleClickOutside(e: any) {
    if (
      !e ||
      (!e.target?.type?.includes('select') &&
        e.target?.dataset?.toggle !== 'profile-menu')
    ) {
      toggleMenu();
    }
  }

  useOnClickOutside(profileMenuRef, handleClickOutside);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      className="profile-menu"
      ref={profileMenuRef}
      onClick={handleClickOutside}
    >
      <NavLink key="account-user" to="account">
        <div className="user-data">
          <p className="user-data-name">{brandName}</p>
          <p className="user-data-email">{email}</p>
        </div>
      </NavLink>
      <div className="nav-link">
        <NavLink key="account" to="/account">
          {t('components.profile.account')}
        </NavLink>
        <div className="small-only">
          {routes.map(({ path, label }, idx) => (
            <NavLink key={idx} to={path} activeClassName="nav-link-active">
              <span>{label}</span>
            </NavLink>
          ))}
        </div>
        <button
          type="button"
          className="link-button"
          data-testid="sign-out-btn"
          onClick={() => dispatch(signOut(authState.tokenState.token))}
        >
          {t('components.profile.signOut')}
        </button>
        <LanguageSelector menu handleClickOutside={handleClickOutside} />
      </div>
    </div>
  );
};

export default ProfileMenu;
