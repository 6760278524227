import React from 'react';
import { useTranslation } from 'react-i18next';

const PaymentDescription = ({ currentOffer }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="payment-description">
        <strong className="title">
          {t('offers.paymentStep.form.paymentOptions.title')}
        </strong>
        <p>{t('offers.paymentStep.form.paymentOptions.description')}</p>
      </div>
      <div className="payment-offer-submission">
        <div className="payment-description">
          <strong className="title">
            {t('offers.paymentStep.form.performanceFee.title')}
          </strong>
          <p>{t('offers.paymentStep.form.performanceFee.description')}</p>
        </div>
        {currentOffer.performanceFee && (
          <div className="payment-offer-submission-price">
            <div className="performance-fee">
              <span>{currentOffer.performanceFee}%</span>
            </div>
            <div className="performance-fee-subtitle">
              {t('offers.paymentStep.form.performanceFee.revenue')}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PaymentDescription;
