import React from 'react';
import moment from 'moment';
import { t } from 'i18next';
import { NavLink } from 'react-router-dom';

import {
  BusinessInformation,
  Invoice,
} from '@fidel.uk/store/lib/brand-user/brand-user-model';

import BillingComponent from 'src/app/abstracts/BillingComponent';
import { toCurrency } from 'src/utils/transform';

export interface BillingViewProps {
  businessInformation: BusinessInformation;
  invoice: Invoice;
}

export default class BillingView extends BillingComponent<
  BillingViewProps,
  {}
> {
  render() {
    const { businessInformation, invoice } = this.props;
    return (
      <div className="billing">
        <div className="billing-container-view">
          <div className="billing-container-header">
            <NavLink to="/billing" className="btn btn-grey back">
              <img src={require('src/assets/svg/chevron-left.svg')} alt="" />
              {t('billing.view.label.back')}
            </NavLink>
            <button
              type="button"
              onClick={this.generateInvoice}
              className="btn btn-grey"
            >
              <img src="/assets/img/download_icon.svg" alt="" />
              {t('billing.view.label.download')}
            </button>
          </div>
          <section className="invoice-preview">
            <h1>{t('billing.invoice.title')}</h1>
            <div className="date">{moment.unix(invoice.date).format('ll')}</div>
            <dl className="invoice-header">
              <div>
                <dt>{t('billing.invoice.billedTo')}</dt>
                <dd>
                  {businessInformation.companyName}
                  <br />
                  {businessInformation.address}
                  <br />
                  {businessInformation.postcode}, {businessInformation.city}
                  <br />
                  {t(`countries.${businessInformation.countryCode}`)}
                  <br />
                  {businessInformation.companyNumber}
                </dd>
              </div>
              <div>
                <dt>{t('billing.invoice.titleNumber')}</dt>
                <dd>{invoice.identifier}</dd>
              </div>
            </dl>
            <p className="invoice-duration">
              {t('billing.invoice.durationMessage', {
                start: moment.unix(invoice.startDate).format('ll'),
                end: moment
                  .unix(invoice.date)
                  .subtract('1', 'd')
                  .format('ll'),
              })}
            </p>
            <div className="invoice-content">
              <dl>
                <dt>{t('billing.invoice.dueDate')}</dt>
                <dd className="date">{invoice.dueDate.format('ll')}</dd>
              </dl>
              <table>
                <thead>
                  <tr>
                    <th className="description">
                      {t('billing.invoice.description')}
                    </th>
                    <th className="qty">{t('billing.invoice.qty')}</th>
                    <th className="vat">{t('billing.invoice.vat')}</th>
                    <th className="amount">{t('billing.invoice.amount')}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="description">
                      {t('billing.invoice.discount')}
                    </td>
                    <td className="qty">1</td>
                    <td className="vat">
                      {(invoice.cashback.vat > 0 &&
                        `${invoice.cashback.vat * 100}%`) ||
                        '–'}
                    </td>
                    <td className="amount">
                      {toCurrency(invoice.cashback.amount, invoice.currency)}
                    </td>
                  </tr>
                  <tr>
                    <td className="description">
                      {t('billing.invoice.performanceFee')}
                    </td>
                    <td className="qty">1</td>
                    <td className="vat">
                      {(invoice.performanceFee.vat > 0 &&
                        `${invoice.performanceFee.vat * 100}%`) ||
                        '–'}
                    </td>
                    <td className="amount">
                      {toCurrency(
                        invoice.performanceFee?.amount,
                        invoice.currency,
                      )}
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td />
                    <td />
                    <td>{t('billing.invoice.subtotal')}</td>
                    <td className="amount">
                      {toCurrency(
                        invoice.performanceFee.amount + invoice.cashback.amount,
                        invoice.currency,
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td />
                    <td />
                    <td>{t('billing.invoice.vat')}</td>
                    <td className="amount">
                      {toCurrency(
                        invoice.performanceFee.amount *
                          invoice.performanceFee.vat +
                          invoice.cashback.amount * invoice.cashback.vat,
                        invoice.currency,
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td />
                    <td />
                    <td className="total">{t('billing.invoice.total')}</td>
                    <td className="amount total">
                      {toCurrency(invoice.amount, invoice.currency)}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
            {invoice.status === 'paid' ? (
              <img
                className="paid-stamp"
                src="/assets/img/paid_stamp.png"
                srcSet="/assets/img/paid_stamp.png, /assets/img/paid_stamp@2x.png 2x"
                alt={t(`billing.status.${invoice.status}`)}
              />
            ) : null}
            <div
              className={`invoice-footer ${
                invoice.status !== 'paid' ? 'margin' : ''
              }`}
            >
              <img
                src={require('src/assets/svg/Fidel_dark_blue_invoice_logo.svg')}
                alt="FIDEL"
              />
              <div className="invoice-fidel-container">
                <div className="fidel-info">
                  <p>
                    4th Floor National House
                    <br />
                    60–66 Wardour St
                    <br />
                    W1F 0TA, London
                    <br />
                    United Kingdom
                    <br />
                    GB 155393006
                  </p>
                  <p>
                    Bank: HSBC UK
                    <br />
                    Account Nr: 5181 1290
                    <br />
                    Sort code: 40-05-20
                    <br />
                    IBAN: GB15 HBUK 4005 2051 8112 90
                  </p>
                </div>
                <p>
                  <strong>{t('billing.invoice.questions')}</strong>
                  <br />
                  <span>
                    {t('billing.invoice.mail', {
                      email: 'accounts@fidel.uk',
                      phone: '+44 20 3815 5770',
                    })}
                  </span>
                </p>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}
