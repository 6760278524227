import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';

import Account from 'src/app/containers/account/account';
import Billing from 'src/app/containers/billing';
import Offers from 'src/app/containers/offers';
import CreateOffer from 'src/app/containers/offers/create-offer';
import OfferView from 'src/app/containers/offers/offer-view';
import ForgotPassword from 'src/app/containers/sign-in/forgot-password';
import ResetPassword from 'src/app/containers/sign-in/reset-password';
import SignIn from 'src/app/containers/sign-in/sign-in';
import SignUp from 'src/app/containers/sign-up/sign-up';
import Transactions from 'src/app/containers/transactions';

export const PublicRoute = ({
  component: Component,
  logged,
  defaultRoute,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      logged ? (
        <Redirect
          to={{
            pathname: defaultRoute,
            state: { from: props.location },
          }}
        />
      ) : (
        <Component {...props} />
      )
    }
  />
);

export const PrivateRoute = ({
  component: Component,
  signedIn,
  redirectToSignIn,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      signedIn ? (
        <Component {...props} {...rest} />
      ) : (
        redirectToSignIn(props.location)
      )
    }
  />
);

export const DefaultRoute: any = ({ defaultRoute }) => (
  <Route render={() => <Redirect to={defaultRoute} />} />
);

const mapStateToProps = state => ({
  authState: state.authReducer,
  userState: state.user,
});

interface RoutesProps {
  previousLocation: string;
  setPreviousLocation: (location: string) => void;
}

const Routes: React.FC<RoutesProps> = ({
  previousLocation,
  setPreviousLocation,
}) => {
  const { authState, userState } = useSelector(mapStateToProps);
  const { pathname: locationPath } = useLocation();
  let defaultRoute = authState.logged
    ? previousLocation || '/transactions'
    : '/sign-in';

  function isSubpath(path: string) {
    return path.match(/\/[\w-]+\/[\w-]+/);
  }

  function redirectToSignIn({ pathname }: any) {
    setPreviousLocation(pathname);
    return <Redirect to="/sign-in" />;
  }

  if (isSubpath(locationPath)) {
    defaultRoute = `/${locationPath.substr(1).split('/')[0]}`;
  }

  return (
    <Switch>
      <Route
        exact
        path="/"
        render={props => {
          if (props.location.hash) {
            const redirectPath = props.location.hash.toString().slice(1);
            return <Redirect to={redirectPath} />;
          }
          return <Redirect to={defaultRoute} />;
        }}
      />
      <PublicRoute
        exact
        logged={authState.logged}
        path="/reset-password/:token"
        defaultRoute={defaultRoute}
        component={ResetPassword}
      />
      <PublicRoute
        exact
        logged={authState.logged}
        path="/sign-in"
        defaultRoute={defaultRoute}
        component={SignIn}
      />
      <PublicRoute
        exact
        logged={authState.logged}
        path="/sign-in/forgot-password/:email?"
        defaultRoute={defaultRoute}
        component={ForgotPassword}
      />
      <PublicRoute
        exact
        logged={authState.logged}
        path="/sign-up/:tokenId"
        defaultRoute={defaultRoute}
        component={SignUp}
      />
      <PrivateRoute
        redirectToSignIn={redirectToSignIn}
        signedIn={authState.logged}
        key="account"
        path="/account"
        component={Account}
        userState={userState}
      />
      <PrivateRoute
        redirectToSignIn={redirectToSignIn}
        signedIn={authState.logged}
        key="transactions"
        path="/transactions"
        component={Transactions}
      />
      <PrivateRoute
        redirectToSignIn={redirectToSignIn}
        signedIn={authState.logged}
        key="billing"
        path="/billing/:id?"
        userState={userState}
        component={Billing}
      />
      <PrivateRoute
        exact
        redirectToSignIn={redirectToSignIn}
        signedIn={authState.logged}
        key="offers"
        path="/offers"
        component={Offers}
      />
      <PrivateRoute
        exact={false}
        redirectToSignIn={redirectToSignIn}
        signedIn={authState.logged}
        key="offers-view"
        path="/offers/view/:offerId?"
        component={OfferView}
      />
      <PrivateRoute
        exact={false}
        redirectToSignIn={redirectToSignIn}
        signedIn={authState.logged}
        key="offers-create"
        path="/offers/create/:offerId?"
        component={CreateOffer}
      />
      <DefaultRoute defaultRoute={defaultRoute} />
    </Switch>
  );
};
export default Routes;
