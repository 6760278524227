import React from 'react';
import { Trans } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { t } from 'i18next';

import LoadingScreen from 'src/app/components/loading-screen';
import { Publisher } from '@fidel.uk/store/lib/brand-user/brand-user-model';

interface PaymentSuccessScreenProps {
  publisher: Publisher;
}

const PaymentSuccessScreen: React.FC<PaymentSuccessScreenProps> = ({
  publisher,
}) => (
  <div className="business-form payment-success-container">
    {publisher ? (
      <section>
        <img
          alt=""
          src="/assets/img/offer_confirmation_illu.png"
          srcSet="/assets/img/offer_confirmation_illu@2x.png 2x"
        />
        <h2>{t('offers.paymentSuccess.title')}</h2>
        <p>
          <Trans
            i18nKey="offers.paymentSuccess.description"
            values={{ publisherName: publisher.name }}
            components={[<strong>{publisher.name}</strong>]}
          />
        </p>
        <NavLink className="btn" to="/offers">
          {t('offers.paymentSuccess.viewOfferLink')}
        </NavLink>
      </section>
    ) : (
      <LoadingScreen />
    )}
  </div>
);

export default PaymentSuccessScreen;
