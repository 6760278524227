import React, { useMemo, useState } from 'react';
import useForm from 'react-hook-form';
import { isEmpty, isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  BusinessInformation,
  transformToBusinessInfo,
} from '@fidel.uk/store/lib/brand-user/brand-user-model';
import { State } from '@fidel.uk/store/lib';
import { updateBusiness } from '@fidel.uk/store/lib/brand-user/brand-user-actions';

import BusinessFormFields from 'src/app/containers/offers/components/business-form-fields';
import LoadingSpinner from 'src/app/components/loading-spinner';
import Button from 'src/app/components/button';
import useBusinessInfo from '../../../../hooks/use-business-info';
import { fidelCountries } from '../../../../utils/countries';
import { Form, FormFooter } from '../../../styled';
import BusinessReminder from './business-reminder';

export interface BusinessFormProps {
  handleCompleteBusinessForm?: () => any;
  inline?: boolean;
  reminderEnabled?: boolean;
}

const BusinessForm: React.FC<BusinessFormProps> = ({
  handleCompleteBusinessForm,
  inline,
  reminderEnabled,
}) => {
  const dispatch = useDispatch();
  const brandUserState = useSelector((state: State) => state.brandUser);
  const userId = useSelector((state: State) => state.user.details?.id);
  const businessInfo = useBusinessInfo();
  const {
    errors,
    handleSubmit,
    register,
    getValues,
    setValue,
    watch,
  } = useForm({
    mode: 'onBlur',
    defaultValues: { country: 'GBR', phoneCountry: 'GBR' },
  });
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  useMemo(() => {
    const { businessUpdated, error } = brandUserState;
    if (loading) {
      if (businessUpdated || !isEmpty(error)) setLoading(false);
      if (businessUpdated && handleCompleteBusinessForm)
        handleCompleteBusinessForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandUserState, loading]);

  function onSubmit(values: any) {
    setLoading(true);
    if (isEqual(values, businessInfo)) {
      setLoading(false);
      if (handleCompleteBusinessForm) handleCompleteBusinessForm();
    } else {
      const businessInformation = transformToBusinessInfo({
        ...values,
        address: `${values.addressLine}${
          values.addressLine2 ? `, ${values.addressLine2}` : ''
        }`,
        countryCode: values.country,
        companyName: values.name,
        companyNumber:
          fidelCountries[values.phoneCountry].phone + values.companyNumber,
      }) as BusinessInformation;
      dispatch(updateBusiness(userId, businessInformation));
    }
  }

  return (
    <div className="business-form reverse">
      {reminderEnabled && <BusinessReminder businessInfo={getValues()} />}
      <section>
        <div className="title-carousel">
          <h2>{t('offers.businessStep.form.title')}</h2>
        </div>
        <Form
          onSubmit={handleSubmit(onSubmit)}
          className="fidel-form"
          data-testid="business-form"
          inline={inline}
        >
          <BusinessFormFields
            inline={inline}
            businessInfo={businessInfo}
            errors={errors}
            register={register}
            setValue={setValue}
            watch={watch}
          />
          <FormFooter inline={inline}>
            <Button data-testid="business-submit" type="submit">
              {loading ? (
                <LoadingSpinner color="white" />
              ) : inline ? (
                <span>{t('offers.businessStep.form.submit.update')}</span>
              ) : (
                <span className="cta-container">
                  <span>{t('offers.businessStep.form.submit.next')}</span>
                  <img
                    src={require('src/assets/svg/cta_arrow.svg')}
                    alt={t('offers.businessStep.form.submit.next')}
                  />
                </span>
              )}
            </Button>
          </FormFooter>
        </Form>
      </section>
    </div>
  );
};

export default BusinessForm;
