import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { State } from '@fidel.uk/store';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router';
import { Invoice } from '@fidel.uk/store/lib/brand-user/brand-user-model';
import * as BrandUserActions from '@fidel.uk/store/lib/brand-user/brand-user-actions';

import LoadingScreen from 'src/app/components/loading-screen';
import { toCurrency } from 'src/utils/transform';

import BillingCardDetails from './components/BillingCardDetails';
import BillingBankDetails from './components/BillingBankDetails';
import InvoiceRow from './components/InvoiceRow';
import BillingView from './components/BillingView';

const Billing: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();
  const { invoices, businessInformation, details, loading } = useSelector(
    (state: State) => state.brandUser,
  );
  const [currentInvoice, setCurrentInvoice] = useState(null);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    if (details) dispatch(BrandUserActions.getInvoices(details.brandId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details]);

  useEffect(() => {
    if (id) {
      const invoiceToView = invoices?.history.find(
        ({ identifier }) => identifier === id,
      );
      if (invoiceToView) setCurrentInvoice(invoiceToView);
      else setRedirect(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (redirect) return <Redirect to="/billing" />;

  if (loading) return <LoadingScreen />;

  return (
    <main className="main-wrapper billing-view">
      {currentInvoice ? (
        <BillingView
          businessInformation={businessInformation}
          invoice={currentInvoice}
        />
      ) : (
        <div className="billing">
          <section className="billing-container">
            <h3>{t('billing.upcomingInvoice.title')}</h3>
            {invoices?.upcoming ? (
              <div className="upcoming-invoice">
                <span className="credit-balance">
                  {toCurrency(
                    invoices.upcoming.amount,
                    invoices.upcoming.currency,
                  )}
                  <span className="ongoing">
                    &nbsp;{t('billing.upcomingInvoice.ongoing')}
                  </span>
                </span>
                <p>
                  <Trans
                    i18nKey="billing.upcomingInvoice.nextInvoiceMessage"
                    values={{
                      date: moment.unix(invoices.upcoming.date).format('ll'),
                    }}
                    components={[<strong>XXX</strong>]}
                  />
                  {invoices.email && (
                    <Trans
                      i18nKey="billing.upcomingInvoice.fwEmailMessage"
                      values={{ email: invoices.email }}
                      components={[<strong>XXX</strong>]}
                    />
                  )}
                </p>
              </div>
            ) : (
              <span className="no-invoice">
                {t('billing.upcomingInvoice.notAvailableYet')}
              </span>
            )}
          </section>
          {details?.stripe && (
            <section className="billing-container">
              <h3>
                {details.stripe.mode === 'charge_automatically'
                  ? t('billing.automatic')
                  : t('billing.manual')}
              </h3>
              {details.stripe.mode === 'charge_automatically' &&
              details.stripe.defaultSource ? (
                <BillingCardDetails source={details.stripe.defaultSource} />
              ) : (
                <BillingBankDetails />
              )}
            </section>
          )}
          <section className="billing-container">
            <h3>{t('billing.invoices.title')}</h3>
            {invoices?.history?.length > 0 ? (
              <table className="invoices">
                <tbody>
                  {(invoices.history as Invoice[]).map(
                    (invoice: Invoice, index) => (
                      <InvoiceRow
                        key={`${index}_${invoice.identifier}`}
                        businessInformation={businessInformation}
                        invoice={invoice}
                      />
                    ),
                  )}
                </tbody>
              </table>
            ) : (
              <span className="no-invoice">
                {t('billing.invoices.notAvailableYet')}
              </span>
            )}
          </section>
        </div>
      )}
    </main>
  );
};

export default Billing;
