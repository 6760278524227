import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import {
  Offer,
  transformToOffer,
} from '@fidel.uk/store/lib/offers/offer-model';

import FormFields from 'src/app/components/form-fields';
import CountryDropdown from 'src/app/containers/offers/components/country-dropdown';
import DatetimeInput from 'src/app/containers/offers/components/datetime-input';
import DiscountTypeDropdown from 'src/app/containers/offers/components/discount-type-dropdown';
import PublisherDropdown from 'src/app/containers/offers/components/publisher-dropdown';
import UICheckbox from 'src/style/ui-components/checkbox/checkbox';
import UIDaysPicker from 'src/style/ui-components/daysPicker/daysPicker';
import { fidelCountries } from 'src/utils/countries';
import { Field, FieldType } from 'src/utils/interfaces';
import { symbols } from 'src/utils/transform';
import { Currency } from '../../../styled';

interface OfferFormFieldsProps {
  errors: any;
  register: any;
  publisher: any;
  offer?: Offer;
  setValue?: (name: string, value: unknown) => any;
  watch?: (name: string) => any;
}

export default function useOfferFormFields({
  errors,
  offer = transformToOffer({}),
  publisher,
  register,
  setValue,
  watch,
}: OfferFormFieldsProps) {
  const { t } = useTranslation();
  const [daysOfWeek, setDaysOfWeek] = useState([0, 1, 2, 3, 4, 5, 6]);
  const [returnPeriod, setReturnPeriod] = useState<boolean>();
  const [returnPeriodValue, setReturnPeriodValue] = useState<number>();
  const [endDate, setEndDate] = useState(null);
  const [endTime, setEndTime] = useState<string>();
  const [startDate, setStartDate] = useState(moment());
  const [startTime, setStartTime] = useState(moment().format('HH:mm'));
  const defaultCountry: any = watch('country')
    ? fidelCountries[watch('country')]
    : fidelCountries.GBR;
  const maxMinSpend = watch('maxSpend') ? Number(watch('maxSpend')) : null;
  const minMaxSpend = watch('minSpend') ? Number(watch('minSpend')) : 0;
  const discountTypeDropdownDisabled = !!offer?.type?.value;
  const countryDropdownDisabled = !!offer?.id;
  const discountDisabled = !!offer?.type?.value;
  const fields: Field[] = [
    {
      key: 'publisher',
      id: 'publisher',
      info: t('offers.offerStep.form.publisher.info'),
      label: t('offers.offerStep.form.publisher.label'),
      hidden: !publisher,
      group: [
        {
          type: FieldType.element,
          element: (
            <PublisherDropdown
              key="publisher_dropdown"
              ref={register({ required: true })}
            />
          ),
        },
      ],
    },
    {
      key: 'offerName',
      id: 'offerName',
      info: t('offers.offerStep.form.offerName.info'),
      label: t('offers.offerStep.form.offerName.label'),
      group: [
        {
          type: FieldType.input,
          props: {
            error: !!errors.offerName,
            key: 'offerName_input',
            'data-testid': 'offer-name',
            id: 'offerName',
            name: 'offerName',
            defaultValue: offer?.name,
            placeholder: t('offers.offerStep.form.offerName.placeholder'),
            ref: register({ minLength: 4, maxLength: 30, required: true }),
          },
        },
      ],
    },
    {
      key: 'country',
      id: 'country',
      info: t('offers.offerStep.form.country.info'),
      label: t('offers.offerStep.form.country.label'),
      group: [
        {
          type: FieldType.element,
          element: (
            <CountryDropdown
              key="country-dropdown"
              disabled={countryDropdownDisabled}
              ref={register(!countryDropdownDisabled && { required: true })}
            />
          ),
        },
      ],
    },
    {
      key: 'discount',
      id: 'discount',
      info: t('offers.offerStep.form.discount.info'),
      label: t('offers.offerStep.form.discount.label'),
      group: [
        {
          type: FieldType.inputBox,
          key: 'discount_inputBox',
          group: [
            {
              type: FieldType.input,
              props: {
                key: 'discount_input',
                'data-testid': 'discount',
                name: 'discount',
                type: 'number',
                defaultValue: offer?.type?.value,
                placeholder: t('offers.offerStep.form.discount.placeholder'),
                disabled: discountDisabled,
                ref: register(!discountDisabled && { min: 0, required: true }),
              },
            },
            {
              type: FieldType.element,
              element: (
                <DiscountTypeDropdown
                  key="discount-type-dropdown"
                  disabled={discountTypeDropdownDisabled}
                  labelAmount={`${symbols[defaultCountry.currency]} - ${t(
                    'offers.offerStep.form.discount.type.amount',
                  )}`}
                  ref={register(
                    !discountTypeDropdownDisabled && { required: true },
                  )}
                />
              ),
            },
          ],
          props: {
            error: !!errors.discount,
          },
        },
      ],
    },
    {
      key: 'minSpend',
      id: 'minSpend',
      info: t('offers.offerStep.form.minSpend.info'),
      label: t('offers.offerStep.form.minSpend.label'),
      group: [
        {
          type: FieldType.inputBox,
          key: 'minSpend_inputBox',
          group: [
            {
              type: FieldType.element,
              element: (
                <Currency key="minSpend_currency">
                  {symbols[defaultCountry.currency] || defaultCountry.currency}
                </Currency>
              ),
            },
            {
              type: FieldType.input,
              props: {
                key: 'minSpend_input',
                name: 'minSpend',
                type: 'number',
                defaultValue: offer?.minTransactionAmount,
                placeholder: t('offers.offerStep.form.minSpend.placeholder'),
                ref: register({ min: 0, max: maxMinSpend }),
              },
            },
          ],
        },
      ],
    },
    {
      key: 'maxSpend',
      id: 'maxSpend',
      info: t('offers.offerStep.form.maxSpend.info'),
      label: t('offers.offerStep.form.maxSpend.label'),
      group: [
        {
          type: FieldType.inputBox,
          key: 'maxSpend_inputBox',
          group: [
            {
              type: FieldType.element,
              element: (
                <Currency key="maxSpend_currency">
                  {symbols[defaultCountry.currency] || defaultCountry.currency}
                </Currency>
              ),
            },
            {
              type: FieldType.input,
              props: {
                key: 'maxSpend_input',
                name: 'maxSpend',
                type: 'number',
                defaultValue: offer?.maxTransactionAmount,
                placeholder: t('offers.offerStep.form.maxSpend.placeholder'),
                ref: register({
                  min: parseFloat(watch('maxSpend')) && minMaxSpend,
                }),
              },
            },
          ],
        },
        {
          type: FieldType.error,
          error: {
            key: 'maxSpend_error',
            hasError: !!errors.maxSpend,
            errorMessage: t('offers.offerStep.form.maxSpend.errorMessage', {
              min: minMaxSpend,
            }),
          },
        },
      ],
    },
    {
      key: 'daysOfWeek',
      id: 'daysOfWeek',
      info: t('offers.offerStep.form.daysOfWeek.info'),
      label: t('offers.offerStep.form.daysOfWeek.label'),
      group: [
        {
          type: FieldType.element,
          element: (
            <UIDaysPicker
              key="daysOfWeek"
              handleChange={days => setDaysOfWeek(days)}
              value={daysOfWeek}
            />
          ),
        },
      ],
    },
    {
      key: 'returnPeriod',
      id: 'returnPeriod',
      info: t('offers.offerStep.form.returnPeriod.info'),
      label: t('offers.offerStep.form.returnPeriod.label'),
      group: [
        {
          type: FieldType.element,
          element: (
            <div key="returnPeriod">
              <UICheckbox
                key="returnPeriod_checkbox"
                handleChange={(e: any) => setReturnPeriod(e.target.checked)}
                checked={returnPeriod}
                tabIndex={0}
                name="returnPeriod"
                label={t('offers.offerStep.form.returnPeriod.value.label')}
              />
              {returnPeriod && (
                <div className="ui-checkbox-dropdown" key="returnPeriod_view">
                  <div className="ui-checkbox-input-container">
                    <input
                      min={7}
                      max={150}
                      name="returnPeriodValue"
                      type="number"
                      onChange={(e: any) =>
                        setReturnPeriodValue(e.target.value)
                      }
                      value={returnPeriodValue}
                    />
                    <span>
                      {t('offers.offerStep.form.returnPeriod.value.days')}
                    </span>
                  </div>
                  <p>{t('offers.offerStep.form.returnPeriod.value.info')}</p>
                </div>
              )}
            </div>
          ),
        },
      ],
    },
    {
      key: 'duration',
      id: 'startDate',
      info: t('offers.offerStep.form.duration.info'),
      label: t('offers.offerStep.form.duration.label'),
      group: [
        {
          type: FieldType.element,
          element: (
            <DatetimeInput
              key="startDatetime"
              date={startDate}
              handleChange={setStartDate}
              id="startDate"
              isOutsideRange={day => day.isBefore(moment().subtract(1, 'days'))}
              label={t('offers.offerStep.form.duration.startDate.label')}
              inputTimeProps={{
                error: !!errors.startTime,
                name: 'startTime',
                defaultValue: startTime,
                onChange: (e: any) => setStartTime(e.target.value),
                pattern: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/i,
                ref: register({
                  required: true,
                }),
              }}
              showClearDate={false}
            />
          ),
        },
        {
          type: FieldType.element,
          element: (
            <DatetimeInput
              key="endDatetime"
              date={endDate}
              handleChange={date => setEndDate(date)}
              id="endDate"
              isOutsideRange={day => day.isBefore(moment(startDate))}
              label={t('offers.offerStep.form.duration.endDate.label')}
              initialVisibleMonth={moment.bind(null, startDate)}
              inputTimeProps={{
                name: 'endTime',
                'data-testid': 'endTime',
                defaultValue: endTime,
                onChange: (e: any) => setEndTime(e.target.value),
                pattern:
                  (!endDate && /^\s*$/i) ||
                  /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/i,
                ref: register({
                  required: !!endDate,
                }),
              }}
              showClearDate
            />
          ),
        },
      ],
    },
    {
      key: 'terms',
      id: 'terms',
      info: t('offers.offerStep.form.terms.info'),
      label: t('offers.offerStep.form.terms.label'),
      noBorder: true,
      group: [
        {
          type: FieldType.textarea,
          props: {
            rows: 5,
            name: 'terms',
            key: 'terms_textarea',
            placeholder: t('offers.offerStep.form.terms.placeholder'),
            defaultValue: offer?.additionalTerms,
            'data-testid': 'offer-terms',
            minLength: 4,
            ref: register(),
          },
        },
      ],
    },
  ];

  useEffect(() => {
    if (endTime && !endDate)
      setEndDate(
        startDate.add(1, 'days').set({
          minute: moment(endTime, 'HH:mm').get('minute'),
          hour: moment(endTime, 'HH:mm').get('hour'),
          second: 59,
          millisecond: 999,
        }),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endTime]);

  useEffect(() => {
    if (!isEmpty(offer)) {
      setValue('country', offer.countryCode || 'GBR');
      setValue('discountType', offer.type?.name || 'amount');
      setDaysOfWeek(offer.daysOfWeek);
      setReturnPeriod(!!offer.returnPeriod);
      setReturnPeriodValue(offer.returnPeriod);
      setStartDate(moment(offer.startDate));
      setStartTime(moment(offer.startDate).format('HH:mm'));
      if (offer.endDate) {
        setEndDate(moment(offer.endDate));
        setEndTime(moment(offer.endDate).format('HH:mm'));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offer]);

  return {
    fields: <FormFields fields={fields} />,
    daysOfWeek,
    returnPeriodValue,
    returnPeriod,
    endDate,
    startDate,
    setEndTime,
  };
}
