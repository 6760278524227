export const colors = {
  primary: '#111',
  accent: '#0048ff',
  white: 'white',
  transparent: 'transparent',
  grey: '#f1f1f1',
  borderGrey: '#dfe4e7',
  inputGrey: '#eee',
  lightGrey: '#f6f6f6',
  midGrey: '#ddd',
  darkGrey: '#606060',
  darkBlue: '#001564',
  placeholder: '#a0a9b1',
  green: 'limegreen',
  red: '#ec0c1a',
};

export const defaults = {
  borderRadius: '3px',
  fontFamily:
    "'Heebo', -apple-system, BlinkMacSystemFont, 'Segoie UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.2)',
};

export const breakpoints = {
  largeMobile: '380px',
  tabletPortrait: '600px',
  tabletLandscape: '850px',
  tablet: '850px',
  desktop: '1140px',
  above13inch: '1442px',
};

export type Appearance = 'round' | 'transparent';
export type ScreenSize = 'smallScreen' | 'largeScreen';
export type Size = 'small' | 'normal' | 'medium' | 'large';
export type Variant = 'secondary' | 'dark' | 'white' | 'red';

export const input = {
  defaults: `
    font-size: 16px;
    font-family: ${defaults.fontFamily};
    color: ${colors.primary};
    height: 38px;
    border: none;
    border-radius: ${defaults.borderRadius};
    padding: 0 12px;
    box-sizing: border-box;

    &:focus {
      outline: none;
    }

    &:-moz-focus-outer {
      border: none;
    }

    &::placeholder {
      color: ${colors.placeholder};
    }
  `,
  fontSize: '16px',
  border: `1px solid ${colors.placeholder}`,
  size: {
    smallScreen: {
      small: '32px',
      normal: '38px',
      medium: '48px',
      large: '58px',
    },
    largeScreen: {
      small: '32px',
      normal: '38px',
      medium: '58px',
      large: '70px',
    },
  },
};
