const icons = {
  arrowLeft: require('src/assets/svg/arrow_left_icon.svg'),
  arrowRight: require('src/assets/svg/arrow_right_icon.svg'),
  mastercard: require('src/assets/svg/mastercard_icon.svg'),
  visa: require('src/assets/svg/visa_icon.svg'),
  amex: require('src/assets/svg/amex_icon.svg'),
  '/offers': require('src/assets/svg/icon_offers.svg'),
  '/transactions': require('src/assets/svg/icon_transactions.svg'),
  '/billing': require('src/assets/svg/icon_billing.svg'),
};

export const iconFor = (thing: string) => icons[thing];
