import React from 'react';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { State } from '@fidel.uk/store';

interface PublisherDropdownProps {
  publisher?: any;
}

const PublisherDropdown = React.forwardRef<any, PublisherDropdownProps>(
  ({ publisher = {} }, ref) => {
    const { brandUserPublisher } = useSelector((state: State) => ({
      brandUserPublisher: state.brandUser.details.publisher,
    }));

    const { id, name } = brandUserPublisher;

    return (
      <div className="ui-dropdown">
        <select
          data-testid="publisher-selector"
          id="publisher"
          name="publisher"
          ref={ref}
        >
          <option disabled>
            {t('offers.offerStep.form.publisher.placeholder')}
          </option>
          {publisher && <option value={id}>{name}</option>}
        </select>
      </div>
    );
  },
);

export default PublisherDropdown;
