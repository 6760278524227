import React, { useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import { State } from '@fidel.uk/store';
import * as OfferActions from '@fidel.uk/store/lib/offers';

import LoadingScreen from 'src/app/components/loading-screen';
import { fidelCountries } from 'src/utils/countries';
import { toCurrency, toDays } from 'src/utils/transform';

const OfferView: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { offerId } = useParams();
  const { error, loading, offer } = useSelector((state: State) => ({
    error: state.offer.error,
    loading: state.offer.loading,
    offer: state.offer.offer,
  }));
  const country = offer ? fidelCountries[offer.countryCode] : {};
  const dateLabel = offer?.endDate
    ? t('offers.offer.date.duration')
    : t('offers.offer.date.start');

  useEffect(() => {
    if (offerId) dispatch(OfferActions.getOffer(offerId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerId]);

  if (!loading && error) return <Redirect to="/offers" />;

  return (
    <div className="offer-container view">
      <NavLink to="/offers" className="btn btn-grey back">
        <img src={require('src/assets/svg/chevron-left.svg')} alt="" />
        {t('offers.offer.back')}
      </NavLink>
      {loading && <LoadingScreen />}
      {offer && (
        <section className="offer-view">
          <h2>{offer.name}</h2>
          <div className="offer-info">
            <span className="label">
              {t('offers.offerStep.form.discount.label')}
            </span>
            <span>
              {offer.type.value}
              {offer.type.name === 'amount' ? offer.currency : '%'}
            </span>
          </div>
          <div className="offer-info">
            <span className="label">
              {t('offers.offerStep.form.minSpend.label')}
            </span>
            {offer.minTransactionAmount ? (
              <span>
                {toCurrency(offer.minTransactionAmount, country.currency)}
              </span>
            ) : (
              <span>{t('na')}</span>
            )}
          </div>
          <div className="offer-info">
            <span className="label">
              {t('offers.offerStep.form.maxSpend.label')}
            </span>
            {offer.maxTransactionAmount ? (
              <span>
                {toCurrency(offer.maxTransactionAmount, country.currency)}
              </span>
            ) : (
              <span>{t('na')}</span>
            )}
          </div>
          <div className="offer-info">
            <span className="label">
              {t('offers.offerStep.form.daysOfWeek.label')}
            </span>
            <span>{toDays(offer.daysOfWeek)}</span>
          </div>
          <div className="offer-info">
            <span className="label">
              {t('offers.offerStep.form.returnPeriod.label')}
            </span>
            {offer.returnPeriod ? (
              <span>
                {offer.returnPeriod}{' '}
                {t('offers.offerStep.form.returnPeriod.value.days')}
              </span>
            ) : (
              <span>{t('na')}</span>
            )}
          </div>
          <div className="offer-info">
            <span className="label">{dateLabel}</span>
            <span>
              {moment(offer.startDate).format('ll')}
              {offer.endDate && ` - ${moment(offer.endDate).format('ll')}`}
            </span>
          </div>
        </section>
      )}
    </div>
  );
};

export default OfferView;
