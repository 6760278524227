import React, { useEffect, useState } from 'react';
import { State } from '@fidel.uk/store';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router';
import { Offer } from '@fidel.uk/store/lib/offers/offer-model';
import * as OfferActions from '@fidel.uk/store/lib/offers';

import CurrentOfferForm from './components/current-offer-form';
import OfferSteps from './components/offer-steps';
import PaymentSuccessScreen from './components/PaymentSuccessScreen';

const CreateOffer: React.FC = () => {
  const dispatch = useDispatch();
  const { offerId } = useParams();
  const {
    businessInformation,
    loading,
    offer,
    stripe,
    userId,
    publisher,
  } = useSelector((state: State) => ({
    businessInformation: state.brandUser.businessInformation,
    stripe: state.brandUser?.details?.stripe,
    loading: state.offer.loading,
    offer: state.offer.offer,
    userId: state.user.details?.id,
    publisher: state.brandUser.details?.publisher,
  }));
  const [redirect, setRedirect] = useState(false);
  const [stepCompleted, setStepCompleted] = useState(null);
  const businessInformationChecked =
    businessInformation?.address && businessInformation?.companyName;

  function handleCompleteOfferForm() {
    if (!stripe) {
      setStepCompleted('offer');
    }
  }

  function handleCompletePaymentForm() {
    setStepCompleted('payment');
    if (offer)
      dispatch(
        OfferActions.updateOffer(offer.id, {
          funded: {
            type: 'merchant',
            id: userId,
          },
        } as Offer),
      );
  }

  function handlePreviousStep(e: any) {
    e.preventDefault();

    if (stepCompleted !== 'offer') setStepCompleted(null);
    else setStepCompleted('business');
  }

  function handleSwitchStep(e: any, stepName: string) {
    switch (stepName) {
      case 'offer':
        if (stepCompleted === 'offer') setStepCompleted('business');
        break;
      case 'business':
        if (stepCompleted === 'business' || stepCompleted === 'offer')
          setStepCompleted(null);
        break;
      default:
        break;
    }
    dispatch(OfferActions.clearError());
  }

  useEffect(
    () => () => {
      dispatch(OfferActions.clearOffer());
      dispatch(OfferActions.clearError());
    },
    [dispatch],
  );

  useEffect(() => {
    if (businessInformationChecked) setStepCompleted('business');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessInformation]);

  useEffect(() => {
    if (offer?.id && businessInformationChecked && !stripe)
      handleCompleteOfferForm();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offer, stripe]);

  useEffect(() => {
    if (offerId && !offer && !loading) dispatch(OfferActions.getOffer(offerId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerId, stepCompleted]);

  useEffect(() => {
    if (offer && !offer.funded && stripe && !loading)
      dispatch(
        OfferActions.updateOffer(offer.id, {
          funded: {
            type: 'merchant',
            id: userId,
          },
        } as Offer),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offer, userId]);

  if (redirect) return <Redirect to="/offers" />;

  return (
    <div className="offer-create-container">
      <OfferSteps
        handleClose={() => setRedirect(true)}
        handleClick={handleSwitchStep}
        stepCompleted={stepCompleted}
      />
      {stepCompleted === 'payment' ? (
        <PaymentSuccessScreen publisher={publisher} />
      ) : (
        <CurrentOfferForm
          currentOffer={offer}
          handleCompleteBusinessForm={() => setStepCompleted('business')}
          handleCompleteOfferForm={handleCompleteOfferForm}
          handleCompletePaymentForm={handleCompletePaymentForm}
          handlePreviousStep={handlePreviousStep}
          stepCompleted={stepCompleted}
        />
      )}
    </div>
  );
};

export default CreateOffer;
