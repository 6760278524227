import { useEffect } from 'react';

interface ScrollProps {
  handleScroll: (...args: any) => any;
  scrollableElement?: Element;
}

export default function useOnScroll({
  handleScroll,
  scrollableElement = null,
}: ScrollProps) {
  function handleScrollAtTheEnd() {
    const element = document.getElementById('root');
    if (!element) return;

    const height =
      (scrollableElement && scrollableElement.scrollHeight) ||
      element.offsetHeight;
    const offset =
      ((scrollableElement && scrollableElement.scrollTop) ||
        document.documentElement.scrollTop) + window.innerHeight;

    if (offset >= height) {
      handleScroll();
    }
  }

  useEffect(() => {
    (scrollableElement || document).addEventListener(
      'scroll',
      handleScrollAtTheEnd,
    );
    (scrollableElement || document).addEventListener(
      'touchmove',
      handleScrollAtTheEnd,
    );
    return () => {
      (scrollableElement || document).removeEventListener(
        'scroll',
        handleScrollAtTheEnd,
      );
      (scrollableElement || document).removeEventListener(
        'touchmove',
        handleScrollAtTheEnd,
      );
    };
  });
}
