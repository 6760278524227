import React from 'react';
import { useTranslation } from 'react-i18next';

import { DicountTypeContainer } from '../styled';

interface DiscountTypeDropdownProps {
  labelAmount: string;
  className?: string;
  disabled?: boolean;
}

const DiscountTypeDropdown = React.forwardRef<any, DiscountTypeDropdownProps>(
  ({ className, disabled, labelAmount }, ref) => {
    const { t } = useTranslation();

    return (
      <DicountTypeContainer>
        <div className={`ui-dropdown ${className || ''}`}>
          <select
            className="selector discount-type-selector"
            data-testid="discount-type-selector"
            name="discountType"
            tabIndex={0}
            disabled={disabled}
            ref={ref}
          >
            <option value="amount">{labelAmount}</option>
            <option value="discount">
              {t('offers.offerStep.form.discount.type.percentage')}
            </option>
          </select>
        </div>
      </DicountTypeContainer>
    );
  },
);

export default DiscountTypeDropdown;
