import styled from 'styled-components';

export const DatetimeInputContainer = styled.div`
  display: flex;
  max-width: 100%;

  &:not(:last-of-type) {
    margin-bottom: 20px;
  }

  label {
    min-width: 80px;
    white-space: nowrap;
    text-align: left;

    @media (min-width: ${p => p.theme.breakpoints.tablet}) {
      text-align: right;
    }
  }

  input:last-of-type {
    margin-left: 20px;
    flex: 1 1 auto;
  }

  .SingleDatePicker {
    flex: 3 2 auto;

    @media (min-width: ${p => p.theme.breakpoints.tablet}),
      (max-width: ${p => p.theme.breakpoints.mobileLarge}) {
      max-width: 155px;
    }

    input {
      border: none;
      margin-left: 0;
    }
  }
`;

export const DicountTypeContainer = styled.div`
  border-left: ${p => p.theme.baseBorder} !important;
  min-width: 150px;
  max-width: 100%;
`;
