import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { UserState } from '@fidel.uk/store/lib/user/user-reducer';
import * as Sentry from '@sentry/browser';

interface ErrorBoundaryProps extends WithTranslation {
  children: any;
  logged: boolean;
  t?: any;
  user?: UserState['details'];
  userError?: any;
}

interface ErrorBoundaryState {
  error?: any;
}

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);

    this.state = {};
  }

  componentDidCatch(error: any, errorInfo: any) {
    this.setState({ error });
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      const { user, logged } = this.props;
      if (logged && user) {
        scope.setUser({
          email: user.email,
          username: `${user.nameFirst} ${user.nameLast}`,
          id: user.id,
        });
        scope.setTag('fidel-lang', localStorage.getItem('fidel-lang') || 'en');
      }
      Sentry.captureException(error);
    });
  }

  render() {
    const { children, userError, t } = this.props;
    const { error } = this.state;

    if (error || userError)
      return (
        <div className="error-container">
          <h1>{t('error.title')}</h1>
          <p>
            {(userError && t('error.messages.user')) ||
              t('error.messages.default')}
          </p>
          <button
            type="button"
            className="btn"
            onClick={() => Sentry.showReportDialog()}
          >
            {t('error.report')}
          </button>
        </div>
      );

    return children;
  }
}

export default withTranslation()(ErrorBoundary);
