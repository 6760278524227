import React from 'react';

const EmptyTransaction = (props: { className: string }) => {
  const cols = ['scheme', 'amount', 'location', 'card', 'status', 'date'];

  return (
    <tr className="loading-placeholder">
      {cols.map(col => (
        <td key={col} className={`col-${col}`}>
          <span
            className={`loading-text-placeholder pill ${
              col === 'location' ? props.className : ''
            }`}
          />
        </td>
      ))}
    </tr>
  );
};

export default EmptyTransaction;
