import React from 'react';

interface UICheckboxProps {
  name: string;
  id: string;
  handleChange: any;
  checked?: boolean;
  round?: boolean;
  label?: any | undefined;
  value?: string;
  tabIndex?: number | 0;
}

const UIRadioButton = ({
  label,
  id,
  value,
  tabIndex,
  name,
  checked,
  handleChange,
}: UICheckboxProps) => {
  const classes = `radio-button round ${checked ? 'checked icon-circle' : ''}`;

  return (
    <div className="ui-radio-button">
      <label htmlFor={id}>
        <span className={classes} />
        {label ? <span className="label">{label}</span> : null}
      </label>
      <input
        type="radio"
        name={name}
        id={id}
        value={value}
        tabIndex={tabIndex}
        onClick={handleChange}
      />
    </div>
  );
};

export default UIRadioButton;
