import React, { useEffect, useRef, useState } from 'react';
import { State, Transaction } from '@fidel.uk/store';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { completeStep } from '@fidel.uk/store/lib/brand-user/brand-user-actions';
import * as OfferActions from '@fidel.uk/store/lib/offers';
import * as TransactionsActions from '@fidel.uk/store/lib/transactions/transaction-actions';

import Empty from 'src/app/components/empty';
import LoadingScreen from 'src/app/components/loading-screen';
import LoadingSpinner from 'src/app/components/loading-spinner';
import WelcomeBox from 'src/app/components/WelcomeBox';

import EmptyTransaction from './components/EmptyTransaction';
import TransactionDetail from './components/TransactionDetail';
import TransactionRow from './components/TransactionRow';
import TransactionsHeader from './transactions-header';

const Transactions: React.FC = () => {
  const dispatch = useDispatch();
  const wrapperRef = useRef<HTMLDivElement>();
  const { t } = useTranslation();
  const {
    bootstrapped,
    lastTransaction,
    loadingTransactions,
    transactionsEmpty,
    offer,
    programs,
    transactions,
    userDetails,
    brandUserDetails,
    role,
  } = useSelector(
    (state: State) => ({
      bootstrapped: !!state.brandUser.details,
      lastTransaction: state.transactions.lastTransaction,
      loadingTransactions: state.transactions.loading,
      transactionsEmpty: state.transactions.transactionsEmpty,
      offer: state.offer.offer,
      programs: state.brandUser.programs,
      transactions: state.transactions.transactions,
      userDetails: state.user.details,
      brandUserDetails: state.brandUser.details,
      role: state.authReducer.role,
    }),
    shallowEqual,
  );
  const isModerator = ['moderator', 'super'].includes(role);
  const [loading, setLoading] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);

  const welcomeMessageVisible =
    !brandUserDetails?.stepsCompleted?.cloWelcomeMessage && !isModerator;

  const FidelListHeader = (
    <thead>
      <tr>
        <th className="col-scheme">&nbsp;</th>
        <th className="col-amount">{t('transactions.label.amount')}</th>
        <th className="col-location">{t('transactions.label.location')}</th>
        <th className="col-card">{t('transactions.label.card')}</th>
        <th className="col-status">{t('transactions.label.status')}</th>
        <th className="col-date">
          <span>{t('transactions.label.date')}</span>
        </th>
      </tr>
    </thead>
  );
  const publisherName = brandUserDetails?.publisher?.name;

  const transactionsLoadedAndNotEmpty = transactions?.length > 0;
  const isOfferTx = offer && selectedTransaction?.offer?.id === offer.id;

  function toggleTransactionDetail(transaction: Transaction) {
    const { offer: transactionOffer }: any = transaction;
    if (transactionOffer?.id)
      dispatch(OfferActions.getOffer(transactionOffer.id));
    setSelectedTransaction(transaction);
  }

  function hideWelcomeMessage() {
    if (userDetails?.id)
      dispatch(
        completeStep(
          userDetails.id,
          brandUserDetails.stepsCompleted,
          'cloWelcomeMessage',
        ),
      );
  }

  useEffect(
    () => () => {
      dispatch(TransactionsActions.clear());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (wrapperRef.current) {
      wrapperRef.current.addEventListener('scroll', () => {
        const headerWrapper = wrapperRef.current.querySelector(
          '.header-wrapper',
        );
        const scrollBreakpoint = welcomeMessageVisible ? 226 : 1;

        if (
          !!headerWrapper &&
          wrapperRef.current.scrollTop > scrollBreakpoint
        ) {
          headerWrapper.classList.add('fixed');
          if (welcomeMessageVisible)
            headerWrapper.classList.add('with-welcome-message');
        } else if (headerWrapper) {
          headerWrapper.classList.remove('fixed', 'with-welcome-message');
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapperRef]);

  useEffect(() => {
    setLoading(!bootstrapped);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bootstrapped]);

  useEffect(() => {
    if (loading && (transactions || transactionsEmpty)) setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, transactions, transactionsEmpty]);

  return (
    <div className="transactions-container">
      {loadingTransactions && <LoadingScreen />}
      {selectedTransaction && (
        <TransactionDetail
          handleClose={() => setSelectedTransaction(null)}
          transaction={selectedTransaction}
          publisherName={publisherName}
          offer={isOfferTx && offer}
        />
      )}
      <div className="transactions-wrapper" ref={wrapperRef}>
        {welcomeMessageVisible && !!userDetails && (
          <div className="transactions-content">
            <WelcomeBox hideMessage={hideWelcomeMessage} user={userDetails} />
          </div>
        )}
        <div className="transactions-top">
          {programs?.length > 0 && <TransactionsHeader />}
        </div>
        <div className="transactions-list transactions-content">
          {loading || transactionsLoadedAndNotEmpty ? (
            <div className="list-loaded">
              <table className="fidel-table">
                {FidelListHeader}
                <tbody>
                  {loading
                    ? [50, 68, 50, 57, 48].map((size, index) => (
                        <EmptyTransaction
                          key={`empty-tr-${index}`}
                          className={`w-${size}`}
                        />
                      ))
                    : transactions.map(tr => (
                        <TransactionRow
                          key={tr.id}
                          data={tr}
                          handleClick={toggleTransactionDetail}
                        />
                      ))}
                </tbody>
              </table>
              {loadingTransactions && !!lastTransaction && (
                <LoadingSpinner color="#ABB7C4" size={18} thickness={2} />
              )}
            </div>
          ) : (
            <Empty message={t('transactions.label.empty')} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Transactions;
