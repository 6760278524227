import React from 'react';
import { useSelector } from 'react-redux';
import { Elements, StripeProvider } from 'react-stripe-elements';
import { State } from '@fidel.uk/store/lib';
import { Offer } from '@fidel.uk/store/lib/offers/offer-model';

import config from 'src/utils/config';
import BusinessForm from './business-form';
import OfferForm from './offer-form';
import PaymentForm from './payment-form';

interface CurrentOfferFormProps {
  currentOffer: Offer;
  handleCompleteBusinessForm: () => any;
  handleCompleteOfferForm: () => any;
  handleCompletePaymentForm: () => any;
  handlePreviousStep: (...args: any) => any;
  stepCompleted: string;
}

const CurrentOfferForm: React.FC<CurrentOfferFormProps> = ({
  currentOffer,
  handleCompleteBusinessForm,
  handleCompleteOfferForm,
  handleCompletePaymentForm,
  handlePreviousStep,
  stepCompleted,
}) => {
  const offer = useSelector((state: State) => state.offer.offer);

  switch (stepCompleted) {
    case 'business':
      return (
        <OfferForm
          handleCompleteOfferForm={handleCompleteOfferForm}
          handlePreviousStep={handlePreviousStep}
          currentOffer={currentOffer}
        />
      );
    case 'offer':
      return (
        <StripeProvider apiKey={config.STRIPE_KEY}>
          <Elements>
            <PaymentForm
              handleCompletePaymentForm={handleCompletePaymentForm}
              handlePreviousStep={handlePreviousStep}
              currentOffer={offer}
            />
          </Elements>
        </StripeProvider>
      );
    default:
      return (
        <BusinessForm
          handleCompleteBusinessForm={handleCompleteBusinessForm}
          reminderEnabled
        />
      );
  }
};

export default CurrentOfferForm;
