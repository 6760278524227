import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { bootstrap } from '@fidel.uk/store/lib/auth/auth-actions';
import { setLive } from '@fidel.uk/store/lib/live/live-actions';
import { getAllAccounts } from '@fidel.uk/store/lib/moderator/moderator-actions';
import { getUser } from '@fidel.uk/store/lib/user/user-actions';

import Routes from './routes';
import ConnectedStatusBar from './components/connected-status-bar';
import ErrorBoundary from './components/ErrorBoundary';
import LoadingScreen from './components/loading-screen';
import Sidebar from './components/sidebar';

const mapStateToProps = state => ({
  authState: state.authReducer,
  moderator: state.moderator,
  userState: state.user,
});

const App: React.FC = () => {
  const dispatch = useDispatch();
  const {
    authState: { logged, loading, bootstrapped, tokenState, role },
    moderator: { accounts, users },
    userState: { error, details },
  } = useSelector(mapStateToProps);
  const [previousLocation, setPreviousLocation] = useState(null);
  const moderatorLoading = accounts?.loading || users?.loading;

  useEffect(() => {
    dispatch(setLive(true));
    if (!bootstrapped) dispatch(bootstrap());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (bootstrapped && logged) {
      if (['moderator', 'super'].includes(role)) dispatch(getAllAccounts());
      else dispatch(getUser(tokenState?.token));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bootstrapped, logged]);

  return (
    <Router>
      {(loading || moderatorLoading) && <LoadingScreen />}
      {bootstrapped && (
        <div className="fidel-container">
          {logged && <Sidebar />}
          <main className="dashboard-container">
            <ConnectedStatusBar />
            <ErrorBoundary logged={logged} user={details} userError={error}>
              <Routes
                previousLocation={previousLocation}
                setPreviousLocation={setPreviousLocation}
              />
            </ErrorBoundary>
          </main>
        </div>
      )}
    </Router>
  );
};

export default App;
