import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { transformToMerchantAccount } from '@fidel.uk/store/lib/brand-user/brand-user-model';

import { FidelRoute } from 'src/utils/interfaces';
import * as show from 'src/utils/show';

import AccountsDropdown from './accounts-dropdown';
import BrandUserDetail from './brand-user-detail';
import LanguageSelector from './language-selector';
import ProfileMenu from './profile-menu';
import UsersDropdown from './users-dropdown';
import { ModeratorContainer } from './styled/sidebar';

const fidelLogo = require('src/assets/svg/logo_Fidel.svg');

const mapStateToProps = state => ({
  authState: state.authReducer,
  brandUserState: state.brandUser,
  moderator: state.moderator,
});

const Sidebar: React.FC = () => {
  const { t } = useTranslation();
  const { authState, brandUserState, moderator } = useSelector(mapStateToProps);
  const { merchantAccount } = brandUserState;
  const [menuOpen, setMenuOpen] = useState(false);
  const [account, setAccount] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const dashboardRoutes = [
    {
      path: '/transactions',
      label: t('menu.transactions'),
    },
    {
      path: '/offers',
      label: t('menu.offers'),
    },
    {
      path: '/billing',
      label: t('menu.billing'),
    },
  ];
  const isModerator = ['moderator', 'super'].includes(authState.role);
  const selectedUser = moderator.users.items.find(
    user => user.brandUserId === currentUser,
  )?.userId;
  const moderatingUserDetail =
    selectedUser && transformToMerchantAccount(selectedUser);

  function handleAccount(selectedAccount: string) {
    setAccount(selectedAccount);
    setCurrentUser(null);
  }

  return (
    <div className="sidebar">
      <nav>
        {menuOpen && (
          <ProfileMenu
            routes={dashboardRoutes}
            toggleMenu={() => setMenuOpen(!menuOpen)}
            moderatingUserDetail={moderatingUserDetail}
          />
        )}
        <BrandUserDetail
          moderatingUserDetail={moderatingUserDetail}
          openProfileMenu={() => setMenuOpen(!menuOpen)}
          profileMenuOpened={menuOpen}
          userDetails={merchantAccount}
        />
        {isModerator && (
          <ModeratorContainer className="medium-only">
            <label>
              <span>{t('moderatingFor')}</span>
              <AccountsDropdown account={account} setAccount={handleAccount} />
              {!moderator.accounts?.loading && (
                <UsersDropdown
                  currentUser={currentUser}
                  setCurrentUser={setCurrentUser}
                />
              )}
            </label>
          </ModeratorContainer>
        )}
        <ul className="nav-link-container">
          {dashboardRoutes.map(({ path, label }: FidelRoute, idx) => (
            <li key={idx}>
              <NavLink key={idx} to={path} activeClassName="nav-link-active">
                <div className="large-only">
                  <img src={show.iconFor(path)} alt="" />
                </div>
                <span>{label}</span>
              </NavLink>
            </li>
          ))}
          <li key="account">
            <NavLink
              to="/account"
              className="small-only"
              activeClassName="nav-link-active"
            >
              {t('components.sidebar.account')}
            </NavLink>
          </li>
        </ul>
      </nav>
      <div className="sidebar-footer">
        <a href="https://fidel.uk/" target="_blank" rel="noopener noreferrer">
          <img className="fidel-logo" src={fidelLogo} alt="Fidel" />
        </a>
        <LanguageSelector />
      </div>
    </div>
  );
};

export default Sidebar;
