import React from 'react';
import moment from 'moment';
import { t } from 'i18next';

import {
  BusinessInformation,
  Invoice,
} from '@fidel.uk/store/lib/brand-user/brand-user-model';

import { toCurrency } from 'src/utils/transform';
import InvoiceActionBox from './InvoiceActionBox';

export interface InvoiceRowProps {
  businessInformation: BusinessInformation;
  invoice: Invoice;
}

export const InvoiceRow = (props: InvoiceRowProps) => {
  const { businessInformation, invoice } = props;
  return (
    <tr>
      <td className="date">{moment.unix(invoice.date).format('ll')}</td>
      <td className="amount">{toCurrency(invoice.amount, invoice.currency)}</td>
      <td className="status">
        <span className={`pill ${invoice.status}`}>
          {t(`billing.status.${invoice.status}`)}
        </span>
      </td>
      <td className="dueDate">
        {invoice.status !== 'paid' ? (
          <span>
            {t('billing.invoices.due', { date: invoice.dueDate.format('ll') })}
          </span>
        ) : null}
      </td>
      <td className="link">
        <InvoiceActionBox
          businessInformation={businessInformation}
          invoice={invoice}
        />
      </td>
    </tr>
  );
};

export default InvoiceRow;
